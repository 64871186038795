import React from "react"

import Text from "./FieldTypes/Text"
import Number from "./FieldTypes/Number"
import Email from "./FieldTypes/Email"
import PhoneNumber from "./FieldTypes/PhoneNumber"
import OIB from "./FieldTypes/OIB"
import RichText from "./FieldTypes/RichText"
import TextField from "./FieldTypes/TextField"
import DateInput from "./FieldTypes/DateInput"
import Time from "./FieldTypes/Time"
import DateTime from "./FieldTypes/DateTime"
import WorkingHours from "./FieldTypes/WorkingHours"
import LinkInput from "./FieldTypes/LinkInput"
import RadioButton from "./FieldTypes/RadioButton"
import CheckboxButton from "./FieldTypes/CheckboxButtons"
import Dropdown from "./FieldTypes/Dropdown"
import MultipleChoiceDropdown from "./FieldTypes/MultipleChoiceDropdown"
import Switch from "./FieldTypes/Switch"
import File from "./FieldTypes/File"
import SingleImage from "./FieldTypes/SingleImage"
import Audio from "./FieldTypes/Audio"
import { connect } from "react-redux"
import RegionCitySelector from "./FieldTypes/RegionCitySelector"

const FieldPicker = ({ item, updateValue, value, disabled, currentUser }) => {
  if (!item.Visible) return <div />

  if (item.AdminOnly && currentUser.role > 3) return <div />

  if (item.Type === "Text") {
    return (
      <Text
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "Number") {
    return (
      <Number
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "Email") {
    return (
      <Email
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "Phone Number") {
    return (
      <PhoneNumber
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "OIB") {
    return (
      <OIB
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "Rich Text Field") {
    return (
      <RichText
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
        updateValue={val => updateValue(item.Name, val)}
        value={value || ""}
      />
    )
  }

  if (item.Type === "Text Field") {
    return (
      <TextField
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "Date") {
    return (
      <DateInput
        item={item}
        updateValue={val => updateValue(item.Name, val)}
        value={value ? new Date(value) : new Date()}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "Time") {
    return (
      <Time
        item={item}
        updateValue={val => updateValue(item.Name, val)}
        value={value ? new Date(value) : new Date()}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "DateTime") {
    return (
      <DateTime
        item={item}
        updateValue={val => updateValue(item.Name, val)}
        value={value ? new Date(value) : new Date()}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "Working Hours") {
    return (
      <WorkingHours
        item={item}
        updateValue={val => updateValue(item.Name, val)}
        value={value || "unknown"}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "Link") {
    return (
      <LinkInput
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (item.Type === "Radio Button" || item.Type === "Radio Button Entity") {
    return (
      <RadioButton
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
      />
    )
  }

  if (
    item.Type === "Checkbox Buttons" ||
    item.Type === "Checkbox Buttons Entity"
  ) {
    return (
      <CheckboxButton
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
        value={value && value.SelectionOptions ? value.SelectionOptions : []}
        updateValue={newSelections =>
          updateValue(item.Name, {
            Field_ID: item.ID,
            SelectionOptions: newSelections,
          })
        }
      />
    )
  }

  if (
    item.Type === "Dropdown" ||
    item.Type === "Dropdown Entity" ||
    item.Type === "Question Collection Selection"
  ) {
    return (
      <Dropdown
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
        value={value}
        updateValue={val => updateValue(item.Name, val)}
      />
    )
  }

  if (
    item.Type === "Multiple Choice Dropdown" ||
    item.Type === "Multiple Choice Dropdown Entity"
  ) {
    return (
      <MultipleChoiceDropdown
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
        value={value && value.SelectionOptions ? value.SelectionOptions : []}
        updateValue={newSelections =>
          updateValue(item.Name, {
            Field_ID: item.ID,
            SelectionOptions: newSelections,
          })
        }
      />
    )
  }

  if (item.Type === "Switch") {
    return (
      <Switch
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
        value={value || false}
        updateValue={val => updateValue(item.Name, val)}
      />
    )
  }

  if (item.Type === "Files") {
    return (
      <File
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
        value={value && value.SelectionOptions ? value.SelectionOptions : []}
        updateValue={newSelections =>
          updateValue(item.Name, {
            Field_ID: item.ID,
            SelectionOptions: newSelections,
          })
        }
      />
    )
  }

  if (item.Type === "Single Image") {
    return (
      <SingleImage
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
        value={value}
        updateValue={val => updateValue(item.Name, val)}
        path="EntityImages/"
      />
    )
  }

  if (item.Type === "Audio") {
    return (
      <Audio
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
        value={value}
        updateValue={val => updateValue(item.Name, val)}
        path="EntityAudio/"
      />
    )
  }

  if (item.Type === "Region City Selector") {
    return (
      <RegionCitySelector
        item={item}
        disabled={disabled || item.ReadOnly}
        required={item.Required}
        value={value}
        updateValue={val => updateValue(item.Name, val)}
      />
    )
  }

  console.log(`False Field input ${item.Type}`)
  return <div />
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser } = Auth

  return { currentUser }
}

export default connect(mapStateToProps, {})(FieldPicker)
