import React from "react"

import ErrorPage from "../../components/Common/Utility/ErrorPage"

const Pages404 = () => {
  return (
    <ErrorPage
      code="404"
      title="Page not found"
      description="We're sorry but the page wasn't found"
    />
  )
}

export default Pages404
