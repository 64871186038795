import React from "react"
import { Label, FormGroup, CustomInput } from "reactstrap"

const CheckboxButtons = ({ item, disabled, value, updateValue }) => {
  return (
    <>
      <FormGroup check style={{ padding: "0" }} className="mb-3 col-12">
        <Label className="d-block">{item.Label}</Label>
        {item.Options.map(option => (
          <CustomInput
            type="checkbox"
            key={`${item.ID}_checkbox_option_label_${option.ID}`}
            id={`${item.ID}_checkbox_option_${option.ID}`}
            label={option.Label ? option.Label : option.Name}
            checked={value.find(x => x.ID === option.ID) ? true : false}
            onChange={() => {
              if (!value.find(x => x.ID === option.ID)) {
                const newValues = [...value]
                newValues.push({ ID: option.ID })
                updateValue(newValues)
              } else {
                const newValues = [...value]
                newValues.splice(
                  value.indexOf(value.find(x => x.ID === option.ID)),
                  1
                )
                updateValue(newValues)
              }
            }}
            className="col-4 mr-0"
            inline
            disabled={disabled}
          />
        ))}
      </FormGroup>
    </>
  )
}

export default CheckboxButtons
