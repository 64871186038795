import React from "react"

import ErrorPage from "../../components/Common/Utility/ErrorPage"

const Pages401 = () => {
  let reason = location.search
    ? new URLSearchParams(location.search).get("reason")
    : ""

  switch (reason) {
    case "no_application":
      reason = "It is possible that this client doesn't have an application."
  }

  return (
    <ErrorPage
      code="401"
      title="You are not authorized to view this page"
      description="We're sorry but you don't have the neccessary authorizations to view this page. Please contact your administrator if this was a mistake."
      reason={reason}
    />
  )
}

export default Pages401
