import React from "react"
import { withRouter } from "react-router"

import module from "../../../../constants/administratorCMS/regions.form.json"
import Controler from "../../../../components/Form/Controler"

const Regions = ({ location }) => {
  const params = new URLSearchParams(location.search)

  return (
    <div className="page-content">
      <Controler
        module={module}
        opened={params.get("opened")}
        title={module.DisplayName}
        rules={{ create: "True", update: "True", delete: "True" }}
        privateAPI
      />
    </div>
  )
}

export default withRouter(Regions)
