import React from "react"
import {
  Card,
  CardBody,
  CustomInput,
  Button,
  Input,
  Label,
  Col,
} from "reactstrap"
import { ReactSortable } from "react-sortablejs"
import Select from "react-select"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

import MenuEditorField from "./Field/Field"
import ButtonEditorField from "./Button/Button"

import {
  editMenuItemFieldGroup,
  deleteFieldGroup,
  menuItemAddNewField,
  menuItemAddNewButton,
} from "../../../../../../store/menuItemForm/actions"
import { getSelectTheme } from "helpers/Utils"

const MenuEditorFieldGroup = ({
  loading,
  form,
  fieldGroupIndex,
  entitiesOptions,
  editMenuItemFieldGroupAction,
  menuItemAddNewFieldAction,
  menuItemAddNewButtonAction,
  deleteFieldGroupAction,
  fieldGroupTypeOptions,
  fieldTypeOptions,
  requestMethodOptions,
  buttonTypeOptions,
  menuItemOptions,
  t,
}) => {
  const fieldGroup = form.FieldGroups[fieldGroupIndex]

  const fields = fieldGroup.Fields

  const buttons = fieldGroup.Buttons

  return (
    !loading && (
      <>
        <Card className="mb-5">
          <CardBody>
            <Col>
              <div className="flex-lg-row d-flex col-12 mb-2">
                <div className="col-6">
                  <Label>Display Name</Label>
                  <Input
                    type="text"
                    className=" mb-1 truncate"
                    placeholder="Display Name"
                    value={fieldGroup.DisplayName}
                    onChange={e =>
                      editMenuItemFieldGroupAction(
                        form,
                        fieldGroupIndex,
                        "DisplayName",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="col-3">
                  <Label>Type</Label>
                  <Select
                    theme={getSelectTheme}
                    classNamePrefix="select2-selection"
                    onChange={e => {
                      editMenuItemFieldGroupAction(
                        form,
                        fieldGroupIndex,
                        "FieldGroupType_ID",
                        e.ID
                      )
                      editMenuItemFieldGroupAction(
                        form,
                        fieldGroupIndex,
                        "Type",
                        e.Name
                      )
                    }}
                    options={fieldGroupTypeOptions}
                    defaultValue={fieldGroupTypeOptions.find(
                      x => x.ID === fieldGroup.FieldGroupType_ID
                    )}
                    selectedOption={fieldGroupTypeOptions.find(
                      x => x.ID === fieldGroup.FieldGroupType_ID
                    )}
                    getOptionLabel={option => option.Name}
                    isOptionSelected={option =>
                      option.ID === fieldGroup.FieldGroupType_ID
                    }
                  />
                </div>
                <div className="col-3">
                  <Label>Entity</Label>

                  <Select
                    theme={getSelectTheme}
                    classNamePrefix="select2-selection"
                    onChange={e => {
                      editMenuItemFieldGroupAction(
                        form,
                        fieldGroupIndex,
                        "Entity_ID",
                        e.ID
                      )
                    }}
                    options={entitiesOptions}
                    defaultValue={entitiesOptions.filter(
                      x => x.ID === fieldGroup.Entity_ID
                    )}
                    selectedOption={entitiesOptions.filter(
                      x => x.ID === fieldGroup.Entity_ID
                    )}
                    getOptionLabel={option => option.Name}
                    isOptionSelected={option =>
                      option.ID === fieldGroup.Entity_ID
                    }
                  />
                </div>
              </div>

              {fieldGroup.Type === "Input" && (
                <>
                  <div className="flex-grow-1 min-width-zero">
                    <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero">
                      <div className="align-self-center mr-4 w-10">
                        <Label />
                      </div>
                      <div className="w-40 w-sm-40 pl-2">
                        <Label>Type</Label>
                      </div>

                      <div className="w-40 w-sm-40 ">
                        <Label>Label</Label>
                      </div>

                      <div className="w-10">
                        <div>
                          <Label> </Label>
                        </div>
                      </div>
                      <div className="w-10" />
                    </div>
                  </div>
                  <div className="separator" />
                  <ReactSortable
                    list={fields}
                    setList={fieldsList => {
                      // ReactSortable would sometimes push an undefined object
                      // to the end of the list
                      // To prevent this object from crashing the applicaton,
                      // a check is done, and those objects are removed
                      const checkedFieldsList = [...fieldsList]
                      while (checkedFieldsList.indexOf(undefined) !== -1) {
                        checkedFieldsList.splice(
                          checkedFieldsList.indexOf(undefined),
                          1
                        )
                      }
                      editMenuItemFieldGroupAction(
                        form,
                        fieldGroupIndex,
                        "Fields",
                        checkedFieldsList
                      )
                    }}
                    className="list-unstyled "
                    options={{
                      handle: ".handle",
                    }}
                  >
                    {fields.map((formItem, index) => (
                      <div key={`formItem_${formItem.ID}`}>
                        <MenuEditorField
                          form={form}
                          fieldGroupIndex={fieldGroupIndex}
                          fieldIndex={index}
                          fieldTypeOptions={fieldTypeOptions}
                          entitiesOptions={entitiesOptions}
                        />
                      </div>
                    ))}
                  </ReactSortable>
                  <div className="d-flex mb-5 justify-content-around align-items-lg-center">
                    <Button
                      outline
                      color="primary"
                      className="mt-3"
                      onClick={() =>
                        menuItemAddNewFieldAction(form, fieldGroupIndex)
                      }
                    >
                      <i className="simple-icon-plus btn-group-icon" />
                      {t("menu-editor.add-new")}
                    </Button>
                  </div>
                </>
              )}

              {fieldGroup.Type === "Table" && (
                <>
                  <div className="flex-lg-row d-flex col-12 mb-2">
                    <div className="col-6">
                      <Label>Menu Item</Label>
                      <Select
                        theme={getSelectTheme}
                        classNamePrefix="select2-selection"
                        onChange={e => {
                          editMenuItemFieldGroupAction(
                            form,
                            fieldGroupIndex,
                            "MenuItem_ID",
                            e.ID
                          )
                        }}
                        options={menuItemOptions}
                        defaultValue={menuItemOptions.find(
                          x => x.ID === fieldGroup.MenuItem_ID
                        )}
                        selectedOption={menuItemOptions.find(
                          x => x.ID === fieldGroup.MenuItem_ID
                        )}
                        getOptionLabel={option => option.Name}
                        isOptionSelected={option =>
                          option.ID === fieldGroup.MenuItem_ID
                        }
                      />
                    </div>

                    <div className="col-6">
                      <Label>Limit</Label>
                      <Input
                        type="number"
                        className=" mb-1 truncate"
                        placeholder="Limit"
                        value={fieldGroup.PerPage}
                        onChange={e =>
                          editMenuItemFieldGroupAction(
                            form,
                            fieldGroupIndex,
                            "PerPage",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="flex-lg-row d-flex col-12 mb-2">
                    <div className="col-3">
                      <div className="custom-control custom-checkbox pl-1 align-self-center pr-4 mb-2">
                        <CustomInput
                          className="itemCheck"
                          type="checkbox"
                          id={`fg_visible__${fieldGroup.Visible}`}
                          checked={fieldGroup.Visible}
                          onChange={e =>
                            editMenuItemFieldGroupAction(
                              form,
                              fieldGroupIndex,
                              "Visible",
                              e.target.checked
                            )
                          }
                          label="Visible"
                        />
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="custom-control custom-checkbox pl-1 align-self-center pr-4 mb-2">
                        <CustomInput
                          className="itemCheck"
                          type="checkbox"
                          id={`fg_adminOnly__${fieldGroup.AdminOnly}`}
                          checked={fieldGroup.AdminOnly}
                          onChange={e =>
                            editMenuItemFieldGroupAction(
                              form,
                              fieldGroupIndex,
                              "AdminOnly",
                              e.target.checked
                            )
                          }
                          label="Admin only"
                        />
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="custom-control custom-checkbox pl-1 align-self-center pr-4 mb-2">
                        <CustomInput
                          className="itemCheck"
                          type="checkbox"
                          id={`fg_noExisting__${fieldGroup.NoExistingEntries}`}
                          checked={fieldGroup.NoExistingEntries}
                          onChange={e =>
                            editMenuItemFieldGroupAction(
                              form,
                              fieldGroupIndex,
                              "NoExistingEntries",
                              e.target.checked
                            )
                          }
                          label="No existing entries"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {fieldGroup.Type === "Input" && (
                <>
                  <ReactSortable
                    list={buttons}
                    setList={buttonsList => {
                      // ReactSortable would sometimes push an undefined object
                      // to the end of the list
                      // To prevent this object from crashing the applicaton,
                      // a check is done, and those objects are removed
                      const checkedButtonsList = [...buttonsList]
                      while (checkedButtonsList.indexOf(undefined) !== -1) {
                        checkedButtonsList.splice(
                          checkedButtonsList.indexOf(undefined),
                          1
                        )
                      }
                      editMenuItemFieldGroupAction(
                        form,
                        fieldGroupIndex,
                        "Buttons",
                        checkedButtonsList
                      )
                    }}
                    className="list-unstyled "
                  >
                    {buttons.map((buttonItem, index) => (
                      <div key={`button_editor_field_${buttonItem.ID}`}>
                        <ButtonEditorField
                          form={form}
                          fieldGroupIndex={fieldGroupIndex}
                          buttonIndex={index}
                          requestMethodOptions={requestMethodOptions}
                          buttonTypeOptions={buttonTypeOptions}
                        />
                      </div>
                    ))}
                  </ReactSortable>

                  <div className="d-flex mb-5 justify-content-around align-items-lg-center">
                    <Button
                      outline
                      color="primary"
                      className="mt-3"
                      onClick={() =>
                        menuItemAddNewButtonAction(form, fieldGroupIndex)
                      }
                    >
                      <i className="simple-icon-plus btn-group-icon" />
                      {t("menu-editor.add-button")}
                    </Button>
                  </div>
                </>
              )}

              <div className="d-flex mb-5 justify-content-around align-items-lg-center">
                <Button
                  outline
                  color="primary"
                  className="mt-3"
                  onClick={() => deleteFieldGroupAction(form, fieldGroupIndex)}
                >
                  <i className="simple-icon-minus btn-group-icon" />
                  {t("menu-editor.delete")}
                </Button>
              </div>
            </Col>
          </CardBody>
        </Card>
      </>
    )
  )
}

const mapStateToProps = ({ MenuItemForm }) => {
  const {
    loading,
    form,
    fieldGroupTypeOptions,
    fieldTypeOptions,
    entitiesOptions,
    requestMethodOptions,
    buttonTypeOptions,
  } = MenuItemForm

  return {
    loading,
    form,
    fieldGroupTypeOptions,
    fieldTypeOptions,
    entitiesOptions,
    requestMethodOptions,
    buttonTypeOptions,
  }
}

export default withTranslation()(
  connect(mapStateToProps, {
    editMenuItemFieldGroupAction: editMenuItemFieldGroup,
    deleteFieldGroupAction: deleteFieldGroup,
    menuItemAddNewFieldAction: menuItemAddNewField,
    menuItemAddNewButtonAction: menuItemAddNewButton,
  })(MenuEditorFieldGroup)
)
