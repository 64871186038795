import React from "react"
import { Input } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"
import Switch from "rc-switch"
import "rc-switch/assets/index.css"

import { ThemeColors } from "../../../../helpers/ThemeColors"

const colors = ThemeColors()

const QuestionRow = ({ item, updateValue, deleteOption }) => {
  return (
    <div
      key={`question_${item.Question_ID}`}
      className={`p-2 d-flex flex-lg-row justify-content-between min-width-zero `}
    >
      <div className="align-self-center pr-4 w-10">
        <button
          className="text-only-button"
          type="button"
          id={`move_${item.Question_ID}`}
          onClick={() => {}}
        >
          <FontAwesomeIcon
            icon={faGripVertical}
            color={colors.separatorColor}
          />
        </button>
      </div>

      <div className="w-70 w-sm-70 mr-2">
        <Input
          type="text"
          className="list-item-heading truncate"
          placeholder="Label"
          value={item.QuestionText}
          onChange={e => updateValue({ ...item, QuestionText: e.target.value })}
        />
      </div>

      <div className="w-10 align-self-center d-flex justify-content-end">
        <Switch
          className="custom-switch custom-switch-primary custom-switch-small "
          checked={item.QuestionActive}
          onChange={e => updateValue({ ...item, QuestionActive: e })}
        />
      </div>

      <div className="align-self-center pr-4 w-10 d-flex justify-content-end">
        <button
          className="text-only-button"
          type="button"
          id={`delete_${item.Question_ID}`}
          onClick={() => deleteOption()}
        >
          <i className="simple-icon-close" />
        </button>
      </div>
    </div>
  )
}

export default QuestionRow
