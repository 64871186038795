import { StyleSheet, Font } from "@react-pdf/renderer"
import NunitoRegular from "../../../../../assets/fonts/Nunito/static/Nunito-Regular.ttf"
import NunitoSemiBold from "../../../../../assets/fonts/Nunito/static/Nunito-SemiBold.ttf"
import NunitoBold from "../../../../../assets/fonts/Nunito/static/Nunito-Bold.ttf"
import NunitoItalic from "../../../../../assets/fonts/Nunito/static/Nunito-Italic.ttf"

Font.register({
  family: "Nunito",
  format: "truetype",
  fonts: [
    { src: NunitoRegular },
    { src: NunitoRegular, fontWeight: 400 },
    { src: NunitoSemiBold, fontWeight: "bold" },
    { src: NunitoSemiBold, fontStyle: "bold" },
    { src: NunitoBold, fontWeight: "extrabold" },
    { src: NunitoItalic, fontStyle: "italic" },
  ],
})

const themeColor = "#555"
export const styles = StyleSheet.create({
  page: {
    fontFamily: "Nunito",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    height: 45,
  },
  titleContainer: {
    flexDirection: "row",
    marginTop: 24,
  },
  reportTitle: {
    fontSize: 35,
    fontWeight: "bold",
    textTransform: "uppercase",
    marginRight: 10,
  },
  offerNoContainer: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "flex-end",
  },
  offerNo: {
    width: 80,
    fontSize: 12,
    fontWeight: "extrabold",
  },
  offerDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  offerDate: {
    width: 80,
    fontSize: 12,
  },
  label: {
    width: 80,
  },
  headerContainer: {
    marginTop: 36,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontWeight: "bold",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 0,
  },
  container: {
    flexDirection: "row",
    backgroundColor: themeColor,
    borderBottomWidth: 0,
    alignItems: "center",
    height: 24,
    padding: "2px",
    fontStyle: "bold",
    flexGrow: 1,
    color: "#fff",
  },
  description: {
    width: "35%",
    fontSize: 10,
    paddingLeft: 3,
  },
  qty: {
    width: "10%",
    fontSize: 10,
    textAlign: "right",
  },
  rate: {
    width: "10%",
    fontSize: 10,
    textAlign: "right",
  },
  discount: {
    width: "10%",
    fontSize: 10,
    textAlign: "right",
  },
  amount: {
    width: "10%",
    fontSize: 10,
    textAlign: "right",
  },
  PDV: {
    width: "10%",
    fontSize: 10,
    textAlign: "right",
  },
  amountWithPDV: {
    width: "15%",
    fontSize: 10,
    textAlign: "right",
    paddingRight: 3,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
  },
  totalDescription: {
    width: "65%",
    paddingLeft: 3,
  },
  total: {
    width: "10%",
    textAlign: "right",
  },
  totalWithPDV: {
    width: "15%",
    textAlign: "right",
    paddingRight: 3,
  },
  footerContainer: {
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 24,
  },
  footerText: {
    fontSize: 10,
    textAlign: "center",
  },
})
