import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

import { withRouter } from "react-router-dom"
import { NavLink } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  getCMS,
  getCMSAccountEditorVersion,
  getCMSApplicationEditingVersion,
} from "../../store/actions"

const SidebarContent = props => {
  const ref = useRef()
  const [menuItems, setMenuItems] = useState([])
  const [active, setActive] = useState([])
  const [opened, setOpened] = useState([])

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = `${props.location.pathname || ""}`

    const initMenu = () => {
      if (!props.loading) {
        var BreakException = {}

        try {
          menuItems.forEach(menuItem => {
            if (!menuItem.subs && `${menuItem.to}` === pathName) {
              setActive([menuItem.id])
              setOpened([menuItem.id])
              throw BreakException
            } else if (menuItem.subs) {
              menuItem.subs.forEach(sub1 => {
                if (!sub1.subs && `${sub1.to}` === pathName) {
                  setActive([menuItem.id, sub1.id])
                  setOpened([menuItem.id, sub1.id])
                  throw BreakException
                } else if (sub1.subs) {
                  sub1.subs.forEach(sub2 => {
                    if (!sub2.subs && `${sub2.to}` === pathName) {
                      setActive([menuItem.id, sub1.id, sub2.id])
                      setOpened([menuItem.id, sub1.id, sub2.id])
                      throw BreakException
                    } else if (sub2.subs) {
                      sub2.subs.forEach(sub3 => {
                        if (`${sub3.to}` === pathName) {
                          setActive([menuItem.id, sub1.id, sub2.id, sub3.id])
                          setOpened([menuItem.id, sub1.id, sub2.id, sub3.id])
                          throw BreakException
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        } catch (e) {
          if (e !== BreakException) throw e
        }
      }
    }
    initMenu()
  }, [menuItems, location.search])

  // useEffect(() => {
  //   ref.current.recalculate()
  // })

  useEffect(() => {
    setMenuItems([...props.menuItems.filter(x => checkRoles(x))])
  }, [props.menuItems])

  function handleOnClickAction(IDList, activating, action, search) {
    if (activating) {
      setActive([...IDList])
      setOpened([...IDList])
    } else if (opened[1] === IDList[1]) {
      setOpened([])
    } else setOpened([...IDList])

    if (!action) return
    setMenuItems([])

    const params = new URLSearchParams(search || "?")

    if (params.get("appID")) {
      props.getCMSApplicationEditingVersionAction(params.get("appID"))
    } else if (params.get("clientID")) {
      props.getCMSAccountEditorVersionAction(params.get("clientID"))
    } else {
      props.getCMSAction()
    }
  }

  function checkRoles(menuItem) {
    if (!menuItem.roles || menuItem.roles.includes(props.currentUser.role))
      return true
  }

  function getClassName(id) {
    if (active.includes(id)) {
      return "mm-active active"
    } else if (opened.includes(id)) {
      return "mm-active opened-only"
    }

    return ""
  }

  return (
    <React.Fragment>
      <SimpleBar
        style={{
          maxHeight: "100%",
          zIndex: 100,
          backgroundColor: "rgba(235, 237, 237, 0.85)",
        }}
        ref={ref}
      >
        {!props.loading ? (
          <div id="sidebar-menu">
            <ul className="list-unstyled" id="side-menu">
              {menuItems.map(menuItem =>
                menuItem.subs ? (
                  <div key={menuItem.id}>
                    <li className="menu-title">{props.t(menuItem.label)}</li>
                    {menuItem.subs
                      .filter(x => checkRoles(x))
                      .map(sub1 => (
                        <li
                          key={sub1.id}
                          className={`${
                            opened.includes(sub1.id) ? "mm-active" : ""
                          } ${
                            opened.includes(sub1.id) &&
                            !active.includes(sub1.id)
                              ? "opened-only"
                              : ""
                          }`}
                        >
                          <NavLink
                            exact
                            strict
                            to={{
                              pathname: sub1.subs ? "/#" : sub1.to,
                              search: sub1.search,
                            }}
                            activeClassName="mm-active"
                            className={`sidebar-link ${
                              sub1.subs ? "has-arrow" : ""
                            } waves-effect`}
                            onClick={e => {
                              if (sub1.disabled) {
                                e.preventDefault()
                                return
                              }
                              if (sub1.subs) e.preventDefault()
                              handleOnClickAction(
                                [menuItem.id, sub1.id],
                                !sub1.subs,
                                sub1.action,
                                sub1.search
                              )
                            }}
                            isActive={(match, location) => {
                              return (
                                active.includes(sub1.id) ||
                                opened.includes(sub1.id)
                              )
                            }}
                          >
                            <i
                              className={sub1.icon}
                              style={{
                                color: sub1.color || "",
                              }}
                            ></i>
                            <span
                              title={props.t(sub1.label)}
                              className="sidebar-span"
                              style={{
                                color: sub1.color || "",
                              }}
                            >
                              {props.t(sub1.label)}
                            </span>
                          </NavLink>

                          {sub1.subs && (
                            <ul
                              className={`sub-menu`}
                              style={
                                props.isIcon
                                  ? {}
                                  : {
                                      display: opened.includes(sub1.id)
                                        ? "block"
                                        : "none",
                                    }
                              }
                            >
                              {sub1.subs
                                .filter(x => checkRoles(x))
                                .map(sub2 => (
                                  <li
                                    key={sub2.id}
                                    className={getClassName(sub2.id)}
                                  >
                                    <NavLink
                                      exact
                                      strict
                                      to={{
                                        pathname: sub2.subs ? "/#" : sub2.to,
                                        search: sub2.search,
                                      }}
                                      className={`sidebar-link ${
                                        sub2.subs ? "has-arrow" : ""
                                      }`}
                                      onClick={e => {
                                        if (sub2.disabled) {
                                          e.preventDefault()
                                          return
                                        }
                                        if (sub2.subs) e.preventDefault()
                                        handleOnClickAction(
                                          [menuItem.id, sub1.id, sub2.id],
                                          !sub2.subs,
                                          sub2.action,
                                          sub2.search
                                        )
                                      }}
                                      activeClassName="mm-active"
                                      isActive={(match, location) => {
                                        return (
                                          active.includes(sub2.id) ||
                                          opened.includes(sub2.id)
                                        )
                                      }}
                                    >
                                      <i
                                        className={sub2.icon}
                                        style={{
                                          color: sub2.color || "",
                                        }}
                                      ></i>
                                      <span
                                        title={props.t(sub2.label)}
                                        className="sidebar-span"
                                        style={{
                                          color: sub2.color || "",
                                        }}
                                      >
                                        {props.t(sub2.label)}
                                      </span>
                                    </NavLink>

                                    {sub2.subs && (
                                      <ul
                                        className={`sub-menu`}
                                        style={
                                          props.isIcon
                                            ? {}
                                            : {
                                                display: opened.includes(
                                                  sub2.id
                                                )
                                                  ? "block"
                                                  : "none",
                                              }
                                        }
                                      >
                                        {sub2.subs
                                          .filter(x => checkRoles(x))
                                          .map(sub3 => (
                                            <li
                                              key={sub3.id}
                                              className={getClassName(sub3.id)}
                                            >
                                              <NavLink
                                                exact
                                                strict
                                                to={{
                                                  pathname: sub3.to,
                                                  search: sub3.search,
                                                }}
                                                onClick={() => {
                                                  if (sub3.disabled) {
                                                    e.preventDefault()
                                                    return
                                                  }
                                                  handleOnClickAction(
                                                    [
                                                      menuItem.id,
                                                      sub1.id,
                                                      sub2.id,
                                                      sub3.id,
                                                    ],
                                                    true,
                                                    sub3.action,
                                                    sub3.search
                                                  )
                                                }}
                                                activeClassName="mm-active"
                                                isActive={(match, location) => {
                                                  return active.includes(
                                                    sub3.id
                                                  )
                                                }}
                                                className={`sidebar-link`}
                                              >
                                                <i
                                                  className={sub3.icon}
                                                  style={{
                                                    color: sub3.color || "",
                                                  }}
                                                ></i>
                                                <span
                                                  title={props.t(sub3.label)}
                                                  className="sidebar-span"
                                                  style={{
                                                    color: sub3.color || "",
                                                  }}
                                                >
                                                  {props.t(sub3.label)}
                                                </span>
                                              </NavLink>
                                            </li>
                                          ))}
                                      </ul>
                                    )}
                                  </li>
                                ))}
                            </ul>
                          )}
                        </li>
                      ))}
                  </div>
                ) : (
                  <li key={menuItem.id}>
                    <NavLink
                      exact
                      strict
                      to={{ pathname: menuItem.to, search: menuItem.search }}
                      activeClassName="mm-active"
                      className={`sidebar-link waves-effect`}
                      onClick={() => {
                        if (menuItem.disabled) {
                          e.preventDefault()
                          return
                        }
                        handleOnClickAction(
                          [menuItem.id],
                          true,
                          menuItem.action,
                          menuItem.search
                        )
                      }}
                      isActive={(match, location) => {
                        return active.includes(menuItem.id)
                      }}
                    >
                      <i
                        className={menuItem.icon}
                        style={{
                          color: menuItem.color || "",
                        }}
                      />
                      <span
                        title={props.t(menuItem.label)}
                        className="sidebar-span"
                        style={{
                          color: menuItem.color || "",
                        }}
                      >
                        {props.t(menuItem.label)}
                      </span>
                    </NavLink>
                  </li>
                )
              )}
            </ul>
          </div>
        ) : (
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li />
            </ul>
          </div>
        )}
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

const mapStateToProps = ({ CMS, Auth, Layout }) => {
  const { menuItems, loading: cmsLoading } = CMS
  const { currentUser, loading: authLoading } = Auth
  const { leftSidebarIsIcon } = Layout

  return {
    menuItems,
    loading: authLoading && cmsLoading,
    currentUser,
    isIcon: leftSidebarIsIcon,
  }
}

export default connect(mapStateToProps, {
  getCMSAccountEditorVersionAction: getCMSAccountEditorVersion,
  getCMSAction: getCMS,
  getCMSApplicationEditingVersionAction: getCMSApplicationEditingVersion,
})(withRouter(withTranslation()(SidebarContent)))
