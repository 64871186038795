import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  MENU_ITEM_FORM_GET,
  MENU_ITEM_FORM_SUBMIT,
  RELOAD_ENTITIES,
} from "./actionTypes"

import {
  initialGetMenuItemFormSuccess,
  getMenuItemFormSuccess,
  getMenuItemFormError,
  reloadEntitesSuccess,
} from "./actions"
import {
  getFormByFormID,
  setFormStructure,
  getAllFieldTypesForCMSCreator,
  getAllFieldGroupTypes,
  getAllEntities,
  getAllButtonTypes,
  getApplicationMenuByApplicationID,
  getAllGlobalEntities,
} from "../../helpers/API"
import {
  compareCroatianString,
  getLevelTwoMenuItemsFromApplicationMenu,
} from "../../helpers/Utils"
import {
  fieldTypeOptionsWithChoices,
  fieldTypeOptionsWithEntities,
} from "../../constants/defaultValues"

const getMenuItemFormRequest = async (formID, appID) => {
  return await new Promise(success => {
    setTimeout(async () => {
      const fieldTypeOptionsRequest = await getAllFieldTypesForCMSCreator()
      const fieldTypeOptionsProccessed = [...fieldTypeOptionsRequest]
      fieldTypeOptionsRequest.forEach(
        (fieldTypeOption, fieldTypeOptionIndex) => {
          fieldTypeOptionsProccessed[fieldTypeOptionIndex].options =
            fieldTypeOption.FieldTypes
        }
      )

      const fieldGroupTypeOptions = await getAllFieldGroupTypes()
      const entitiesOptions = await getAllEntities()
      entitiesOptions.sort((a, b) => compareCroatianString(a.Name, b.Name))
      const globalEntitiesOptions = await getAllGlobalEntities()
      globalEntitiesOptions.sort((a, b) =>
        compareCroatianString(a.Name, b.Name)
      )
      const buttonTypeOptions = await getAllButtonTypes()
      const applicationMenuRequest = await getApplicationMenuByApplicationID(
        appID
      )
      const levelTwoMenuItems = getLevelTwoMenuItemsFromApplicationMenu(
        applicationMenuRequest
      )

      const requestMethodOptions = [
        { Name: "PUT" },
        { Name: "POST" },
        { Name: "DELETE" },
        { Name: "GET" },
      ]

      const form = await getFormByFormID(formID)
      success({
        form,
        fieldTypeOptions: fieldTypeOptionsProccessed,
        fieldGroupTypeOptions,
        entitiesOptions,
        globalEntitiesOptions,
        requestMethodOptions,
        buttonTypeOptions,
        menuItemOptions: levelTwoMenuItems,
      })
    }, 1000)
  })
    .then(response => response)
    .catch(error => error)
}

const submitMenuItemFormRequest = async form => {
  return await new Promise(success => {
    setTimeout(async () => {
      // removes ID's that were added
      const apiForm = { ...form }
      form.FieldGroups.forEach((fieldGroup, fieldGroupIndex) => {
        if (fieldGroup.Type === "Input") {
          fieldGroup.Fields.forEach((field, fieldIndex) => {
            if (field.ID && field.ID.includes("field_id")) {
              apiForm.FieldGroups[fieldGroupIndex].Fields[fieldIndex].ID =
                undefined
            }

            if (
              field.SelectionOptions &&
              fieldTypeOptionsWithChoices.includes(field.Type)
            ) {
              field.SelectionOptions.forEach(
                (selectionOption, selectionOptionIndex) => {
                  if (selectionOption.ID.includes("option_id_")) {
                    apiForm.FieldGroups[fieldGroupIndex].Fields[
                      fieldIndex
                    ].SelectionOptions[selectionOptionIndex].ID = undefined
                  }

                  apiForm.FieldGroups[fieldGroupIndex].Fields[
                    fieldIndex
                  ].SelectionOptions[selectionOptionIndex].Order =
                    selectionOptionIndex

                  apiForm.FieldGroups[fieldGroupIndex].Fields[
                    fieldIndex
                  ].SelectionOptions[selectionOptionIndex].chosen = undefined

                  apiForm.FieldGroups[fieldGroupIndex].Fields[
                    fieldIndex
                  ].SelectionOptions[selectionOptionIndex].selected = undefined
                }
              )
            } else if (field.SelectionOptions) {
              apiForm.FieldGroups[fieldGroupIndex].Fields[
                fieldIndex
              ].SelectionOptions = undefined
            }

            if (
              !fieldTypeOptionsWithEntities.includes(field.Type) &&
              field.SelectionEntity_ID
            ) {
              apiForm.FieldGroups[fieldGroupIndex].Fields[
                fieldIndex
              ].SelectionEntity_ID = undefined
            }

            if (
              !fieldTypeOptionsWithEntities.includes(field.Type) &&
              field.SelectionGlobalEntity_ID
            ) {
              apiForm.FieldGroups[fieldGroupIndex].Fields[
                fieldIndex
              ].SelectionGlobalEntity_ID = undefined
            }

            apiForm.FieldGroups[fieldGroupIndex].Fields[fieldIndex].chosen =
              undefined
            apiForm.FieldGroups[fieldGroupIndex].Fields[fieldIndex].selected =
              undefined

            apiForm.FieldGroups[fieldGroupIndex].Fields[fieldIndex].Order =
              fieldIndex
          })
        }

        fieldGroup.Buttons.forEach((button, buttonIndex) => {
          const newButtonMap = {}
          Object.keys(
            apiForm.FieldGroups[fieldGroupIndex].Buttons[buttonIndex]
          ).forEach(key => {
            if (
              key !== "chosen" &&
              key !== "selected" &&
              (key !== "ID" ||
                !apiForm.FieldGroups[fieldGroupIndex].Buttons[buttonIndex][
                  key
                ].includes("button_id"))
            ) {
              newButtonMap[key] =
                apiForm.FieldGroups[fieldGroupIndex].Buttons[buttonIndex][key]
            }
          })

          apiForm.FieldGroups[fieldGroupIndex].Buttons[buttonIndex] =
            newButtonMap
        })

        apiForm.FieldGroups[fieldGroupIndex].chosen = undefined
        apiForm.FieldGroups[fieldGroupIndex].selected = undefined
        apiForm.FieldGroups[fieldGroupIndex].Order = fieldGroupIndex

        if (fieldGroup.ID && fieldGroup.ID.includes("field_group_id")) {
          apiForm.FieldGroups[fieldGroupIndex].ID = undefined
        }
      })

      const response = await setFormStructure(apiForm)

      if (!response.ErrorMessage) {
        success(response)
      }
    }, 1000)
  })
    .then(response => response)
    .catch(error => error)
}

const reloadEntitiesRequest = async () => {
  return await new Promise(success => {
    setTimeout(async () => {
      const entitiesOptions = await getAllEntities()
      entitiesOptions.sort((a, b) => compareCroatianString(a.Name, b.Name))
      success(entitiesOptions)
    }, 1000)
  })
    .then(response => response)
    .catch(error => error)
}

function* getMenuItemForm({ payload }) {
  try {
    const { formID, appID } = payload
    const response = yield call(getMenuItemFormRequest, formID, appID)
    yield put(initialGetMenuItemFormSuccess(response))
  } catch (error) {
    yield put(getMenuItemFormError(error))
  }
}

function* submitMenuItemForm({ payload }) {
  try {
    const form = payload
    const response = yield call(submitMenuItemFormRequest, form)
    yield put(getMenuItemFormSuccess(response))
  } catch (error) {
    yield put(getMenuItemFormError(error))
  }
}

function* reloadEntities() {
  try {
    const response = yield call(reloadEntitiesRequest)
    yield put(reloadEntitesSuccess(response))
  } catch (error) {
    yield put(getMenuItemFormError(error))
  }
}

export function* watchGetMenuItemForm() {
  yield takeLatest(MENU_ITEM_FORM_GET, getMenuItemForm)
}

export function* watchMenuItemFormSubmit() {
  yield takeEvery(MENU_ITEM_FORM_SUBMIT, submitMenuItemForm)
}

export function* watchReloadEntities() {
  yield takeLatest(RELOAD_ENTITIES, reloadEntities)
}

export default function* rootSaga() {
  yield all([
    fork(watchGetMenuItemForm),
    fork(watchMenuItemFormSubmit),
    fork(watchReloadEntities),
  ])
}
