import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { getUserInfo, logoutUser } from "./store/auth/actions"
import {
  getCMS,
  getCMSApplicationEditingVersion,
  getCMSAccountEditorVersion,
} from "./store/cms/actions"
import { auth } from "./helpers/Firebase"

import App from "./App"

const AppLoader = ({
  triedToGetUserInfo,
  currentUser,
  getUserInfoAction,
  loading,
  cmsLoading,
  clientID,
  getCMSAction,
  getCMSAccountEditorVersionAction,
  getCMSApplicationEditingVersionAction,
}) => {
  const location = window.location

  useEffect(() => {
    if (!currentUser && !triedToGetUserInfo) {
      const unsubscribe = auth.onAuthStateChanged(user => {
        getUserInfoAction(user && user.uid)
        unsubscribe()
      })
    }
  }, [])

  useEffect(() => {
    if (!currentUser || cmsLoading) return

    const params = new URLSearchParams(location.search)

    if (params.get("appID")) {
      getCMSApplicationEditingVersionAction(params.get("appID"))
    } else if (params.get("clientID")) {
      getCMSAccountEditorVersionAction(params.get("clientID"))
    } else {
      getCMSAction()
    }
  }, [location.search, currentUser && currentUser.uid, clientID])

  return loading || cmsLoading ? <div className="loading" /> : <App />
}

const mapStateToProps = ({ Auth, CMS }) => {
  const { triedToGetUserInfo, currentUser, loading } = Auth
  const { loading: cmsLoading, clientID } = CMS

  return { triedToGetUserInfo, currentUser, loading, cmsLoading, clientID }
}

export default connect(mapStateToProps, {
  getUserInfoAction: getUserInfo,
  logoutUserAction: logoutUser,
  getCMSAction: getCMS,
  getCMSApplicationEditingVersionAction: getCMSApplicationEditingVersion,
  getCMSAccountEditorVersionAction: getCMSAccountEditorVersion,
})(AppLoader)
