import {
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_NO_USER,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_AVATAR_URL,
} from "./actionTypes"

const INIT_STATE = {
  currentUser: null,
  triedToGetUserInfo: false,
  forgotUserMail: "",
  newPassword: "",
  resetPasswordCode: "",
  loading: true,
  error: "",
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_INFO:
      return { ...state, loading: true, triedToGetUserInfo: true }
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        triedToGetUserInfo: true,
        loading: false,
        currentUser: action.payload,
        error: "",
      }
    case GET_USER_INFO_NO_USER:
      return {
        ...state,
        triedToGetUserInfo: true,
        loading: false,
        error: "",
      }
    case LOGIN_USER:
      return { ...state, loading: true, error: "" }
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: "",
      }
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      }
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: "" }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: "",
      }
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: "",
        error: action.payload.message,
      }
    case RESET_PASSWORD:
      return { ...state, loading: true, error: "" }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: "",
        error: "",
      }
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: "",
        resetPasswordCode: "",
        error: action.payload.message,
      }
    case REGISTER_USER:
      return { ...state, loading: true, error: "" }
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: "",
      }
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      }
    case LOGOUT_USER:
      return { ...state, currentUser: null, error: "" }
    case CHANGE_AVATAR_URL:
      return {
        ...state,
        currentUser: { ...state.currentUser, avatarURL: action.payload },
      }

    default:
      return { ...state }
  }
}
