import React, { useState } from "react"
import { Label } from "reactstrap"
import DatePicker from "react-datepicker"
import { AvGroup } from "availity-reactstrap-validation"

const DateTime = ({ item, disabled, updateValue, value }) => {
  const [dateTime, setDateTime] = useState(value)

  return (
    <AvGroup>
      <Label>{item.Label}</Label>
      <div className="mb-3">
        <DatePicker
          className="form-control"
          selected={dateTime}
          onChange={val => {
            setDateTime(val)
            updateValue(val)
          }}
          placeholderText={dateTime}
          dateFormat="dd/MM/yyyy HH:mm"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={5}
          timeCaption="Time"
          disabled={disabled}
        />
      </div>
    </AvGroup>
  )
}

export default DateTime
