import React from "react"

import FieldGroupPicker from "../FieldGroups/FieldGroupPicker"

// Classic form used when editing entries gotten from API Calls or creating new ones
const Form = ({
  ID,
  form,
  excludeKeys,
  onAdd,
  onSubmit,
  onCancel,
  onDelete,
  rules,
  propValues, // values to add to the form as default
  privateAPI,
  noSubTables,
  noDelete,
  propClient_ID, // for calls that refer to a specific client but aren't made "inside" that client
  propApplication_ID, // for calls that refer to a specific client but aren't made "inside" that client
}) => {
  const inputFieldGroup = form.FieldGroups.find(x => x.Type === "Input")
  const parentURL = inputFieldGroup.EntityRequestURL
  const parentRelationKey = inputFieldGroup.EntityRelationKey

  return (
    <div className="p-2">
      {form.FieldGroups.map(fieldGroup => (
        <FieldGroupPicker
          key={`field_group_${fieldGroup.ID}`}
          fieldGroup={fieldGroup}
          ID={ID}
          formID={form.ID}
          propValues={propValues}
          excludeKeys={excludeKeys}
          rules={rules}
          parentURL={parentURL}
          parentRelationKey={parentRelationKey}
          privateAPI={privateAPI}
          onCancel={onCancel}
          onDelete={onDelete}
          onAdd={onAdd}
          onSubmit={onSubmit}
          noSubTables={noSubTables}
          noDelete={noDelete}
          propClient_ID={propClient_ID}
          propApplication_ID={propApplication_ID}
        />
      ))}
    </div>
  )
}

export default React.memo(Form)
