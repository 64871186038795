import React from "react"
import { withRouter } from "react-router"

import Controler from "../../../../components/Admin/AccountManager/Editor/Controler"

const Creator = ({ history }) => {
  return <Controler history={history} clientURL="businesses" type="business" />
}

export default withRouter(Creator)
