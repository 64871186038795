import React, { useState } from "react"
import { Label, Row, Card, CardBody } from "reactstrap"
import Switch from "rc-switch"
import "rc-switch/assets/index.css"
import WorkingHoursDay from "./WorkingHoursDay"

const fullDaysOpenedDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
const fullDaysNotOpenedDays = ["Mon-Fri", "Sat", "Sun"]

const checkIfMondayToFridayIsTheSame = workingHoursInAWeek => {
  if (workingHoursInAWeek.length !== 7) return false

  let ret = false

  const mondayValue = workingHoursInAWeek[0]

  let i = 1
  for (i = 1; i < 5; i += 1) {
    if (mondayValue !== workingHoursInAWeek[i]) ret = true
  }

  return ret
}

const WorkingHours = ({ item, value, updateValue, disabled }) => {
  const workingHoursPerDay =
    value === "unknown"
      ? "closed,closed,closed,closed,closed,closed,closed".split(",")
      : value.split(",")

  const [fullDaysOpened, setFullDaysOpened] = useState(
    checkIfMondayToFridayIsTheSame(workingHoursPerDay)
  )

  return (
    <div>
      <Label>{item.Label}</Label>
      <Card>
        <CardBody>
          <Row className="col-12 mt-1 p-1 align-items-center">
            <Label className="col-3">Show All</Label>

            <div className="col-9">
              <Switch
                className="custom-switch custom-switch-primary custom-switch-small"
                checked={fullDaysOpened}
                onChange={e => {
                  if (e) {
                    setFullDaysOpened(true)
                  } else {
                    const newWorkingHoursPerDay = [...workingHoursPerDay]
                    const mondayValue = workingHoursPerDay[0]
                    let i = 1
                    for (i = 1; i < 5; i += 1) {
                      newWorkingHoursPerDay[i] = mondayValue
                    }
                    updateValue(newWorkingHoursPerDay.join(","))
                    setFullDaysOpened(false)
                  }
                }}
                disabled={disabled || value === "unknown"}
              />
            </div>
          </Row>
          {!fullDaysOpened ? (
            <>
              {fullDaysNotOpenedDays.map((fullDaysNotOpenedDay, index) =>
                index === 0 ? (
                  <div
                    key={`working_hours_day_not_opened_full_${item.ID}_${fullDaysNotOpenedDay}`}
                  >
                    <WorkingHoursDay
                      label={fullDaysNotOpenedDay}
                      value={workingHoursPerDay[index]}
                      updateValue={newVal => {
                        const newWorkingHoursPerDay = [...workingHoursPerDay]
                        let i = 0
                        for (i = 0; i < 5; i += 1) {
                          newWorkingHoursPerDay[i] = newVal
                        }

                        updateValue(newWorkingHoursPerDay.join(","))
                      }}
                      disabled={disabled}
                    />
                  </div>
                ) : (
                  <div
                    key={`working_hours_day_not_opened_full_${item.ID}_${fullDaysNotOpenedDay}`}
                  >
                    <WorkingHoursDay
                      label={fullDaysNotOpenedDay}
                      value={workingHoursPerDay[index + 4]}
                      updateValue={newVal => {
                        const newWorkingHoursPerDay = [...workingHoursPerDay]
                        newWorkingHoursPerDay[index + 4] = newVal
                        updateValue(newWorkingHoursPerDay.join(","))
                      }}
                      disabled={disabled}
                    />
                  </div>
                )
              )}
            </>
          ) : (
            <>
              {workingHoursPerDay.map((workingHoursInDay, index) => (
                <div
                  key={`working_hours_day_not_opened_full_${item.ID}_${fullDaysOpenedDays[index]}`}
                >
                  <WorkingHoursDay
                    label={fullDaysOpenedDays[index]}
                    value={value === "unkown" ? value : workingHoursInDay}
                    updateValue={newVal => {
                      const newWorkingHoursPerDay = [...workingHoursPerDay]
                      newWorkingHoursPerDay[index] = newVal
                      updateValue(newWorkingHoursPerDay.join(","))
                    }}
                    disabled={disabled}
                  />
                </div>
              ))}
            </>
          )}
          <Row className="col-12 mt-1 p-1 align-items-center">
            <Label className="col-3">Unknown</Label>

            <div className="col-9">
              <Switch
                className="custom-switch custom-switch-primary custom-switch-small"
                checked={value === "unknown"}
                onChange={e => {
                  if (e) updateValue("unknown")
                  else
                    updateValue(
                      "closed,closed,closed,closed,closed,closed,closed"
                    )
                }}
                disabled={disabled}
              />
            </div>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}

export default WorkingHours
