import React, { useState } from "react"
import { Input, Col } from "reactstrap"
import { connect } from "react-redux"
import Select from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"

import {
  editMenuItemFieldGroupField,
  deleteField,
} from "../../../../../../../store/menuItemForm/actions"
import { ThemeColors } from "../../../../../../../helpers/ThemeColors"
import MenuEditorFieldDetails from "./Details/FieldDetails"
import {
  defaultOptions,
  fieldTypeOptionsWithChoices,
} from "../../../../../../../constants/defaultValues"
import { getSelectTheme } from "helpers/Utils"

const colors = ThemeColors()

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.Name}</span>
  </div>
)

const MenuEditorField = ({
  form,
  fieldGroupIndex,
  fieldIndex,
  fieldTypeOptions,
  editMenuItemFieldGroupFieldAction,
  deleteFieldAction,
}) => {
  const field = form.FieldGroups[fieldGroupIndex].Fields[fieldIndex]
  const [showDetails, setShowDetails] = useState(false)

  const editField = (key, newValue) => {
    editMenuItemFieldGroupFieldAction(
      form,
      fieldGroupIndex,
      fieldIndex,
      key,
      newValue
    )
  }

  const findSelectedFieldType = selectedID => {
    let selected

    fieldTypeOptions.forEach(fieldTypeGroup => {
      if (fieldTypeGroup.FieldTypes.find(x => x.ID === selectedID)) {
        selected = fieldTypeGroup.FieldTypes.find(x => x.ID === selectedID)
      }
    })

    return selected
  }

  return (
    <>
      <Col className="mb-2 mt-2">
        <div
          className="flex-grow-1 min-width-zero"
          // style={{
          //   border:
          //     fieldTypeOptionsWithChoices.includes(field.Type) &&
          //     "red 1px solid",
          // }}
        >
          <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero">
            <div className="align-self-center pr-4 w-10">
              <button
                className="text-only-button handle"
                type="button"
                id={`move_${field.ID}`}
                onClick={() => {}}
              >
                <FontAwesomeIcon
                  icon={faGripVertical}
                  color={colors.separatorColor}
                />
              </button>
            </div>
            <div className="w-30 w-sm-30 mr-2">
              <Select
                theme={getSelectTheme}
                classNamePrefix="select2-selection"
                onChange={e => {
                  if (
                    fieldTypeOptionsWithChoices.includes(e.Name) &&
                    !field.SelectionOptions
                  ) {
                    editField("SelectionOptions", defaultOptions)
                  }
                  if (
                    field.Type === "Dropdown" &&
                    fieldTypeOptionsWithChoices.includes(e.Name)
                  ) {
                    const newSelectionOptions = []
                    field.SelectionOptions.forEach(selectionOption => {
                      if (selectionOption.Type === "Option")
                        newSelectionOptions.push(selectionOption)
                    })

                    editField("SelectionOptions", newSelectionOptions)
                  }
                  if (
                    !fieldTypeOptionsWithChoices.includes(e.Name) &&
                    field.SelectionOptions
                  ) {
                    editField("SelectionOptions", undefined)
                  }

                  editField("FieldType_ID", e.ID)
                  editField("Type", e.Name)
                }}
                options={fieldTypeOptions}
                defaultValue={findSelectedFieldType(field.FieldType_ID)}
                selectedOption={findSelectedFieldType(field.FieldType_ID)}
                getOptionLabel={option => option.Name}
                isOptionSelected={option => option.ID === field.FieldType_ID}
                formatGroupLabel={formatGroupLabel}
              />
            </div>

            <div className="w-30 w-sm-30">
              <Input
                type="text"
                className="list-item-heading truncate"
                name={field.Label}
                placeholder="Label"
                value={field.Label}
                onChange={e => editField("Label", e.target.value)}
              />
            </div>

            <div className="custom-control custom-checkbox align-self-center w-5 w-sm-5">
              <button
                className="text-only-button"
                type="button"
                id={`details_${field.ID}`}
                onClick={() => setShowDetails(!showDetails)}
              >
                <i className="simple-icon-eye" />
              </button>
            </div>
            <div className="custom-control custom-checkbox align-self-center w-5 w-sm-5">
              <div>
                <div>
                  <button
                    className="text-only-button"
                    type="button"
                    id={`delete_${field.ID}`}
                    onClick={() =>
                      deleteFieldAction(form, fieldGroupIndex, fieldIndex)
                    }
                  >
                    <i className="simple-icon-trash" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showDetails && (
          <MenuEditorFieldDetails
            field={field}
            editField={(key, value) => editField(key, value)}
          />
        )}
      </Col>
      <div className="separator" />
    </>
  )
}

const mapStateToProps = ({ MenuItemForm }) => {
  const { fieldTypeOptions } = MenuItemForm
  return { fieldTypeOptions }
}
export default connect(mapStateToProps, {
  deleteFieldAction: deleteField,
  editMenuItemFieldGroupFieldAction: editMenuItemFieldGroupField,
})(MenuEditorField)
