import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { Col, Input, Modal, ModalBody, Row, Button, Label } from "reactstrap"
import moment from "moment"
import { connect } from "react-redux"

import DynamicTable from "components/Form/Table/DynamicTable"
import { firestore, database, firestoreObject } from "helpers/Firebase"
import Form from "components/Form/Components/Form"
import { getCurrentUser, setCurrentUser } from "helpers/Utils"
import Dropdown from "components/Form/Fields/FieldTypes/Dropdown"

const UserCreateNew = ({ history, match, t, currentUser }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [opened, setOpened] = useState()
  const [appStructure, setAppStructure] = useState()
  const [status, setStatus] = useState()
  const [message, setMessage] = useState("")
  const statusOptions = [
    { ID: "Pending", Label: "Pending", Message: "" },
    {
      ID: "Missing info",
      Label: "Missing info",
      Message:
        "Zahvaljujemo se na Vašem unosu! Molimo, dopunite unos sa sljedećim podatcima da bismo ga mogli prihvatiti: ",
    },
    {
      ID: "Rejected",
      Label: "Rejected",
      Message:
        "Vaš pokušaj unosa je odbijen. Ako mislite da je ovo greška, pokušajte ponovo. Glavni razlozi za odbijanje unosa su neispravni podatci i nedostatak podataka.",
    },
    {
      ID: "Accepted",
      Label: "Accepted",
      Message: "Vaš unos je prihvačen! Hvala Vam što se dio CityX-a!",
    },
  ]

  useEffect(() => {
    database
      .ref(
        "/Production/Applications/DataBase/715D58A9-1DE5-4350-A1C9-38EEE254A79C/LatestBuild"
      )
      .once("value", snapshot => {
        const latestBuild = snapshot.val()
        database
          .ref(
            `/Production/Applications/DataBase/715D58A9-1DE5-4350-A1C9-38EEE254A79C/Builds/${latestBuild}`
          )
          .once("value", appSnapshot => {
            setAppStructure(JSON.parse(appSnapshot.val().Structure))

            firestore
              .collection("City/Requests/UserCreateNew")
              .where("Status", "==", "Pending")
              .orderBy("LastUpdate", "asc")
              .onSnapshot(
                {
                  // Listen for document metadata changes
                  includeMetadataChanges: true,
                },
                querySnapshot => {
                  const requests = {}
                  querySnapshot.forEach(
                    doc => (requests[doc.id] = { ...doc.data(), ID: doc.id })
                  )
                  setData(requests)
                  setMessage("")
                  setStatus(undefined)
                  setLoading(false)
                }
              )
          })
      })
  }, [match.params.appID])

  const openModal = ID => {
    const openedData = data[ID]

    const userInfo = getCurrentUser()

    userInfo.eeID = userInfo.Authorizations[openedData.Client_ID].EEID

    userInfo.eeSecret = userInfo.Authorizations[openedData.Client_ID].EESecret

    setCurrentUser(userInfo)
    setStatus(openedData.Status)
    setOpened(openedData)
  }

  const closeModal = () => {
    const userInfo = getCurrentUser()

    userInfo.eeID = userInfo.Authorizations.Global.EEID

    userInfo.eeSecret = userInfo.Authorizations.Global.EESecret

    setCurrentUser(userInfo)
    setStatus(undefined)
    setOpened(undefined)
    setMessage("")
  }

  const updateRequest = async () => {
    await firestore.doc(`City/Requests/UserCreateNew/${opened.ID}`).update({
      LastUpdate: firestoreObject.Timestamp.now(),
      Status: status,
      Logs: firestoreObject.FieldValue.arrayUnion({
        Status: status,
        Time: firestoreObject.Timestamp.now(),
        User_ID: currentUser.uid,
        Message: message,
      }),
    })

    const notificationID = firestore
      .collection(
        `/Users/UsersList/DataBase/${opened.User_ID}/Applications/City/Notifications/`
      )
      .doc().id

    const notificationData = {
      City_ID: opened.Client_ID,
      ID: notificationID,
      ReceiveTime: firestoreObject.Timestamp.now(),
      Title: message,
    }

    if (status === "Missing info") {
      notificationData.Navigation = {
        Screen: "AddNewMain",
        Params: {
          firestorePath: `City/Settings/Forms/${opened.MenuItem_ID}`,
          requestPath: `City/Requests/UserCreateNew/${opened.ID}`,
        },
      }
    }

    await firestore
      .doc(
        `/Users/UsersList/DataBase/${opened.User_ID}/Applications/City/Notifications/${notificationID}`
      )
      .set(notificationData)

    setOpened(undefined)
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row>
            <Col>
              {loading ? (
                <div className="loading" />
              ) : (
                <>
                  <Modal
                    isOpen={opened !== undefined}
                    toggle={() => closeModal()}
                    size="xl"
                  >
                    <ModalBody>
                      {opened ? (
                        <Row>
                          <Col>
                            <Dropdown
                              item={{
                                Label: "Status",
                                Options: statusOptions,
                              }}
                              value={status}
                              updateValue={val => {
                                setStatus(val)
                                setMessage(
                                  statusOptions.find(x => x.ID === val).Message
                                )
                              }}
                            />
                            <Label>Message</Label>
                            <Input
                              type="textarea"
                              value={message}
                              onChange={e => setMessage(e.target.value)}
                            />

                            <h4 className="d-flex flex-column min-width-zero mt-5 ml-2">
                              {t("Unos")}
                            </h4>

                            <Form
                              form={{
                                ...appStructure.MenuItems[
                                  opened.ParentMenuItem_ID
                                ].MenuItems[opened.MenuItem_ID].Form,
                                // remove submit button if the status is not set to Accepted
                                FieldGroups: [
                                  ...appStructure.MenuItems[
                                    opened.ParentMenuItem_ID
                                  ].MenuItems[
                                    opened.MenuItem_ID
                                  ].Form.FieldGroups.map(fieldGroup => ({
                                    ...fieldGroup,
                                    Buttons:
                                      status === "Accepted"
                                        ? fieldGroup.Buttons
                                        : [],
                                  })),
                                ],
                              }}
                              onSubmit={ID => updateRequest()}
                              rules={{
                                create: "True",
                                update: "True",
                                delete: "True",
                              }}
                              propValues={opened.Values}
                              propClient_ID={opened.Client_ID}
                              propApplication_ID={
                                "715D58A9-1DE5-4350-A1C9-38EEE254A79C"
                              }
                            />

                            {status !== "Accepted" && status !== "Pending" && (
                              <Button
                                color="primary"
                                className="float-right"
                                onClick={() => updateRequest()}
                              >
                                {t("Submit")}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      ) : (
                        <div />
                      )}
                    </ModalBody>
                  </Modal>

                  <div className="d-flex flex-column min-width-zero margin-bottom-10">
                    <h1>User create new</h1>
                  </div>
                  <DynamicTable
                    item={Object.keys(data).map(key => ({
                      ID: data[key].ID,
                      Name: data[key].Values.Name,
                      "Last Update": moment
                        .unix(data[key].LastUpdate.seconds)
                        .format("MM/DD/YYYY HH:mm"),
                    }))}
                    openForm={ID => openModal(ID)}
                    hideCreate
                  />
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser } = Auth
  return { currentUser }
}
export default withTranslation()(
  connect(mapStateToProps, {})(withRouter(UserCreateNew))
)
