import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

import { getAllClientsByCategory } from "../../../helpers/API"
import DynamicTable from "../../Form/Table/DynamicTable"
import {
  adminRoot,
  clientTypeCategoryIDs,
} from "../../../constants/defaultValues"

const StartPage = ({ title, clientCategory, history, t }) => {
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(async () => {
      const data = await getAllClientsByCategory(
        clientTypeCategoryIDs[clientCategory]
      )

      setClients(data)
      setLoading(false)
    }, 100)
  }, [getAllClientsByCategory])

  const openClient = clientID => {
    history.push(`${adminRoot}home?clientID=${clientID}`)
    history.go()
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row>
            <Col>
              <div className="d-flex flex-column min-width-zero margin-bottom-10">
                <h1>{t(title)}</h1>
              </div>
              {loading ? (
                <div className="loading" />
              ) : (
                <DynamicTable
                  openForm={ID => openClient(ID)}
                  item={clients.Relations}
                  hideCreate
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = () => {
  return {}
}
const mapActionToProps = {}

export default withTranslation()(
  connect(mapStateToProps, mapActionToProps)(StartPage)
)
