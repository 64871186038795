import React, { useEffect, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { Button, Row, Col, Modal, ModalBody, Card } from "reactstrap"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import BlockRow from "../../../../components/User/BusinessHomepage/BlockRow"
import {
  blockTypeOptions,
  defaultBlockForHomepage,
} from "../../../../constants/defaultValues"
import {
  getHomepageFromFirestore,
  setHomepageToFirestore,
  timestampToDateString,
} from "../../../../helpers/Utils"
import NotificationAlert from "../../../../components/Common/Notifications/NotificationAlert"
import MultipleAddModal from "../../../../components/User/BusinessHomepage/MultipleAddModal"

const BusinessHomepageDesigner = ({ clientID, cmsLoading, t }) => {
  const [homepage, setHomepage] = useState([])
  const [deleted, setDeleted] = useState([])
  const [modalOpened, setModalOpened] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (cmsLoading || !clientID) return

    getHomepage()
  }, [cmsLoading, clientID])

  const getHomepage = async () => {
    setLoading(true)
    getHomepageFromFirestore(clientID, async val => {
      setHomepage(
        val.map(x => ({
          ...x,
          StartTime: timestampToDateString(x.StartTime),
          EndTime: timestampToDateString(x.EndTime),
        }))
      )
      setLoading(false)
    })
  }

  const editBlock = (blockIndex, key, val) => {
    const newHomepage = [...homepage]
    newHomepage[blockIndex][key] = val
    setHomepage(newHomepage)
  }

  const deleteBlock = (block, blockIndex) => {
    const newHomepage = [...homepage]
    newHomepage.splice(blockIndex, 1)
    setHomepage(newHomepage)
    setDeleted([...deleted, block.ID])
  }

  const addBlock = () => {
    const newHomepage = [...homepage]
    const startDate = new Date()
    const endDate = new Date()
    endDate.setDate(startDate + 7)
    newHomepage.push(defaultBlockForHomepage())
    setHomepage(newHomepage)
  }

  const onSubmit = () => {
    const cleanedHomepage = [...homepage]
    cleanedHomepage.forEach((block, blockIndex) => {
      delete cleanedHomepage[blockIndex].selected
      delete cleanedHomepage[blockIndex].chosen
      cleanedHomepage[blockIndex].Order = blockIndex

      block.Stores.forEach((store, storeIndex) => {
        delete cleanedHomepage[blockIndex].Stores[storeIndex].selected
        delete cleanedHomepage[blockIndex].Stores[storeIndex].chosen
        cleanedHomepage[blockIndex].Stores[storeIndex].Order = storeIndex
      })
    })
    setHomepageToFirestore(clientID, cleanedHomepage, deleted, res => {
      if (!res)
        NotificationAlert({
          type: "success",
          title: "Success",
          expiration: 3000,
        })
      else
        NotificationAlert({
          type: "error",
          title: "Error",
          message: res,
          expiration: 3000,
        })

      setDeleted([])
    })
  }

  return (
    <>
      <Row className="app-row">
        <Col xxs="12">
          {loading ? (
            <div className="loading" />
          ) : (
            <>
              <div className="page-content">
                <div className="container-fluid p-1">
                  <Modal
                    size="xl"
                    isOpen={modalOpened}
                    toggle={() => {
                      setModalOpened(!modalOpened)
                    }}
                  >
                    <ModalBody>
                      {modalOpened && (
                        <MultipleAddModal callback={() => getHomepage()} />
                      )}
                      <div />
                    </ModalBody>
                  </Modal>

                  <div className="d-flex mb-5 justify-content-around align-items-lg-center">
                    <Button
                      color="secondary"
                      className="mt-3"
                      onClick={() => setModalOpened(true)}
                    >
                      {t("form.add-to-multiple")}
                    </Button>

                    <Button
                      color="primary"
                      className="mt-3"
                      onClick={() => onSubmit()}
                    >
                      {t("form.submit")}
                    </Button>
                  </div>
                  <ReactSortable
                    list={homepage}
                    setList={homepageList => {
                      // ReactSortable would sometimes push an undefined object
                      // to the end of the list
                      // To prevent this object from crashing the applicaton,
                      // a check is done, and those objects are removed
                      const checkedHomepageList = [...homepageList]
                      while (checkedHomepageList.indexOf(undefined) !== -1) {
                        checkedHomepageList.splice(
                          checkedHomepageList.indexOf(undefined),
                          1
                        )
                      }
                      setHomepage(checkedHomepageList)
                    }}
                    className="list-unstyled "
                  >
                    {homepage.map((block, blockIndex) => (
                      <Card key={block.ID}>
                        <BlockRow
                          key={block.ID}
                          block={block}
                          blockTypeOptions={blockTypeOptions}
                          editBlock={(key, val) =>
                            editBlock(blockIndex, key, val)
                          }
                          deleteBlock={() => deleteBlock(block, blockIndex)}
                        />
                      </Card>
                    ))}
                  </ReactSortable>

                  <div className="d-flex mb-5 justify-content-around align-items-lg-center">
                    <Button
                      outline
                      color="primary"
                      className="mt-3"
                      onClick={() => addBlock()}
                    >
                      <i className="simple-icon-plus btn-group-icon" />
                      {t("menu-editor.add-new")}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { clientID, loading } = CMS
  return { clientID, cmsLoading: loading }
}
export default withTranslation()(
  connect(mapStateToProps, {})(BusinessHomepageDesigner)
)
