import React from "react"
import { withRouter } from "react-router"

import adminSettings from "../../../../constants/administratorCMS/edit.cms.administrator.form.json"
import Controler from "../../../../components/Form/Controler"

const SystemSetting = ({ location }) => {
  const params = new URLSearchParams(location.search)

  return (
    <div className="page-content">
      <Controler
        module={adminSettings}
        opened={params.get("opened")}
        title={adminSettings.DisplayName}
        rules={{ create: "True", update: "True", delete: "True" }}
        privateAPI
      />
    </div>
  )
}

export default withRouter(SystemSetting)
