import React, { useState } from "react"

import TabNavigation from "../Common/UI/TabNavigation"
import GalleryDropzone from "./GalleryDropzone"
import GalleryFilesList from "./GalleryFilesList"

const GalleryModal = ({ defaultValue, updateValue, cancel, types }) => {
  const [activeTab, setActiveTab] = useState("files")

  const navItems = [
    { name: "upload", label: "gallery.upload" },
    { name: "files", label: "gallery.files" },
  ]

  return (
    <div>
      <TabNavigation
        activeTab={activeTab}
        setActive={val => setActiveTab(val)}
        navList={navItems}
      />
      <>
        {activeTab === "upload" && <GalleryDropzone cancel={cancel} />}
        {activeTab === "files" && (
          <GalleryFilesList
            defaultValue={defaultValue || []}
            updateValue={updateValue}
            cancel={cancel}
            types={types}
          />
        )}
      </>
    </div>
  )
}

export default GalleryModal
