import React, { useRef, useState, useEffect } from "react"
import { Button } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"

import { defaultFileImage, imageTypes } from "../../constants/defaultValues"
import { getAllFiles } from "../../helpers/API"

const GalleryFilesList = ({ defaultValue, updateValue, cancel, types }) => {
  const [selectedFiles, setSelectedFiles] = useState([...defaultValue])
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(true)
  const scrollBarRef = useRef(null)

  const instanceRandomizer = Math.floor(Math.random() * 100000000)

  useEffect(() => {
    setTimeout(async () => {
      const filesAPI = await getAllFiles()

      setFiles(filesAPI)
      setLoading(false)
    }, 100)
  }, [])

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <div className="gallery-files-list-box">
            {files &&
            files.filter(x => x.Status && (!types || types.includes(x.Type)))
              .length > 0 ? (
              <PerfectScrollbar
                className="gallery-files-list col-12 row p-0 m-0"
                ref={scrollBarRef}
                // containerRef={(ref) => {}}
                options={{ suppressScrollX: true, wheelPropagation: false }}
              >
                {files
                  .filter(x => x.Status && (!types || types.includes(x.Type)))
                  .map(file => (
                    <div
                      key={`modal_${instanceRandomizer}_${file.ID}`}
                      className="col-12 col-sm-3 p-1"
                    >
                      <Button
                        color="none"
                        className={`gallery-file-list-file ${
                          selectedFiles.find(x => x.ID === file.ID)
                            ? "gallery-file-list-file-selected"
                            : ""
                        } `}
                        onClick={() => {
                          if (selectedFiles.find(x => x.ID === file.ID)) {
                            const newFiles = [...selectedFiles]
                            newFiles.splice(
                              newFiles.indexOf(
                                selectedFiles.find(x => x.ID === file.ID)
                              ),
                              1
                            )
                            setSelectedFiles(newFiles)
                          } else {
                            const newFiles = [...selectedFiles]
                            newFiles.push(file)
                            setSelectedFiles(newFiles)
                          }
                        }}
                      >
                        <img
                          src={
                            imageTypes.includes(file.Type)
                              ? file.URL
                              : defaultFileImage
                          }
                          className={`gallery-file-list-file-image mr-2 ${
                            !imageTypes.includes(file.Type) && "p-2"
                          } `}
                          alt={file.Name}
                        />
                        <span>{file.Name}</span>
                      </Button>
                    </div>
                  ))}
              </PerfectScrollbar>
            ) : (
              <div className="gallery-file-modal-no-files">
                <p style={{ margin: 0 }}>No files</p>
              </div>
            )}
          </div>
          <div className="col-12">
            <Button onClick={() => cancel()} color="secondary mt-2 ">
              Cancel
            </Button>
            <Button
              color="primary"
              className="mt-2 float-right"
              onClick={() => {
                updateValue([...selectedFiles])
                cancel()
              }}
            >
              Choose Files
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default GalleryFilesList
