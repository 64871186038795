import React, { useState } from "react"
import Select from "react-select"
import { CustomInput, Input, Label, Modal, ModalBody, Button } from "reactstrap"
import { connect } from "react-redux"

import {
  fieldTypeOptionsWithChoices,
  fieldTypeOptionsWithEntities,
} from "../../../../../../../../constants/defaultValues"
import FieldDetailsOptions from "./FieldDetailsOptions"
import SelectionLabelCreator from "./SelectionLabelCreator"
import { getSelectTheme } from "helpers/Utils"

const MenuEditorFieldDetails = ({
  field,
  editField,
  entitiesOptions,
  globalEntitiesOptions,
}) => {
  const [modalOpened, setModalOpened] = useState(false)

  return (
    <>
      <div className="flex-grow-1 min-width-zero">
        <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero">
          <div className="w-40 w-sm-40">
            <div className="custom-control custom-checkbox pl-1 align-self-center pr-4 mb-2 mt-2">
              <CustomInput
                className="itemCheck mb-0"
                type="checkbox"
                id={`required_${field.ID}`}
                checked={field.Required}
                label="Required"
                onChange={e => editField("Required", e.target.checked)}
              />
            </div>
            <div className="custom-control custom-checkbox pl-1 align-self-center pr-4 mb-2">
              <CustomInput
                className="itemCheck mb-0"
                type="checkbox"
                id={`read_only_${field.ID}`}
                checked={field.ReadOnly}
                label="Read Only"
                onChange={e => editField("ReadOnly", e.target.checked)}
              />
            </div>
            <div className="custom-control custom-checkbox pl-1 align-self-center pr-4 mb-2">
              <CustomInput
                className="itemCheck mb-0"
                type="checkbox"
                id={`visible_${field.ID}`}
                checked={field.Visible}
                label="Visible"
                onChange={e => editField("Visible", e.target.checked)}
              />
            </div>
            <div className="custom-control custom-checkbox pl-1 align-self-center pr-4 mb-2">
              <CustomInput
                className="itemCheck mb-0"
                type="checkbox"
                id={`adminOnly_${field.ID}`}
                checked={field.AdminOnly}
                label="Admin only"
                onChange={e => editField("AdminOnly", e.target.checked)}
              />
            </div>
            <div className="custom-control custom-checkbox pl-1 align-self-center pr-4 mb-2">
              <CustomInput
                className="itemCheck mb-0"
                type="checkbox"
                id={`unique_${field.ID}`}
                checked={field.Unique}
                label="Unique"
                onChange={e => editField("Unique", e.target.checked)}
              />
            </div>
            <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
              <CustomInput
                className="itemCheck mb-0"
                type="checkbox"
                id={`includeingetall_${field.ID}`}
                checked={field.IncludeInGetAll}
                label="IncludeInGetAll"
                onChange={e => editField("IncludeInGetAll", e.target.checked)}
              />
            </div>
          </div>

          <div className="w-60 w-sm-60">
            <Label>SQL Name</Label>
            <Input
              type="text"
              className="mb-2 list-item-heading truncate"
              name={field.Name}
              placeholder="Name"
              value={field.Name}
              onChange={e => editField("Name", e.target.value)}
            />

            <Label>Prompt</Label>
            <Input
              type="text"
              className="list-item-heading truncate mb-2"
              name={field.Prompt}
              placeholder="Prompt"
              value={field.Prompt}
              onChange={e => editField("Prompt", e.target.value)}
            />

            {field.Type === "Text" && (
              <>
                <Label>Default Value</Label>
                <Input
                  type="text"
                  className="list-item-heading truncate mb-2"
                  name={field.DefaultValue}
                  placeholder="DefaultValue"
                  value={field.DefaultValue}
                  onChange={e => editField("DefaultValue", e.target.value)}
                />
              </>
            )}

            {fieldTypeOptionsWithEntities.includes(field.Type) && (
              <>
                <Modal
                  isOpen={modalOpened}
                  toggle={() => setModalOpened(!modalOpened)}
                  size="xl"
                >
                  <ModalBody>
                    <SelectionLabelCreator
                      entityID={field.SelectionEntity_ID}
                      label={field.SelectionOptionLabel}
                      updateValue={e => editField("SelectionOptionLabel", e)}
                      close={() => setModalOpened(false)}
                    />
                  </ModalBody>
                </Modal>

                <Label>Entity</Label>
                <Select
                  className="mb-2"
                  classNamePrefix="select2-selection"
                  onChange={e => {
                    editField("SelectionEntity_ID", e.ID)
                    editField("SelectionGlobalEntity_ID", null)
                  }}
                  options={entitiesOptions}
                  value={entitiesOptions.filter(
                    x => x.ID === field.SelectionEntity_ID
                  )}
                  defaultValue={entitiesOptions.filter(
                    x => x.ID === field.SelectionEntity_ID
                  )}
                  selectedOption={entitiesOptions.filter(
                    x => x.ID === field.SelectionEntity_ID
                  )}
                  getOptionLabel={option => option.Name}
                  isOptionSelected={option =>
                    option.ID === field.SelectionEntity_ID
                  }
                  theme={getSelectTheme}
                />

                <div className="w-100">
                  <Label>Selection Option Label</Label>
                  <Button
                    onClick={() => setModalOpened(true)}
                    color="none"
                    disabled={!field.SelectionEntity_ID}
                  >
                    <i className="simple-icon-note" />
                  </Button>
                </div>

                <Label>Global Entity</Label>
                <Select
                  className="mb-2"
                  classNamePrefix="select2-selection"
                  onChange={e => {
                    editField("SelectionEntity_ID", null)
                    editField("SelectionGlobalEntity_ID", e.ID)
                  }}
                  options={globalEntitiesOptions}
                  value={globalEntitiesOptions.filter(
                    x => x.ID === field.SelectionGlobalEntity_ID
                  )}
                  defaultValue={globalEntitiesOptions.filter(
                    x => x.ID === field.SelectionGlobalEntity_ID
                  )}
                  selectedOption={globalEntitiesOptions.filter(
                    x => x.ID === field.SelectionGlobalEntity_ID
                  )}
                  getOptionLabel={option => option.Name}
                  isOptionSelected={option =>
                    option.ID === field.SelectionGlobalEntity_ID
                  }
                  theme={getSelectTheme}
                />

                <Input
                  type="text"
                  className="list-item-heading truncate"
                  name={field.SelectionOptionLabel}
                  placeholder="Selection Option Label"
                  value={field.SelectionOptionLabel}
                  onChange={e =>
                    editField("SelectionOptionLabel", e.target.value)
                  }
                />
              </>
            )}
          </div>
        </div>

        {fieldTypeOptionsWithChoices.includes(field.Type) && (
          <FieldDetailsOptions field={field} editField={editField} />
        )}
      </div>
    </>
  )
}

const mapStateToProps = ({ MenuItemForm }) => {
  const { entitiesOptions, globalEntitiesOptions } = MenuItemForm
  return { entitiesOptions, globalEntitiesOptions }
}
export default connect(mapStateToProps, {})(MenuEditorFieldDetails)
