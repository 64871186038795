import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
import { withTranslation } from "react-i18next"

const EditingTable = ({ setOffer, offer, t }) => {
  return (
    <BootstrapTable
      keyField="sno"
      data={offer.items}
      columns={[
        {
          dataField: "desc",
          text: "Opis",
        },
        {
          dataField: "qty",
          text: "Količina",
        },
        {
          dataField: "discount",
          text: "Popust",
        },
        {
          dataField: "rate",
          text: "Cijena",
        },
        {
          dataField: "deleteButton",
          text: "Izbriši",
          editable: false,
          formatter: (cellContent, row) => {
            return (
              <button
                className="btn btn-danger btn-xs"
                onClick={() =>
                  setOffer({
                    ...offer,
                    items: offer.items.filter(x => x.sno !== row.sno),
                  })
                }
              >
                {t("offer.delete")}
              </button>
            )
          },
        },
        {
          dataField: "addButton",
          text: "Dodaj",
          editable: false,
          formatter: (cellContent, row) => {
            return (
              <button
                className="btn btn-primary btn-xs"
                onClick={() =>
                  setOffer({
                    ...offer,
                    items: [
                      ...offer.items.slice(
                        0,
                        offer.items.indexOf(
                          offer.items.find(x => x.sno === row.sno)
                        )
                      ),
                      {
                        sno: `${Math.round(
                          Math.random() * 100000000
                        )}-${Math.round(Math.random() * 1000000)}-${Math.round(
                          Math.random() * 100000000
                        )}`,
                        desc: "",
                        qty: 1,
                        discount: 0,
                        rate: 100.0,
                      },
                      ...offer.items.slice(
                        offer.items.indexOf(
                          offer.items.find(x => x.sno === row.sno)
                        ),
                        offer.items.length
                      ),
                    ],
                  })
                }
              >
                {t("offer.add")}
              </button>
            )
          },
        },
      ]}
      cellEdit={cellEditFactory({
        mode: "click",
        afterSaveCell: (oldValue, newValue, row, column) => {
          setOffer({
            ...offer,
            items: [...offer.items.map(x => (x.sno === row.sno ? row : x))],
          })
        },
      })}
    />
  )
}

export default withTranslation()(EditingTable)
