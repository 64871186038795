import {
  CMS_GET,
  CMS_GET_APPLICATION_EDITING_VERSION,
  CMS_GET_ACCOUNT_EDITOR_VERSION,
  CMS_GET_SUCCESS,
  CMS_GET_ERROR,
  SET_CLIENT_ID,
} from "./actionTypes"

// call to get the CMS
export const getCMS = () => ({
  type: CMS_GET,
})

// call to change the menuItems from default to Application Editing version
export const getCMSApplicationEditingVersion = chosenApplicationID => ({
  type: CMS_GET_APPLICATION_EDITING_VERSION,
  payload: chosenApplicationID,
})

// call to change the menuItems from default to Account Editor Client version
export const getCMSAccountEditorVersion = clientID => ({
  type: CMS_GET_ACCOUNT_EDITOR_VERSION,
  payload: clientID,
})

export const getCMSSuccess = (
  cms,
  metaData,
  menuItems,
  systemSettings,
  newVersion,
  clientID,
  appID,
  style
) => ({
  type: CMS_GET_SUCCESS,
  payload: {
    cms,
    metaData,
    menuItems,
    systemSettings,
    newVersion,
    clientID,
    appID,
    style,
  },
})

export const getCMSError = error => ({
  type: CMS_GET_ERROR,
  payload: error,
})

export const setClientID = clientID => ({
  type: SET_CLIENT_ID,
  payload: clientID,
})
