import { all } from "redux-saga/effects"

//public
import AuthSaga from "./auth/saga"
import CMSSaga from "./cms/saga"
import MenuItemFormSaga from "./menuItemForm/saga"
import LayoutSaga from "./layout/saga"

export default function* rootSaga() {
  yield all([
    //public
    AuthSaga(),
    CMSSaga(),
    MenuItemFormSaga(),
    LayoutSaga(),
  ])
}
