import React, { useEffect, useState } from "react"
import { Button, CustomInput, FormGroup, Label } from "reactstrap"
import { withTranslation } from "react-i18next"

import {
  getEntityCitySelections,
  setEntityCitySelections,
} from "../../../helpers/API"
import CitySelection from "./CitySelection"

const CitySelectionTab = ({ cancel, ID, entityID, t }) => {
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setTimeout(async () => {
      const defaultValues = await getEntityCitySelections(ID)

      setSelected(defaultValues.Cities)
      setLoading(false)
    }, 100)
  }, [ID])

  const onSubmit = async () => {
    await setEntityCitySelections(
      ID,
      entityID,
      selected.map(x => x.ID)
    )
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <div className="p-2">
          <CitySelection
            setSelected={val => setSelected(val)}
            selected={selected}
          />

          <Button
            color="primary"
            className="float-right"
            onClick={() => onSubmit()}
          >
            Submit
          </Button>

          <Button
            color="secondary"
            type="button"
            onClick={() => {
              cancel()
            }}
          >
            {t("form.cancel")}
          </Button>
        </div>
      )}
    </>
  )
}

export default withTranslation()(CitySelectionTab)
