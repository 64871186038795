import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import { connect } from "react-redux"

import { database } from "../../../helpers/Firebase"
import { setClientApplicationBuild } from "../../../helpers/API"
import { getCMSAccountEditorVersion } from "../../../store/cms/actions"
import { Environment } from "../../../constants/defaultValues"

const NewVersion = ({ cms, clientID, getCMSAccountEditorVersionAction }) => {
  const [newVersion, setNewVersion] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (cms) {
      database
        .ref(`${Environment}Applications/DataBase/${cms.MetaData.ID}`)
        .once("value", snapshot => {
          const newestVersion = snapshot.val()
          setNewVersion(newestVersion)
          setLoading(false)
        })
    }
  }, [cms])

  const onClick = async () => {
    await setClientApplicationBuild(
      clientID,
      cms.MetaData.ID,
      newVersion.LatestBuild
    )

    getCMSAccountEditorVersionAction(clientID)
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <div className="d-flex flex-column min-width-zero margin-bottom-10">
            <h4>{`Your app ${newVersion.MetaData.DisplayName} has a new version available`}</h4>
            <span>{`Old Version: ${cms.BuildNumber}`}</span>
            <span>{`New Version: ${newVersion.LatestBuild}`}</span>
            <div
              className="mt-2"
              style={{ wordBreak: "break-all" }}
              dangerouslySetInnerHTML={{
                __html: newVersion.Builds[newVersion.LatestBuild].Description,
              }}
            />
          </div>
          <Button className="mt-4" color="primary" onClick={() => onClick()}>
            Upgrade
          </Button>
        </>
      )}
    </>
  )
}
const mapStateToProps = ({ CMS }) => {
  const { cms, clientID } = CMS
  return { cms, clientID }
}

export default connect(mapStateToProps, {
  getCMSAccountEditorVersionAction: getCMSAccountEditorVersion,
})(NewVersion)
