import React from "react"
import { Input } from "reactstrap"
import Select from "react-select"
import { ruleOptions } from "constants/defaultValues"
import { getSelectTheme } from "helpers/Utils"

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

const formatGroupLabel = data => (
  <div style={groupStyles} key={data.ID}>
    <span>{data.Label ? data.Label : data.Name}</span>
  </div>
)

const RoleRow = ({ keyVal, role, changeName, deleteRole, switchRule }) => {
  return (
    <tr key={`row_${keyVal}`}>
      <td
        style={{
          position: "sticky",
          left: 0,
          zIndex: 1,
          backgroundColor: "white",
        }}
      >
        <Input
          type="text"
          style={{
            minWidth: "250px",
          }}
          disabled={role.Administrator || role.Contributor}
          name={`name_${role.ID}`}
          value={role.Name}
          onChange={e => changeName(e.target.value)}
        />
      </td>
      {Object.keys(role.Rules)
        .filter(
          levelOneMenuItemID =>
            role.Rules[levelOneMenuItemID].MenuItemCMSStatus === "Active"
        )
        .map(levelOneMenuItemID => {
          const levelOneMenuItem = role.Rules[levelOneMenuItemID]
          return [
            ...Object.keys(levelOneMenuItem.Rules)
              .filter(
                levelTwoMenuItemID =>
                  levelOneMenuItem.Rules[levelTwoMenuItemID]
                    .MenuItemCMSStatus === "Active"
              )
              .map(levelTwoMenuItemID => {
                const levelTwoMenuItem =
                  levelOneMenuItem.Rules[levelTwoMenuItemID]
                return [
                  <td key={`read_switch_${keyVal}`}>
                    <div style={{ width: "120px" }}>
                      <Select
                        classNamePrefix="select2-selection"
                        onChange={e =>
                          switchRule(
                            levelOneMenuItemID,
                            levelTwoMenuItemID,
                            "Read",
                            e.ID
                          )
                        }
                        options={ruleOptions}
                        value={ruleOptions.find(
                          x => x.ID === levelTwoMenuItem.Read
                        )}
                        isOptionSelected={option =>
                          option.ID === levelTwoMenuItem.Read
                        }
                        getOptionLabel={option => option.Name}
                        formatGroupLabel={formatGroupLabel}
                        isDisabled={role.Administrator || role.Contributor}
                        theme={getSelectTheme}
                      />
                    </div>
                  </td>,
                  <td key={`update_switch_${keyVal}`}>
                    <div style={{ width: "120px" }}>
                      <Select
                        classNamePrefix="select2-selection"
                        onChange={e =>
                          switchRule(
                            levelOneMenuItemID,
                            levelTwoMenuItemID,
                            "Update",
                            e.ID
                          )
                        }
                        options={ruleOptions}
                        value={ruleOptions.find(
                          x => x.ID === levelTwoMenuItem.Update
                        )}
                        isOptionSelected={option =>
                          option.ID === levelTwoMenuItem.Update
                        }
                        getOptionLabel={option => option.Name}
                        formatGroupLabel={formatGroupLabel}
                        isDisabled={
                          role.Administrator ||
                          role.Contributor ||
                          levelTwoMenuItem.Read === "False"
                        }
                        theme={getSelectTheme}
                      />
                    </div>
                  </td>,
                  <td key={`create_switch_${keyVal}`}>
                    <div style={{ width: "120px" }}>
                      <Select
                        classNamePrefix="select2-selection"
                        onChange={e =>
                          switchRule(
                            levelOneMenuItemID,
                            levelTwoMenuItemID,
                            "Create",
                            e.ID
                          )
                        }
                        options={ruleOptions}
                        value={ruleOptions.find(
                          x => x.ID === levelTwoMenuItem.Create
                        )}
                        isOptionSelected={option =>
                          option.ID === levelTwoMenuItem.Create
                        }
                        getOptionLabel={option => option.Name}
                        formatGroupLabel={formatGroupLabel}
                        isDisabled={
                          role.Administrator ||
                          role.Contributor ||
                          levelTwoMenuItem.Read === "False"
                        }
                        theme={getSelectTheme}
                      />
                    </div>
                  </td>,
                  <td key={`delete_switch_${keyVal}`}>
                    <div style={{ width: "120px" }}>
                      <Select
                        classNamePrefix="select2-selection"
                        onChange={e =>
                          switchRule(
                            levelOneMenuItemID,
                            levelTwoMenuItemID,
                            "Delete",
                            e.ID
                          )
                        }
                        options={ruleOptions}
                        value={ruleOptions.find(
                          x => x.ID === levelTwoMenuItem.Delete
                        )}
                        isOptionSelected={option =>
                          option.ID === levelTwoMenuItem.Delete
                        }
                        getOptionLabel={option => option.Name}
                        formatGroupLabel={formatGroupLabel}
                        isDisabled={
                          role.Administrator ||
                          role.Contributor ||
                          levelTwoMenuItem.Read === "False"
                        }
                        theme={getSelectTheme}
                      />
                    </div>
                  </td>,
                ]
              }),
          ]
        })}
      <td key={`delete_${keyVal}`}>
        <button
          className="text-only-button"
          type="button"
          onClick={() => deleteRole()}
        >
          Delete
        </button>
      </td>
    </tr>
  )
}

export default RoleRow
