import React, { useState, useEffect } from "react"
import { AvForm } from "availity-reactstrap-validation"
import { Button } from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"

import {
  getFormOptions,
  makeAPICall,
  makeAPICallWithConfirmation,
  makeAPICallWithErrorHandling,
} from "../../../../helpers/API"
import NotificationAlert from "../../../Common/Notifications/NotificationAlert"
import FieldPicker from "../../Fields/FieldPicker"
import ButtonWithWarning from "components/Common/UI/ButtonWithWarning"

const Input = ({
  fieldGroup,
  ID,
  formID,
  propValues,
  excludeKeys,
  onAdd,
  onSubmit,
  onCancel,
  onDelete,
  rules,
  privateAPI,
  clientID,
  appID,
  noDelete,
  history,
  t,
  propClient_ID,
  propApplication_ID,
}) => {
  const [defaultValues, setDefaultValues] = useState({})
  const [stateValues, setStateValues] = useState({})
  const [loading, setLoading] = useState(true)
  const [processedFieldGroup, setProccessedFieldGroup] = useState(fieldGroup)
  const [working, setWorking] = useState(false)

  const deleteButton =
    !noDelete && fieldGroup.Buttons.find(x => x.Type === "Delete")
  const submitButton = fieldGroup.Buttons.find(x => x.Type === "Submit")

  const createAllowed =
    rules.create === "True" || rules.create === "IfContributor"
  const deleteAllowed =
    rules.delete === "True" || rules.delete === "IfContributor"
  const updateAllowed =
    rules.update === "True" || rules.update === "IfContributor"
  const prefix = privateAPI ? "{{APIURL}}" : "{{PUBLICAPIURL}}"

  // gets the default values of the entity that's being edited
  const getNewByID = async (qurl, defaultItem) => {
    return await makeAPICallWithErrorHandling(qurl, "GET", defaultItem, history)
  }

  useEffect(() => {
    if (working) return

    setTimeout(async () => {
      setLoading(true)
      const url = fieldGroup.EntityRequestURL

      if (ID) {
        // load default values if ID is present
        const newDefaultValues = await getNewByID(`${prefix}${url}/{{ID}}`, {
          ID,
        })

        Object.keys(newDefaultValues).forEach(x => {
          if (newDefaultValues[x] === null) newDefaultValues[x] = undefined
        })
        setDefaultValues({ ...newDefaultValues })
      } else {
        const cmsDefaultValues = {} // values that are set as default in the CMS

        fieldGroup.Fields.forEach(field => {
          if (field.DefaultValue)
            cmsDefaultValues[field.Name] = field.DefaultValue
        })

        setDefaultValues({ ...propValues, ...cmsDefaultValues })
      }

      if (!privateAPI) {
        // get options for multiple choice fields
        const formOptions = await getFormOptions(
          formID,
          propApplication_ID || appID,
          propClient_ID || clientID
        )

        const newFieldGroup = { ...fieldGroup }

        if (formOptions) {
          fieldGroup.Fields.forEach((field, fieldIndex) => {
            if (formOptions[field.ID]) {
              newFieldGroup.Fields[fieldIndex].Options =
                formOptions[field.ID].SelectionOptions
            } else if (field.SelectionOptions) {
              newFieldGroup.Fields[fieldIndex].Options = field.SelectionOptions
            }
          })
        }

        setProccessedFieldGroup(newFieldGroup)
        setLoading(false)
      } else {
        setProccessedFieldGroup(fieldGroup)
        setLoading(false)
      }
    }, 100)
  }, [ID, formID])

  // updates values in state for fields that aren't included in availity
  const updateValue = (name, value) => {
    const newStateValues = { ...stateValues }
    newStateValues[name] = value
    setStateValues(newStateValues)
  }

  // triggers on form submit
  const onFormSubmit = async (event, errors, values) => {
    if (errors.length === 0) {
      setWorking(true)
      // merge values gotten from the AvForm with the values in state for custom inputs
      const mergedValues = { ...values }

      Object.keys(mergedValues).forEach(key => {
        // availity sets default value as empty string for these field types so we have to
        // check them before sending them to API
        if (mergedValues[key] === "") {
          const fieldType = processedFieldGroup.Fields.find(
            f => f.Name === key
          ).Type
          if (
            fieldType === "Radio Button" ||
            fieldType === "Radio Button Entity" ||
            fieldType === "OIB" ||
            fieldType === "Number"
          )
            mergedValues[key] = undefined
        }
      })

      // add values from state that weeren't added with availity
      Object.keys(stateValues).forEach(key => {
        mergedValues[key] = stateValues[key]
      })

      // if value wasn't edited, add default
      Object.keys(defaultValues).forEach(key => {
        if (
          !mergedValues[key] &&
          mergedValues[key] !== false &&
          mergedValues[key] !== ""
        ) {
          mergedValues[key] = defaultValues[key]
        }
      })

      // for passing values that need to be in the call but are gotten in parent components
      // for example, for addin AppID and ParentMenuItem when creating a new app menu item
      if (propValues)
        Object.keys(propValues).forEach(key => {
          if (
            !mergedValues[key] &&
            mergedValues[key] !== false &&
            mergedValues[key] !== ""
          ) {
            mergedValues[key] = propValues[key]
          }
        })

      // switch doesn't set default values to false by default
      processedFieldGroup.Fields.filter(f => f.Type === "Switch").forEach(f => {
        if (!mergedValues[f.Name]) mergedValues[f.Name] = false
      })

      // set default value for times
      processedFieldGroup.Fields.filter(
        f => f.Type === "Date" || f.Type === "DateTime" || f.Type === "Time"
      ).forEach(f => {
        if (!mergedValues[f.Name]) mergedValues[f.Name] = new Date()
      })

      if (!mergedValues.ID && ID) {
        mergedValues.ID = ID
      }

      if (excludeKeys) {
        excludeKeys.forEach(excludeKey => {
          if (mergedValues[excludeKey]) delete mergedValues[excludeKey]
        })
      }

      let apiMethod = submitButton.RequestMethod

      if (
        apiMethod === "PUT" &&
        !submitButton.ForceRequestMethod &&
        !mergedValues.ID
      )
        apiMethod = "POST"

      // make the API call with the given set of values
      const ret = await makeAPICall(
        `${prefix}${submitButton.RequestURL}`,
        apiMethod,
        mergedValues
      )

      if (ret.Success) {
        setDefaultValues(ret.Data)

        if (!ID && onAdd) {
          onAdd(ret.Data.ID)
        }

        if (onSubmit) {
          onSubmit(ret.Data.ID)
        }

        NotificationAlert({
          type: "success",
          title: "",
          message: submitButton.Confirmation,
          expiration: 3000,
        })
      } else {
        NotificationAlert({
          type: "error",
          title: "",
          message: ret.ErrorMessage.Message,
          expiration: 3000,
        })
      }
      setWorking(false)
    } else {
      let errorMessage = "Missing: "
      errors.forEach(error => {
        errorMessage += error
        errorMessage += ", "
      })
      NotificationAlert({
        type: "warning",
        title: "",
        message: errorMessage,
        expiration: 3000,
      })
    }
  }

  // deletes an entry
  const deleteEntry = async () => {
    setWorking(true)
    const apiCall = await makeAPICallWithConfirmation(
      `${prefix}${deleteButton.RequestURL}`,
      deleteButton.RequestMethod,
      { ID }
    )

    if (!apiCall.ErrorMessage && onDelete) {
      onDelete()
    }
    setWorking(false)
  }

  console.log(processedFieldGroup)

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          {ID && deleteButton && (
            <div className="col-12 text-right margin-bottom-10">
              <ButtonWithWarning
                color="danger"
                onClick={() => deleteEntry()}
                disabled={working || !deleteAllowed}
              >
                {deleteButton.Text}
              </ButtonWithWarning>
            </div>
          )}

          <AvForm
            className="av-tooltip tooltip-label-right "
            onSubmit={(event, errors, values) =>
              onFormSubmit(event, errors, values)
            }
            model={defaultValues}
          >
            {processedFieldGroup.Fields.map(field => (
              <div key={`field_${field.ID}`}>
                <FieldPicker
                  item={field}
                  updateValue={(name, value) => updateValue(name, value)}
                  disabled={(ID && !updateAllowed) || (!ID && !createAllowed)}
                  value={
                    stateValues[field.Name]
                      ? stateValues[field.Name]
                      : defaultValues[field.Name]
                  }
                />
              </div>
            ))}

            <>
              {onCancel && (
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => {
                    onCancel()
                  }}
                >
                  {t("form.cancel")}
                </Button>
              )}

              {submitButton && (
                <Button
                  color="primary"
                  className="float-right"
                  disabled={
                    working || (ID && !updateAllowed) || (!ID && !createAllowed)
                  }
                >
                  {t(submitButton.Text)}
                </Button>
              )}
            </>
          </AvForm>
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { clientID, appID } = CMS
  return { clientID, appID }
}
export default withTranslation()(
  connect(mapStateToProps, {})(withRouter(Input))
)
