import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import {
  getMenuItemsByID,
} from "../../../../helpers/API"
import { getMenuItemForm } from "../../../../store/menuItemForm/actions"
import MenuEditorSettings from "../../../../components/Admin/CMSCreator/MenuItemEditor/Settings"
import MenuEditorForm from "../../../../components/Admin/CMSCreator/MenuItemEditor/Form/Form"
import TabNavigation from "../../../../components/Common/UI/TabNavigation"
import menuItemEditForm from "../../../../constants/administratorCMS/edit.menu.items.form.json"
import menuLinkEditForm from "../../../../constants/administratorCMS/edit.menu.link.form.json"

// editing menu settings
const MenuItemEditor = ({
  location,
  getMenuItemFormAction,
  menuLoading,
  formLoading,
  history,
  t,
}) => {
  const [activeTab, setActiveTab] = useState("settings")

  const [defaultValues, setDefaultValues] = useState({})
  const [loading, setLoading] = useState(true)
  const [appID, setAppID] = useState()
  const [parent, setParent] = useState()
  const [form, setForm] = useState()

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true)

      const params = new URLSearchParams(location.search)

      const applicationID = params.get("appID")
      setAppID(applicationID)
      const _parent = params.get("parent")
      setParent(_parent)
      const ID = params.get("ID")

      let menuItem = {}
      if (ID) {
        menuItem = await getMenuItemsByID(ID)
      } else {
        menuItem = {
          IsStudent: false,
          IsNomad: false,
          IsCitizen: false,
          IsTourist: false,
          Visible: true,
          EnableCitySelection: false,
          HasOIB: false,
          FirebasePath: "",
          DeepURL: "",
          LinkType: null,
        }
      }

      if (!menuItem.ParentMenuItem_ID) menuItem.ParentMenuItem_ID = _parent

      if (!menuItem.Application_ID) menuItem.Application_ID = applicationID

      if (menuItem.Form_ID) {
        getMenuItemFormAction(menuItem.Form_ID, applicationID)
      } else {
        menuItem.Form_ID = null
      }

      setDefaultValues(menuItem)

      if (menuItem.Form_ID) {
        // menu-item-level-2
        changeForm(false)
        setActiveTab("form")
      } else if (!menuItem.ID) {
        // creating
        changeForm(false)
        setActiveTab("settings")
      } else if (typeof menuItem.LinkType === "string") {
        // menu-link
        changeForm(true)
        setActiveTab("link")
      } else {
        // menu-item-level-1
        changeForm(false)
        setActiveTab("settings")
      }
      setLoading(false)
    }, 1000)
  }, [location.search])

  let navItems = []

  if (defaultValues.Form_ID) {
    // menu-item-level-2
    navItems = [
      {
        name: "form",
        label: "form.form",
      },
      { name: "settings", label: "form.settings" },
    ]
  } else if (!defaultValues.ID) {
    // creating
    navItems = [
      { name: "settings", label: "form.settings" },
      { name: "link", label: "form.link" },
    ]
  } else if (typeof defaultValues.LinkType === "string") {
    // menu-link
    navItems = [{ name: "link", label: "form.link" }]
  } else {
    // menu-item-level-1
    navItems = [{ name: "settings", label: "form.settings" }]
  }

  const changeForm = (toLink) => {
    const _form = toLink ? menuLinkEditForm.Form : menuItemEditForm.Form

    setForm(_form)
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row>
            <Col>
              {loading || menuLoading || formLoading ? (
                <div className="loading" />
              ) : (
                <>
                  <h1>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {defaultValues && defaultValues.ID
                      ? t(
                          defaultValues.DisplayName
                            ? defaultValues.DisplayName
                            : " "
                        )
                      : t("form.new-menu-item")}
                  </h1>

                  <TabNavigation
                    activeTab={activeTab}
                    setActive={val => {
                      if (val === "link" && !defaultValues.ID) {
                        const newMenuItem = {
                          ...defaultValues,
                          LinkType: "",
                        }
                        changeForm(true)
                        setDefaultValues(newMenuItem)
                        setForm(menuLinkEditForm.Form)
                      } else if (val === "settings" && !defaultValues.ID) {
                        const newMenuItem = {
                          ...defaultValues,
                          LinkType: undefined,
                        }
                        changeForm(false)
                        setDefaultValues(newMenuItem)
                        setForm(menuItemEditForm.Form)
                      }
                      setActiveTab(val)
                    }}
                    navList={navItems}
                  />

                  {/* settings for menu item */}
                  {activeTab === "settings" && (
                    <MenuEditorSettings
                      form={form}
                      appID={appID}
                      parent={parent}
                      ID={defaultValues.ID}
                      location={location}
                      history={history}
                    />
                  )}

                  {/* settings for menu link */}
                  {activeTab === "link" && (
                    <MenuEditorSettings
                      form={form}
                      appID={appID}
                      parent={parent}
                      ID={defaultValues.ID}
                      isLink
                      location={location}
                      history={history}
                    />
                  )}

                  {/* form for menu item */}
                  {activeTab === "form" && <MenuEditorForm />}
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ CMS, MenuItemForm }) => {
  const { loading: menuLoading } = CMS
  const { loading: formLoading } = MenuItemForm

  return {
    menuLoading,
    formLoading,
  }
}

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, { getMenuItemFormAction: getMenuItemForm })(
      MenuItemEditor
    )
  )
)
