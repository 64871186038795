import React from "react"
import { withRouter } from "react-router"

import Controler from "../../../../components/Admin/AccountManager/Editor/Controler"

const Editor = ({ history, location }) => {
  const params = new URLSearchParams(location.search)

  const ID = params.get("ID")

  return (
    <Controler
      history={history}
      clientURL="businesses"
      ID={ID}
      type="business"
    />
  )
}

export default withRouter(Editor)
