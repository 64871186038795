import React, { useState, useEffect } from "react"
import { Row } from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

import {
  getClientByClientID,
  getClientOffers,
} from "../../../../../helpers/API"
import NewOffer from "./NewOffer"
import OldOffer from "./OldOffer"

const Controler = ({ clientID, t }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [offers, setOffers] = useState([])
  const [chosenOffer, setChosenOffer] = useState()

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true)
      const dataAPI = await getClientByClientID(clientID)
      const offersAPI = await getClientOffers(clientID)
      const sortedOffers = offersAPI.sort(
        (a, b) => new Date(a.Time).getTime() - new Date(b.Time).getTime()
      )
      setOffers(sortedOffers)
      setData(dataAPI)
      setLoading(false)
    }, 100)
  }, [clientID])

  const afterOfferCreate = async ID => {
    setLoading(true)
    const offersAPI = await getClientOffers(clientID)
    const sortedOffers = offersAPI.sort(
      (a, b) => new Date(a.Time).getTime() - new Date(b.Time).getTime()
    )
    setOffers(sortedOffers)
    setChosenOffer(offersAPI.find(x => x.ID == ID))
    setLoading(false)
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <Row>
            <div className="col-2 offer-menu-container">
              <div className="offer-menu">
                {offers.map(offer => (
                  <button
                    key={`${offer.ID}`}
                    className={`offer-menu-item ${
                      chosenOffer == offer && "active"
                    }`}
                    onClick={() => setChosenOffer(offer)}
                  >
                    {t("offer.offer")} {offer.Number}
                  </button>
                ))}

                <button
                  className={`offer-menu-item ${!chosenOffer && "active"}`}
                  onClick={() => setChosenOffer(undefined)}
                >
                  {t("offer.new-offer")}
                </button>
              </div>
            </div>
            <div className="col-10">
              {chosenOffer ? (
                <OldOffer offer={chosenOffer} />
              ) : (
                <NewOffer
                  clientData={data}
                  clientID={clientID}
                  afterOfferCreate={afterOfferCreate}
                />
              )}
            </div>
          </Row>
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser } = Auth

  return {
    currentUser,
  }
}
export default withTranslation()(connect(mapStateToProps, {})(Controler))
