import React from "react"
import { Label } from "reactstrap"
import { AvGroup, AvField, AvFeedback } from "availity-reactstrap-validation"

const LinkInput = ({ item, disabled, required }) => {
  return (
    <AvGroup className="error-t-negative error-l-150">
      <Label>{item.Label}</Label>
      <AvField
        name={item.Name}
        type="text"
        validate={{ required: { value: required } }}
        disabled={disabled}
      />
      <AvFeedback>{item.Prompt}</AvFeedback>
    </AvGroup>
  )
}

export default LinkInput
