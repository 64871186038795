import React from "react"
import { AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import { Label } from "reactstrap"

const RadioButton = ({ item, required, disabled }) => {
  return (
    <>
      <Label>{item.Label}</Label>
      <AvRadioGroup
        className="error-l-150 radio-buttons"
        name={item.Name}
        inline
        validate={{ required: { value: required } }}
        disabled={disabled}
      >
        {item.Options.map(option => (
          <AvRadio
            key={`${item.ID}_option_${option.ID}`}
            customInput
            label={option.Label}
            value={option.ID}
          />
        ))}
      </AvRadioGroup>
    </>
  )
}

export default RadioButton
