import { NotificationManager } from "./"

const NotificationAlert = ({ type, title, message, expiration, className }) => {
  const cName = className || "filled"
  switch (type) {
    case "primary":
      NotificationManager.primary(message, title, expiration, null, null, cName)
      break
    case "secondary":
      NotificationManager.secondary(
        message,
        title,
        expiration,
        null,
        null,
        cName
      )
      break
    case "info":
      NotificationManager.info(message, title, expiration, null, null, cName)
      break
    case "success":
      NotificationManager.success(message, title, expiration, null, null, cName)
      break
    case "warning":
      NotificationManager.warning(message, title, expiration, null, null, cName)
      break
    case "error":
      NotificationManager.error(message, title, expiration, null, null, cName)
      break
    default:
      NotificationManager.info("Info message")
      break
  }
}

export default NotificationAlert
