import defaultLogo from "../assets/images/styles/default/logo.png"
import defaultIcon from "../assets/images/styles/default/logo-icon.png"
import defaultNavBottom from "../assets/images/styles/default/nav-bottom.jpeg"

import cityLogo from "../assets/images/styles/city/logo.png"
import cityIcon from "../assets/images/styles/city/logo-icon.png"
import cityNavBottom from "../assets/images/styles/city/nav-bottom.png"

export default {
  default: {
    colors: {
      "--primary-color": "rgba(231, 60, 115, 1)",
      "--primary-color-25": "rgba(231, 60, 115, 0.25)",
      "--secondary-color": "rgba(238, 119, 82, 1)",
      "--secondary-color-25": "rgba(238, 119, 82, 0.25)",
    },
    images: {
      logo: defaultLogo,
      icon: defaultIcon,
      navBottom: defaultNavBottom,
    },
  },
  city: {
    colors: {
      "--primary-color": "rgba(231, 60, 115, 1)",
      "--primary-color-25": "rgba(231, 60, 115, 0.25)",
      "--secondary-color": "rgba(238, 119, 82, 1)",
      "--secondary-color-25": "rgba(238, 119, 82, 0.25)",
    },
    images: {
      logo: cityLogo,
      icon: cityIcon,
      navBottom: cityNavBottom,
    },
  },
}
