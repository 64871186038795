import React, { useEffect, useState } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"

import Controler from "../../../../components/Form/Controler"

const Module = ({
  location,
  history,
  match,
  reducersLoading,
  cms,
  clientID,
  currentUser,
}) => {
  const [module, setModule] = useState()
  const [opened, setOpened] = useState()
  const [rules, setRules] = useState()
  const [contributorEntities, setContributorEntities] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (reducersLoading || !cms) return

    const params = new URLSearchParams(location.search)

    const ID = match.params.ID
    setOpened(params.get("opened"))

    const levelOneMenuItems = JSON.parse(cms.Structure).MenuItems

    if (levelOneMenuItems[ID]) {
      const chosenModule = levelOneMenuItems[ID]
      if (currentUser.role < 4) {
        setRules({ create: "True", update: "True", delete: "True" })
      } else {
        const role =
          currentUser.Clients[clientID].Applications[cms.MetaData.ID].Roles[
            Object.keys(
              currentUser.Clients[clientID].Applications[cms.MetaData.ID].Roles
            )[0]
          ]

        if (role.Rules[ID].Read === "True") {
          setRules({
            create: role.Rules[ID].Create,
            update: role.Rules[ID].Update,
            delete: role.Rules[ID].Delete,
          })
        } else if (role.Rules[ID].Read === "IfContributor") {
          const memberships =
            currentUser.Clients[clientID].Applications[cms.MetaData.ID]
              .Memberships

          setContributorEntities([
            ...Object.keys(memberships).map(k => memberships[k]),
          ])
          setRules({
            create: role.Rules[ID].Create,
            update: role.Rules[ID].Update,
            delete: role.Rules[ID].Delete,
          })
        } else {
          history.push("/401")
          return
        }
      }
      setModule(chosenModule)
      setLoading(false)
    } else {
      const chosenModuleParentID = Object.keys(levelOneMenuItems).find(
        x => levelOneMenuItems[x].MenuItems[ID]
      )
      const chosenModuleParent = levelOneMenuItems[chosenModuleParentID]
      const chosenModule = chosenModuleParent.MenuItems[ID]
      if (currentUser.role < 4) {
        setRules({ create: "True", update: "True", delete: "True" })
      } else {
        const role =
          currentUser.Clients[clientID].Applications[cms.MetaData.ID].Roles[
            Object.keys(
              currentUser.Clients[clientID].Applications[cms.MetaData.ID].Roles
            )[0]
          ]
        const moduleRules = role.Rules[chosenModuleParentID].Rules[ID]
        if (moduleRules.Read === "True") {
          setRules({
            read: moduleRules.Read,
            create: moduleRules.Create,
            update: moduleRules.Update,
            delete: moduleRules.Delete,
          })
        } else if (moduleRules.Read === "IfContributor") {
          const memberships =
            currentUser.Clients[clientID].Applications[cms.MetaData.ID]
              .Memberships

          setContributorEntities([
            ...Object.keys(memberships).map(k => memberships[k]),
          ])
          setRules({
            read: moduleRules.Read,
            create: moduleRules.Create,
            update: moduleRules.Update,
            delete: moduleRules.Delete,
          })
        } else {
          history.push("/401")
          return
        }
      }
      setModule(chosenModule)
      setLoading(false)
    }
  }, [location.pathname, location.search, cms, clientID, reducersLoading])

  return (
    <div className="page-content">
      {loading || reducersLoading ? (
        <div className="loading" />
      ) : (
        <Controler
          module={module}
          opened={opened}
          title={module.DisplayName}
          uploadTab={currentUser.role < 4}
          rules={rules}
          contributorEntities={
            rules.read === "IfContributor" && contributorEntities
          }
        />
      )}
    </div>
  )
}

const mapStateToProps = ({ CMS, Auth }) => {
  const { loading: cmsLoading, cms, clientID } = CMS
  const { loading: authLoading, currentUser } = Auth

  return {
    reducersLoading: cmsLoading && authLoading,
    cms,
    clientID,
    currentUser,
  }
}
export default connect(mapStateToProps, {})(withRouter(Module))
