import React from "react"

import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"
import Offer from "./Offer"
import { styles } from "./Styling"
import { Button } from "reactstrap"

const OldOffer = ({ offer }) => {
  return (
    <>
      {offer ? (
        <div className="p-4 mt-2">
          <div className="d-flex justify-content-center mb-2">
            <PDFDownloadLink
              document={
                <Offer
                  offer={JSON.parse(offer.DataJSON)}
                  styles={styles}
                  title={JSON.parse(offer.DataJSON).offer.offer_no}
                />
              }
              fileName={`${JSON.parse(offer.DataJSON).offer.offer_no}.pdf`}
            >
              {({ blob, url, loading, error }) => (
                <Button color="primary">Download</Button>
              )}
            </PDFDownloadLink>
          </div>
          <PDFViewer
            style={{ height: "100vh", width: "100%" }}
            showToolbar={false}
          >
            <Offer
              offer={JSON.parse(offer.DataJSON)}
              styles={styles}
              title={JSON.parse(offer.DataJSON).offer.offer_no}
            />
          </PDFViewer>
        </div>
      ) : (
        <div className="loading" />
      )}
    </>
  )
}

export default OldOffer
