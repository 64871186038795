import React from "react"

import City from "./City"
import Business from "./Business"

const Selector = ({ ID, type, history }) => {
  if (type === "city")
    return <City ID={ID} history={history} deleteEnabled={ID} />

  return <Business ID={ID} history={history} deleteEnabled={ID} />
}

export default Selector
