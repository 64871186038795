import React, { useEffect, useState } from "react"
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import { Button, Label } from "reactstrap"
import Select from "react-select"

import {
  createOrUpdateClient,
  deleteClientByClientID,
  getAllClientTypes,
  getAllCounties,
  getAllCountries,
  getClientByClientID,
} from "../../../../../helpers/API"
import {
  validateOIBAndMB,
  getCraftDataFromVAT,
  getSelectTheme,
} from "../../../../../helpers/Utils"
import NotificationAlert from "../../../../Common/Notifications/NotificationAlert"
import {
  adminRoot,
  businessStatusOptions,
} from "../../../../../constants/defaultValues"
import ButtonWithWarning from "../../../../Common/UI/ButtonWithWarning"

const BusinessForm = ({ ID, deleteEnabled, history }) => {
  const [loading, setLoading] = useState(true)

  const [defaultValues, setDefaultValues] = useState()

  const [clientTypes, setClientTypes] = useState([])
  const [countries, setCountries] = useState([])
  const [counties, setCounties] = useState([])
  const [country, setCountry] = useState()
  const [county, setCounty] = useState()

  const [type, setType] = useState()
  const [VAT, setVAT] = useState("")
  const [status, setStatus] = useState()

  const [types] = useState(["Company", "Craft", "Association"])

  useEffect(() => {
    setTimeout(async () => {
      const clientTypesAPI = await getAllClientTypes()
      const countriesAPI = await getAllCountries()
      const countiesAPI = await getAllCounties()

      if (ID) {
        const defaultValuesAPI = await getClientByClientID(ID)
        setDefaultValues(defaultValuesAPI)

        setCountry(defaultValuesAPI.Country_ID || countriesAPI[0].ID)
        setCounty(defaultValuesAPI.County_ID)
        setStatus(defaultValuesAPI.Status)
      } else {
        setDefaultValues({
          ClientType_ID: clientTypesAPI.find(x => types[0] === x.Name).ID,
        })
      }

      setClientTypes(clientTypesAPI)
      setCountries(countriesAPI)
      setCounties(countiesAPI)

      setLoading(false)
    }, 100)
  }, [ID])

  const setCraftData = data => {
    const defaultValuesMap = {}
    defaultValuesMap.Name = data.naziv_obrta

    if (counties.find(x => x.Name === data.zupanija))
      defaultValuesMap.County = counties.find(x => x.Name === data.zupanija).ID

    defaultValuesMap.MunicipalityOrCity = data.opcina_grad
    defaultValuesMap.SettlementOrCityDistrict = data.naselje
    defaultValuesMap.Address = data.ulica
    defaultValuesMap.ContactPerson = data.vlasnik.prezime_i_ime
    setDefaultValues({
      ...defaultValues,
      ...defaultValuesMap,
    })
  }

  const setCompanyData = data => {
    const defaultValuesMap = {}
    defaultValuesMap.Name = data.tvrtke[0].ime
    if (countries.find(x => x.Name === data.sjedista[0].drzava.naziv))
      defaultValuesMap.Country = countries.find(
        x => x.Name === data.sjedista[0].drzava.naziv
      ).ID

    if (counties.find(x => x.Name === data.sjedista[0].naziv_zupanije))
      defaultValuesMap.County = counties.find(
        x => x.Name === data.sjedista[0].naziv_zupanije
      ).ID

    defaultValuesMap.MunicipalityOrCity = data.sjedista[0].naziv_opcine
    defaultValuesMap.SettlementOrCityDistrict = data.sjedista[0].naziv_naselja
    defaultValuesMap.Address = data.sjedista[0].ulica
    defaultValuesMap.HouseNumber = data.sjedista[0].kucni_broj
    setDefaultValues({
      ...defaultValues,
      ...defaultValuesMap,
    })
  }

  const onSubmit = async (event, errors, values) => {
    if (errors.length === 0 && county && country && status) {
      const client = await createOrUpdateClient({
        ...values,
        Country_ID: country,
        County_ID: county,
        ID: ID,
        Status: status,
      })

      if (!client.ErrorMessage) {
        history.push(
          `${adminRoot}manager/businesses/edit?ID=${
            client.MetaData ? client.MetaData.ID : client.ID
          }`
        )
      }
    } else {
      let errorMessage = "Missing: "
      errors.forEach(error => {
        errorMessage += error
        errorMessage += ", "
      })
      if (!country) errorMessage += "Country, "
      if (!county) errorMessage += "County, "
      if (!status) errorMessage += "Status, "

      NotificationAlert({
        type: "warning",
        title: "",
        message: errorMessage,
        expiration: 3000,
      })
    }
  }

  const deleteClient = async () => {
    const api = await deleteClientByClientID(ID)

    if (api.ErrorMessage) {
      NotificationAlert({
        type: "error",
        title: "Error",
        message: api.ErrorMessage.Message,
        expiration: 3000,
      })
    } else {
      history.push(`${adminRoot}manager/businesses`)
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <AvForm
            className="av-tooltip tooltip-label-right p-1 pt-3"
            model={defaultValues}
            onSubmit={(event, errors, values) =>
              onSubmit(event, errors, values)
            }
          >
            <AvRadioGroup
              className="error-l-150 radio-buttons"
              name="ClientType_ID"
              required
              inline
            >
              <Label className="d-block">Type</Label>
              {clientTypes.map(clientType =>
                types.includes(clientType.Name) ? (
                  <AvRadio
                    customInput
                    label={clientType.Name}
                    value={clientType.ID}
                    onClick={() => setType(clientType.Name)}
                    key={clientType.ID}
                  />
                ) : (
                  <AvRadio
                    customInput
                    label={clientType.Name}
                    value={clientType.ID}
                    disabled
                    key={clientType.ID}
                  />
                )
              )}
            </AvRadioGroup>

            <AvGroup>
              <Label>OIB</Label>
              <AvInput
                name="PersonalIdentificationNumber"
                type="number"
                onChange={e => setVAT(e.target.value)}
                required
                value={defaultValues.PersonalIdentificationNumber}
                validate={{ async: validateOIBAndMB }}
              />
              <AvFeedback>OIB is required!</AvFeedback>
            </AvGroup>

            <AvGroup className="col-12">
              <Button color="none" />
              <Button
                color="none"
                className="float-right"
                onClick={async () => {
                  if (type === "Company") {
                    const response = await getCompanyDataFromVAT(VAT)
                    if (!response) {
                      NotificationAlert({
                        type: "error",
                        title: "",
                        message: "OIB not found",
                        expiration: 3000,
                      })
                    } else {
                      NotificationAlert({
                        type: "success",
                        title: "Confirmed",
                        message: response.tvrtke[0].ime,
                        expiration: 3000,
                      })
                      setCompanyData(response)
                    }
                  } else if (type === "Craft") {
                    const response = await getCraftDataFromVAT(VAT)
                    if (!response.data) {
                      NotificationAlert({
                        type: "error",
                        title: "",
                        message: "OIB not found",
                        expiration: 3000,
                      })
                    } else {
                      NotificationAlert({
                        type: "success",
                        title: "Confirmed",
                        message: response.data.naziv_obrta,
                        expiration: 3000,
                      })
                      setCraftData(response.data)
                    }
                  }
                }}
                disabled={!validateOIBAndMB(VAT)}
              >
                Confirm
              </Button>
            </AvGroup>

            <AvGroup>
              <Label>Name</Label>
              <AvInput name="Name" required value={defaultValues.Name} />
              <AvFeedback>Name is required!</AvFeedback>
            </AvGroup>

            <Label>Country</Label>
            <Select
              theme={getSelectTheme}
              className="mb-3"
              classNamePrefix="select2-selection"
              onChange={e => setCountry(e.ID)}
              options={countries}
              defaultValue={countries.filter(x => x.ID === country)}
              isOptionSelected={option => option.ID === country}
              getOptionLabel={option => option.Name}
            />

            <Label>County</Label>
            <Select
              theme={getSelectTheme}
              className="mb-3"
              classNamePrefix="select2-selection"
              onChange={e => setCounty(e.ID)}
              options={counties}
              defaultValue={counties.filter(x => x.ID === county)}
              isOptionSelected={option => option.ID === county}
              getOptionLabel={option => option.Name}
            />

            <AvGroup>
              <Label>ZIP code</Label>
              <AvInput
                name="ZIPCode"
                type="number"
                value={defaultValues.ZIPCode}
              />
              <AvFeedback>ZIP code is required!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <Label>City/Municipality</Label>
              <AvInput
                name="MunicipalityOrCity"
                value={defaultValues.MunicipalityOrCity}
              />
              <AvFeedback>Municipality is required!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <Label>Settlement</Label>
              <AvInput
                name="SettlementOrCityDistrict"
                value={defaultValues.SettlementOrCityDistrict}
              />
              <AvFeedback>Settlement is required!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <Label>Address</Label>
              <AvInput name="Address" value={defaultValues.Address} />
              <AvFeedback>Address is required!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <Label>House number</Label>
              <AvInput
                name="HouseNumber"
                type="number"
                value={defaultValues.HouseNumber}
              />
              <AvFeedback>House number is required!</AvFeedback>
            </AvGroup>

            <AvGroup className="mb-3">
              <Label>House number addition</Label>
              <AvInput
                name="HouseNumberAddition"
                value={defaultValues.HouseNumberAddition}
              />
              <AvFeedback>House number addition is required!</AvFeedback>
            </AvGroup>

            <h4>Contact person</h4>
            <AvGroup>
              <Label>Name</Label>
              <AvInput
                name="ContactPerson"
                value={defaultValues.ContactPerson}
              />
              <AvFeedback>Name is required!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <Label>Phone number</Label>
              <AvInput
                name="ContactMobilePhoneNumber"
                type="phone"
                value={defaultValues.ContactMobilePhoneNumber}
              />
              <AvFeedback>Phone number is required!</AvFeedback>
            </AvGroup>

            <AvGroup className="mb-3">
              <Label>Email</Label>
              <AvInput
                name="ContactEMail"
                type="email"
                value={defaultValues.ContactEMail}
              />
              <AvFeedback>Email is required!</AvFeedback>
            </AvGroup>

            <h4>Status</h4>
            <Select
              theme={getSelectTheme}
              className="mb-3"
              classNamePrefix="select2-selection"
              onChange={e => setStatus(e.ID)}
              options={businessStatusOptions}
              defaultValue={businessStatusOptions.filter(x => x.ID === status)}
              isOptionSelected={option => option.ID === status}
              getOptionLabel={option => option.Name}
            />

            {deleteEnabled && (
              <ButtonWithWarning color="danger" onClick={() => deleteClient()}>
                Delete
              </ButtonWithWarning>
            )}

            <Button color="primary" className="float-right">
              Submit
            </Button>
          </AvForm>
        </>
      )}
    </>
  )
}

export default BusinessForm
