import React, { useState } from "react"
import { connect } from "react-redux"
import { Button, ModalHeader, Modal, ModalBody, Label, Row } from "reactstrap"
import Select from "react-select"
import { withTranslation } from "react-i18next"

import MenuEditorFieldGroup from "./FieldGroup/FieldGroup"
import {
  editMenuItemForm,
  menuItemAddNewFieldGroup,
  submitMenuItemForm,
  reloadEntites,
} from "../../../../../store/menuItemForm/actions"
import Form from "../../../../Form/Components/Form"
import systemSettings from "../../../../../constants/administratorCMS/system.settings.json"
import NotificationAlert from "../../../../Common/Notifications/NotificationAlert"
import { getFormByFormID, getMenuItemsByID } from "../../../../../helpers/API"
import {
  checkIfFormIsReadyForSubmit,
  getSelectTheme,
} from "../../../../../helpers/Utils"

const MenuEditorForm = ({
  loading,
  form,
  menuItemOptions,
  menuItemAddNewFieldGroupAction,
  submitMenuItemFormAction,
  editMenuItemFormAction,
  reloadEntitiesAction,
  t,
}) => {
  const [entityModalOpened, setEntityModalOpened] = useState(false)

  const [formCopyModalOpened, setFormCopyModalOpened] = useState(false)
  const [formCopyMenuItem, setFormCopyMenuItem] = useState({})

  const onSubmit = () => {
    if (checkIfFormIsReadyForSubmit(form)) submitMenuItemFormAction(form)
  }

  const copyMenuItemForm = async () => {
    const menuItem = await getMenuItemsByID(formCopyMenuItem.ID)

    if (!menuItem.Form_ID) {
      NotificationAlert({
        type: "error",
        title: "Error",
        message: "Menu item doesn't have a form",
      })
      return
    }
    const formToCopy = await getFormByFormID(menuItem.Form_ID)
    const newFieldGroups = [
      ...formToCopy.FieldGroups.map(fieldGroup => ({
        ...fieldGroup,
        ID: `field_group_id_${Math.floor(Math.random() * 100000)}_${Math.floor(
          Math.random() * 100000
        )}`,
        Buttons: [
          ...fieldGroup.Buttons.map(button => ({
            ...button,
            ID: `button_id_${Math.floor(Math.random() * 100000)}_${Math.floor(
              Math.random() * 100000
            )}`,
          })),
        ],
        Fields: [
          ...fieldGroup.Fields.map(field => ({
            ...field,
            ID: `field_id_${Math.floor(Math.random() * 100000)}_${Math.floor(
              Math.random() * 100000
            )}`,
            SelectionOptions:
              field.SelectionOptions &&
              field.SelectionOptions.map(selectionOption => ({
                ...selectionOption,
                ID: `option_id_${Math.floor(
                  Math.random() * 1000000
                )}-${Math.floor(Math.random() * 10000)}-${Math.floor(
                  Math.random() * 1000000
                )}`,
              })),
          })),
        ],
      })),
    ]

    editMenuItemFormAction(form, "FieldGroups", newFieldGroups)
  }

  return (
    !loading && (
      <>
        <Modal
          isOpen={entityModalOpened}
          toggle={() => setEntityModalOpened(!entityModalOpened)}
        >
          <ModalHeader>{t("add-entity")}</ModalHeader>
          <ModalBody>
            <Form
              form={systemSettings.find(x => x.ID === "entities").Form}
              onCancel={() => setEntityModalOpened(false)}
              onAdd={() => {
                setEntityModalOpened(false)
                reloadEntitiesAction()
              }}
              rules={{ update: "True", create: "True", delete: "True" }}
              privateAPI
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={formCopyModalOpened}
          toggle={() => setFormCopyModalOpened(!formCopyModalOpened)}
        >
          <ModalHeader>{t("copy-form")}</ModalHeader>
          <ModalBody>
            <Label>Menu Item</Label>
            <Select
              theme={getSelectTheme}
              className="mb-3"
              classNamePrefix="select2-selection"
              onChange={e => {
                setFormCopyMenuItem(e)
              }}
              options={menuItemOptions}
              defaultValue={menuItemOptions.find(
                x => x.ID === formCopyMenuItem.ID
              )}
              selectedOption={menuItemOptions.find(
                x => x.ID === formCopyMenuItem.ID
              )}
              getOptionLabel={option => option.Name}
              isOptionSelected={option => option.ID === formCopyMenuItem.ID}
            />

            <Button
              color="primary"
              size="lg"
              className="top-right-button mr-1"
              onClick={() => {
                copyMenuItemForm()
              }}
            >
              {t("copy-form")}
            </Button>
          </ModalBody>
        </Modal>

        <Row className="justify-content-evenly mb-2 p-3">
          <Button
            color="secondary"
            className="col-2"
            onClick={() => setEntityModalOpened(true)}
          >
            {t("add-entity")}
          </Button>

          <Button
            color="secondary"
            className="col-2"
            onClick={() => setFormCopyModalOpened(true)}
          >
            {t("copy-form")}
          </Button>

          <Button
            color="primary"
            className="col-2"
            onClick={() => {
              onSubmit()
            }}
          >
            {t("menu-editor.submit")}
          </Button>
        </Row>

        {form.FieldGroups.map((item, index) => {
          return (
            <MenuEditorFieldGroup
              key={item.ID}
              fieldGroupIndex={index}
              menuItemOptions={menuItemOptions}
            />
          )
        })}

        <div className="d-flex justify-content-around align-items-lg-center">
          <Button
            outline
            color="primary"
            className="mt-3 mb-3"
            onClick={() => menuItemAddNewFieldGroupAction(form)}
          >
            <i className="simple-icon-plus btn-group-icon" />
            {t("menu-editor.add-new")}
          </Button>
        </div>
      </>
    )
  )
}

const mapStateToProps = ({ MenuItemForm }) => {
  const { loading, form, menuItemOptions } = MenuItemForm

  return {
    loading,
    form,
    menuItemOptions,
  }
}

export default withTranslation()(
  connect(mapStateToProps, {
    menuItemAddNewFieldGroupAction: menuItemAddNewFieldGroup,
    submitMenuItemFormAction: submitMenuItemForm,
    editMenuItemFormAction: editMenuItemForm,
    reloadEntitiesAction: reloadEntites,
  })(MenuEditorForm)
)
