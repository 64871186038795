import React from "react"
import ReactApexChart from "react-apexcharts"

const Donut = ({ options, series, height }) => {
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={height}
      />
    </React.Fragment>
  )
}

export default Donut
