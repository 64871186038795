import React, { Suspense } from "react"
import { withRouter, Switch, Redirect } from "react-router-dom"
import { connect } from "react-redux"

import Layout from "../../components/Layout"

import Authmiddleware from "../../routes/middleware/Authmiddleware"
import { userRoutes } from "../../routes/allRoutes"

const App = ({ currentUser, loading }) => {
  if (loading || !currentUser) return <div className="loading" />

  return (
    <Layout>
      <Suspense fallback={<div className="loading" />}>
        <Switch>
          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              roles={route.roles}
              exact
            />
          ))}

          <Redirect to="/404" />
        </Switch>
      </Suspense>
    </Layout>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser, loading } = Auth

  return { currentUser, loading }
}
export default withRouter(connect(mapStateToProps, null)(App))
