import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Col, Row, Card, Button } from "reactstrap"
import { connect } from "react-redux"
import moment from "moment"
import { AvForm } from "availity-reactstrap-validation"

import logoDark from "../../assets/images/logo/logo-dark.png"

import { getRandomBackgroundImage } from "helpers/Utils"
import { setClientID } from "store/actions"
import { adminRoot } from "constants/defaultValues"

const Login = ({ setClientIDAction, history, authLoading, currentUser }) => {
  const [bg, setBg] = useState()
  const [loading, setLoading] = useState(true)
  const [selectedClient, setSelectedClient] = useState()

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true)
      const randomImage = await getRandomBackgroundImage()
      setBg(randomImage)
      setLoading(false)
    }, 0)
  }, [])

  const onSubmit = (event, errors, values) => {
    if (errors.length === 0 && !authLoading) {
      setClientIDAction(selectedClient)
      history.push(adminRoot)
    }
  }

  const now = new Date()

  const locale = moment()
  locale.locale("hr")

  return (
    <React.Fragment>
      <div className="account-pages">
        <MetaTags>
          <title>CityX CMS | Client picker page</title>
        </MetaTags>

        {loading || authLoading ? (
          <div className="loading" />
        ) : (
          <>
            <div
              className="accountbg"
              style={{
                background: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>

            <div className="wrapper-page account-page-full">
              <Card className="shadow-none">
                <div className="card-block">
                  <div className="account-box">
                    <div className="card-box shadow-none p-4 h-100">
                      <div className="account-sub-box">
                        <div>
                          <div className="text-center mt-5">
                            <Link to="/">
                              <img src={logoDark} height="60" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-5 text-center">
                            Dobrodošli na CityX CMS
                          </h4>
                          <p className="text-muted text-center">
                            Odaberite klijenta kojemu želite pristupiti
                          </p>

                          <AvForm
                            className="mt-5"
                            onSubmit={(event, errors, values) =>
                              onSubmit(event, errors, values)
                            }
                          >
                            <div className="mb-3">
                              {Object.keys(currentUser.Clients).map(
                                clientID => (
                                  <Button
                                    className="w-100 mb-2 pt-2 pb-2"
                                    color={`${
                                      clientID === selectedClient
                                        ? "primary"
                                        : "white"
                                    }`}
                                    style={{
                                      border: `${
                                        clientID === selectedClient
                                          ? "transparent 1px solid"
                                          : "black 1px solid"
                                      }`,
                                    }}
                                    onClick={() => setSelectedClient(clientID)}
                                  >
                                    {
                                      currentUser.Clients[clientID].MetaData
                                        .Name
                                    }
                                  </Button>
                                )
                              )}
                            </div>

                            <Row className="mb-3">
                              <Col sm="6">
                                <button
                                  type="submit"
                                  className="btn  w-md waves-effect waves-light"
                                  onClick={() => history.push("/logout")}
                                >
                                  <i
                                    className="simple-icon-arrow-left btn-group-icon mr-2"
                                    style={{ fontSize: "10px" }}
                                  />
                                  Log out
                                </button>
                              </Col>
                              <Col sm="6" className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md waves-effect waves-light"
                                >
                                  Select
                                </button>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>

                        <div className="text-center">
                          <div className="d-flex justify-content-between">
                            <p>{`@ CityX Apps, ${locale
                              .utc(now.toLocaleString())
                              .format("YYYY")}`}</p>
                            <p>
                              <a href="www.cityx.hr">www.cityx.hr</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { loading: authLoading, currentUser } = Auth
  return { authLoading, currentUser }
}

export default connect(mapStateToProps, { setClientIDAction: setClientID })(
  Login
)
