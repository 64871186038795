import CryptoJS from "crypto-js"

import { APIPath, PublicAPIPath } from "../constants/defaultValues"
import { getCurrentUser } from "./Utils"

import NotificationAlert from "../components/Common/Notifications/NotificationAlert"

// prepares URLs for API Calls by finding the places in the URL where a
// value should be and replacing them with the appropriate value
// Example:
// URL: 'http://{{ID}}/items/{{SecondID}}', values: {ID: '123', secondID: '256'}
// -> 'http://123/items/256'
export const makeURL = (inputURL, values) => {
  let url = inputURL.replace("{{APIURL}}", APIPath)
  url = url.replace("{{PUBLICAPIURL}}", PublicAPIPath)
  let match = url.match(/\{{(.*?)\}}/)
  while (match) {
    url = url.replace(match[0], values[match[1]])
    match = url.match(/\{{(.*?)\}}/)
  }
  return url
}

// creates a hash for header authorization
export const createHash = (req, eeID, eeSecret) => {
  let requestBody = req

  if (requestBody === "") {
    requestBody = "{}"
  } else {
    requestBody = JSON.stringify(JSON.parse(requestBody))
  }

  const preHashValue = `${eeSecret}${eeID}${eeSecret}${requestBody}${eeSecret}${eeID}${eeSecret}`

  return CryptoJS.SHA512(preHashValue).toString()
}

export const createAuthorizationHeader = (raw, publicAPI) => {
  const currentUser = getCurrentUser()

  const eeID = currentUser
    ? currentUser.eeID
    : "00000000-0000-0000-0000-000000000000"
  const eeSecret = currentUser ? currentUser.eeSecret : "000"

  // console.log(eeID, eeSecret)

  const hash = createHash(raw, eeID, eeSecret)

  if (publicAPI) return `EE-Public ${eeID}:${hash}`

  return `EE-Private ${eeID}:${hash}`
}

// makes API Call with given values, url and method
// also prepares the URL and values by using makeURL and prepareData functions
export const makeAPICall = async (qurl, method, values) => {
  const raw = method === "GET" ? JSON.stringify({}) : JSON.stringify(values)

  const url = makeURL(qurl, values)
  let ret = null

  const authKey = createAuthorizationHeader(
    raw,
    qurl.includes("{{PUBLICAPIURL}}")
  )

  // console.log(authKey)

  const myHeaders = new Headers()
  myHeaders.append("Authorization", authKey)
  myHeaders.append("Content-Type", "application/json")

  const requestOptions = {
    method,
    headers: myHeaders,
    body: method === "GET" ? undefined : raw,
    redirect: "follow",
  }

  console.log({ ...requestOptions, url })

  // console.log(requestOptions.method)
  // console.log(raw)
  // console.log(authKey)
  // console.log(url)

  // console.log(raw);

  // ALTERNATIVE API CALL FOR TESTING
  // const settings = {
  //   url,
  //   method,
  //   timeout: 0,
  //   headers: myHeaders,
  //   data: method === 'GET' ? undefined : raw,
  //   error: (request) => {
  //     // request.getAllResponseHeaders().forEach((i) => console.log(i));
  //     console.log(request.getAllResponseHeaders());
  //     console.log(request);
  //   },
  // };

  // $.ajax(settings).done(function (response) {
  //   console.log(response);
  // });

  await fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => {
      ret = JSON.parse(result)
      console.log(ret)
      if (
        window.location.href.includes("localhost") &&
        (ret.StatusCode !== 200 || !ret || !ret.Data)
      ) {
        console.log("REQUEST OPTIONS", requestOptions)
        // myHeaders.forEach((header) => console.log(header));
        console.log("URL", url)
        console.log("RETURN", ret)
      }
    })
    .catch(error => {
      console.log(error)
    })

  return ret
}

export const makeAPICallWithConfirmation = async (qurl, method, values) => {
  const apiCall = await makeAPICall(qurl, method, values)

  if (apiCall.Success) {
    NotificationAlert({
      type: "success",
      title: "Success",
      expiration: 3000,
    })

    return apiCall.Data
  }

  let errorMessage = ""

  if (apiCall && apiCall.ErrorMessage.Message)
    errorMessage = apiCall.ErrorMessage.Message
  else if (apiCall && apiCall.ErrorMessage) errorMessage = apiCall.ErrorMessage

  NotificationAlert({
    type: "error",
    title: "Error",
    message: errorMessage,
    expiration: 3000,
  })

  return apiCall
}

export const makeAPICallWithErrorHandling = async (
  qurl,
  method,
  values,
  history
) => {
  const apiCall = await makeAPICall(qurl, method, values)

  if (apiCall.Success) {
    return apiCall.Data
  }

  history.push("/500")
}

export const getApplicationsList = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/applications`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : []

  return data
}

export const getMenuItemsByID = async menuItemID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/menuitems/${menuItemID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getMenuItemsByParentMenuItemID = async menuItemID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/menuitems/${menuItemID}/menuitems`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getApplicationMenuItemsByApplicationID = async applicationID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/applications/${applicationID}/menuitems`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getApplicationMenuByApplicationID = async applicationID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/cms/applications/${applicationID}/getMenu`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getApplicationByApplicationID = async applicationID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/applications/${applicationID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllFieldTypes = async () => {
  const apiCall = await makeAPICall(`{{APIURL}}structure/fieldtypes`, "GET", {})

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllFieldTypesForCMSCreator = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/cms/fieldtypegroups/getFieldTypeSelections`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllFieldGroupTypes = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/fieldgrouptypes`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getEntityFieldsByEntityID = async ID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/entities/${ID}/fields`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllEntities = async () => {
  const apiCall = await makeAPICall(`{{APIURL}}structure/entities`, "GET", {})

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllGlobalEntities = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}globalentity/globalentities`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllButtonTypes = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure//fieldgroupbuttontypes`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllClientTypes = async () => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/clienttypes",
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllClientTypeCategories = async () => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/clienttypecategories",
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getFormByFormID = async formID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/cms/forms/${formID}/getStructure`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const setFormStructure = async form => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}structure/cms/forms/setStructure",
    "PUT",
    { ...form }
  )

  return apiCall
}

export const getSubAPITables = async (formURL, Parent_ID) => {
  const apiCall = await makeAPICall(formURL, "GET", { Parent_ID })

  const data = apiCall.Success ? apiCall.Data : apiCall

  return {
    active: data.Relations.Selected,
    inactive: data.Relations.Available,
  }
}

export const createOrUpdateClient = async values => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}authentication/clients",
    "PUT",
    values
  )

  return apiCall
}

export const getAllClients = async () => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/clients",
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllClientsByType = async typeID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clienttypes/${typeID}/clients`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllClientsByCategory = async categoryID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clienttypecategories/${categoryID}/clients`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientByClientID = async ID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clients/${ID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientApplicationByClientID = async ID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clients/${ID}/applications`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const setApplicationForClient = async (appID, clientID) => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}authentication/clients/addApplicationToClient`,
    "POST",
    { Client_ID: clientID, Application_ID: appID }
  )

  return apiCall
}

export const removeApplicationFromClient = async (appID, clientID) => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}authentication/clientsapplications`,
    "DELETE",
    { Client_ID: clientID, Application_ID: appID }
  )

  return apiCall
}

export const deleteClientByClientID = async ID => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}authentication/clients`,
    "DELETE",
    { ID }
  )

  return apiCall
}

export const getClientAdministrators = async clientID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clients/${clientID}}/users`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const registerUserCall = async (email, password, name) => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/users/register",
    "POST",
    { EMail: email, Password: password, DisplayName: name }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const setRolesToUser = async (userID, roles) => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}authentication/usersroles/setUserRoles",
    "PUT",
    {
      User_ID: userID,
      Relations: roles,
    }
  )

  return apiCall
}

export const updateUserCall = async values => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}authentication/users",
    "PUT",
    values
  )

  return apiCall
}

export const setClientApplicationBuild = async (
  Client_ID,
  Application_ID,
  BuildNumber
) => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}authentication/clients/setClientApplicationBuild",
    "PUT",
    { Client_ID, Application_ID, BuildNumber }
  )

  return apiCall
}

export const getClientApplicationRolesByClientAndApplicationID = async (
  clientID,
  appID
) => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clients/${clientID}/applications/${appID}/getRoles`,
    "GET",
    {}
  )

  return apiCall
}

export const setClientApplicationRoles = async (clientID, appID, roles) => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}authentication/clientsapplicationsroles/setClientApplicationRoles",
    "PUT",
    { Client_ID: clientID, Application_ID: appID, Relations: roles }
  )

  return apiCall
}

export const removePublicAPIRelation = async (
  url,
  IDOne,
  IDTwo,
  keyOne,
  keyTwo
) => {
  const apiCall = makeAPICallWithErrorHandling(url, "DELETE", {
    [keyOne]: IDOne,
    [keyTwo]: IDTwo,
  })

  return apiCall
}

export const addPublicAPIRelation = async (
  url,
  IDOne,
  IDTwo,
  keyOne,
  keyTwo
) => {
  const apiCall = makeAPICall(url, "POST", {
    [keyOne]: IDOne,
    [keyTwo]: IDTwo,
  })

  return apiCall
}

export const searchDatabase = async (tableName, resultName, params) => {
  const apiCall = makeAPICall("{{PUBLICAPIURL}}entities/search", "POST", {
    Table: tableName,
    Result: resultName,
    RelationKeys: {
      ID: "ID",
    },
    Search: params,
  })

  return apiCall
}

export const getFormOptions = async (formID, appID, clientID) => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/cms/forms/${formID}/getSelectionOptions?application_ID=${appID}&client_ID=${clientID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllFiles = async () => {
  const apiCall = await makeAPICall(`{{PUBLICAPIURL}}files`, "GET", {})

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const createFile = async (clientID, name, url, path, type) => {
  const apiCall = await makeAPICall(`{{PUBLICAPIURL}}files`, "POST", {
    Client_ID: clientID,
    Name: name,
    Description: name,
    URL: url,
    Path: path,
    Type: type,
    Display: true,
    Status: true,
  })

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const deleteFile = async file => {
  const apiCall = await makeAPICall(`{{PUBLICAPIURL}}files`, "PUT", {
    ...file,
    Status: false,
  })

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const deleteFilePermanently = async ID => {
  const apiCall = await makeAPICall(`{{PUBLICAPIURL}}files`, "DELETE", {
    ID,
  })

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientDefaultImages = async ID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clientdefaultimages/${ID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const setClientDefaultImages = async (images, ID) => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}authentication/clientdefaultimages`,
    "PUT",
    {
      ID,
      ...images,
    }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientDefaultTexts = async ID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/clientdefaulttexts/${ID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const setClientDefaultTexts = async (texts, ID) => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}authentication/clientdefaulttexts`,
    "PUT",
    {
      ID,
      ...texts,
    }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllCountries = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}globalentity/countries`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllCounties = async () => {
  const apiCall = await makeAPICall(
    `{{APIURL}}globalentity/counties`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getAllRegions = async () => {
  const apiCall = await makeAPICall(`{{APIURL}}globalentity/regions`, "GET", {})

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientMenuItemStatuses = async (clientID, appID) => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/cms/clients/${clientID}/applications/${appID}/getClientMenuItemStatuses`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const setClientMenuItemStatuses = async (clientID, appID, menuItems) => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}structure/cms/clients/setClientMenuItemStatuses`,
    "PUT",
    {
      Client_ID: clientID,
      Application_ID: appID,
      Data: { ...menuItems },
    }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getEntityCitySelections = async ID => {
  const apiCall = await makeAPICall(
    `{{PUBLICAPIURL}}entities/citySelection/${ID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const setEntityCitySelections = async (ID, Entity_ID, values) => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{PUBLICAPIURL}}entities/citySelection`,
    "PUT",
    {
      EntityValueID: ID,
      Entity_ID,
      Cities: values,
    }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getApplicationQuestionCollectionsByAppID = async appID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/applications/${appID}/questioncollections`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getQuestionCollectionByQuestionCollectionID = async ID => {
  const apiCall = await makeAPICall(
    `{{APIURL}}structure/questioncollections/${ID}/questions`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const updateQuestionCollection = async values => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}structure/questioncollectionsquestions/setQuestions`,
    "PUT",
    values
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const createQuestionCollection = async (
  appID,
  name,
  description,
  active
) => {
  const apiCall = await makeAPICallWithConfirmation(
    `{{APIURL}}structure/questioncollections`,
    "POST",
    {
      Application_ID: appID,
      DisplayName: name,
      Description: description,
      Active: active,
    }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientAdministrator = async ID => {
  const apiCall = await makeAPICallWithErrorHandling(
    `{{APIURL}}authentication/users/${ID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientAdministratorRoles = async (clientID, ID) => {
  const apiCall = await makeAPICallWithErrorHandling(
    `{{APIURL}}authentication/clients/${clientID}/users/${ID}/roles`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const createClientAdministrator = async (
  values,
  clientID,
  appID,
  role
) => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/users/create",
    "POST",
    values
  )

  if (!apiCall.ErrorMessage || apiCall.StatusCode === 500) {
    const { ID } = apiCall.Data.MetaData

    const roles = []

    if (role !== "None") {
      roles.push({
        Role_ID: role,
      })
    }

    const rolesAPICall = await makeAPICallWithConfirmation(
      "{{APIURL}}authentication/clientsusersroles/setClientUserRoles",
      "PUT",
      {
        Client_ID: clientID,
        Application_ID: appID,
        User_ID: ID,
        Relations: roles,
      }
    )

    if (!rolesAPICall.ErrorMessage) {
      return { MetaData: apiCall.Data.MetaData, Roles: rolesAPICall }
    }
  } else {
    NotificationAlert({
      type: "error",
      title: "Error",
      message: apiCall.ErrorMessage.Message || apiCall.ErrorMessage,
    })
  }
}

export const updateClientAdministrator = async (
  values,
  clientID,
  appID,
  role
) => {
  const apiCall = await makeAPICall(
    "{{APIURL}}authentication/users",
    "PUT",
    values
  )

  if (!apiCall || !apiCall.ErrorMessage) {
    const { ID } = values

    const roles = []

    if (role !== "None") {
      roles.push({
        Role_ID: role,
      })
    }

    const rolesAPICall = await makeAPICallWithConfirmation(
      "{{APIURL}}authentication/clientsusersroles/setClientUserRoles",
      "PUT",
      {
        Client_ID: clientID,
        Application_ID: appID,
        User_ID: ID,
        Relations: roles,
      }
    )

    if (!rolesAPICall.ErrorMessage) {
      return { MetaData: apiCall.Data, Roles: rolesAPICall }
    }
  } else {
    NotificationAlert({
      type: "error",
      title: "Error",
      message: apiCall.ErrorMessage.Message || apiCall.ErrorMessage,
    })
  }
}

export const getUserByEMail = async email => {
  const apiCall = await makeAPICall(
    `{{APIURL}}authentication/users?EMail=${email}`,
    "GET",
    {}
  )

  const data = apiCall.Success
    ? apiCall.Data.length > 0
      ? apiCall.Data[0]
      : undefined
    : undefined

  return data
}

export const makeApplicationBuild = async values => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}structure/cms/applications/build",
    "POST",
    values
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getNewOfferNumber = async () => {
  const apiCall = await makeAPICallWithErrorHandling(
    "{{APIURL}}management/offers/getNewOfferNumber",
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const createOffer = async (
  Client_ID,
  ClientOIB,
  ClientName,
  Number,
  Amount,
  DocumentURL,
  DataJSON
) => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}management/offers",
    "POST",
    {
      Client_ID,
      ClientOIB,
      ClientName,
      Number: Number.split("/")[1],
      Amount,
      DocumentURL,
      DataJSON: JSON.stringify(DataJSON),
    }
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const getClientOffers = async clientID => {
  const apiCall = await makeAPICallWithErrorHandling(
    `{{APIURL}}management/offers?Client_ID=${clientID}`,
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}

export const rebuildEntitiesStructure = async () => {
  const apiCall = await makeAPICallWithConfirmation(
    "{{APIURL}}structure/cms/sql/updateEntitiesStructure",
    "PATCH",
    {}
  )

  return apiCall
}

export const getSupportedActionScripts = async () => {
  const apiCall = await makeAPICallWithErrorHandling(
    "{{APIURL}}structure/cms/actionScripts/getSupportedActionScripts",
    "GET",
    {}
  )

  const data = apiCall.Success ? apiCall.Data : apiCall

  return data
}
