import ApplicationEditor from "../pages/app/cms-creator/cms/application-editor"
import MenuItemEditor from "../pages/app/cms-creator/cms/menu-item-editor"
import ApplicationBuilder from "../pages/app/cms-creator/cms/application-version-builder"
import QuestionCollections from "../pages/app/cms-creator/cms/question-collections"
import QuestionCollection from "../pages/app/cms-creator/cms/question-collection"

import SystemSettings from "../pages/app/cms-creator/system-settings/systemSettings"
import Regions from "../pages/app/cms-creator/system-settings/regions"
import AdminSettings from "../pages/app/cms-creator/admin-settings/adminSettings"

import { adminRoot } from "../constants/defaultValues"

export default [
  { path: `${adminRoot}applicationEditor`, component: ApplicationEditor },
  {
    path: `${adminRoot}menuItemEditor/:ID`,
    component: MenuItemEditor,
  },
  {
    path: `${adminRoot}menuItemEditor/:ID/:ID2`,
    component: MenuItemEditor,
  },
  {
    path: `${adminRoot}menuItemEditor`,
    component: MenuItemEditor,
  },
  { path: `${adminRoot}applicationBuilder`, component: ApplicationBuilder },
  { path: `${adminRoot}questionCollections`, component: QuestionCollections },
  { path: `${adminRoot}questionCollection`, component: QuestionCollection },

  {
    path: `${adminRoot}systemSettings/:ID`,
    component: SystemSettings,
  },
  {
    path: `${adminRoot}adminSettings`,
    component: AdminSettings,
  },
  {
    path: `${adminRoot}regions`,
    component: Regions,
  },
]
