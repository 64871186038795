import React, { useState } from "react"
import { Input, Label, Col } from "reactstrap"
import { connect } from "react-redux"
import Select from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"

import {
  deleteButton,
  editMenuItemFieldGroupButton,
} from "../../../../../../../store/menuItemForm/actions"
import { ThemeColors } from "../../../../../../../helpers/ThemeColors"
import { getSelectTheme } from "helpers/Utils"

const colors = ThemeColors()

const ButtonEditorField = ({
  form,
  fieldGroupIndex,
  buttonIndex,
  requestMethodOptions,
  editMenuItemFieldGroupButtonAction,
  deleteButtonAction,
  buttonTypeOptions,
}) => {
  const button = form.FieldGroups[fieldGroupIndex].Buttons[buttonIndex]
  const [showDetails, setShowDetails] = useState(false)

  const editButton = (key, newValue) => {
    editMenuItemFieldGroupButtonAction(
      form,
      fieldGroupIndex,
      buttonIndex,
      key,
      newValue
    )
  }

  return (
    <>
      <Col className="mb-3">
        <div>
          <div className="pl-2 d-flex flex-grow-1 min-width-zero">
            <div className="card-body align-self-center d-flex flex-lg-row justify-content-between min-width-zero align-items-lg-center">
              <div className="align-self-center pr-4 w-10">
                <div>
                  <Label> </Label>
                  <div>
                    <button
                      className="text-only-button"
                      type="button"
                      id={`move_${button.ID}`}
                      onClick={() => {}}
                    >
                      <FontAwesomeIcon
                        icon={faGripVertical}
                        color={colors.separatorColor}
                      />
                    </button>{" "}
                  </div>
                </div>
              </div>
              <div className="w-30 w-sm-30 mr-2">
                <Label>Type</Label>

                <Select
                  theme={getSelectTheme}
                  classNamePrefix="select2-selection"
                  onChange={e => editButton("FieldGroupButtonType_ID", e.ID)}
                  options={buttonTypeOptions}
                  defaultValue={buttonTypeOptions.filter(
                    x => x.ID === button.FieldGroupButtonType_ID
                  )}
                  selectedOption={buttonTypeOptions.filter(
                    x => x.ID === button.FieldGroupButtonType_ID
                  )}
                  getOptionLabel={option => option.Name}
                  isOptionSelected={option =>
                    option.ID === button.FieldGroupButtonType_ID
                  }
                />
              </div>

              <div className="w-30 w-sm-30 mr-2">
                <Label>Request URL</Label>
                <Input
                  type="text"
                  className="list-item-heading truncate"
                  name={button.RequestURL}
                  placeholder="RequestURL"
                  value={button.RequestURL}
                  onChange={e => editButton("RequestURL", e.target.value)}
                />
              </div>

              <div className="w-30 w-sm-30">
                <Label>Name</Label>
                <Input
                  type="text"
                  className="list-item-heading truncate"
                  name={button.Name}
                  placeholder="Name"
                  value={button.Name}
                  onChange={e => editButton("Name", e.target.value)}
                />
              </div>

              <div className="custom-control custom-checkbox align-self-center">
                <div>
                  <Label> </Label>
                  <div>
                    <button
                      className="text-only-button"
                      type="button"
                      id={`details_${button.ID}`}
                      onClick={() => setShowDetails(!showDetails)}
                    >
                      <i className="simple-icon-eye" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="custom-control custom-checkbox align-self-center">
                <div>
                  <Label> </Label>
                  <div>
                    <button
                      className="text-only-button"
                      type="button"
                      id={`delete_${button.ID}`}
                      onClick={() =>
                        deleteButtonAction(form, fieldGroupIndex, buttonIndex)
                      }
                    >
                      <i className="simple-icon-trash" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showDetails && (
            <>
              <div className="pl-5 d-flex flex-grow-1 min-width-zero card-body justify-content-between">
                <div className="w-30 w-sm-30">
                  <Label>Method</Label>
                  <Select
                    theme={getSelectTheme}
                    classNamePrefix="select2-selection"
                    onChange={e => editButton("RequestMethod", e.Name)}
                    options={requestMethodOptions}
                    defaultValue={requestMethodOptions.filter(
                      x => x.Name === button.RequestMethod
                    )}
                    selectedOption={requestMethodOptions.filter(
                      x => x.Name === button.RequestMethod
                    )}
                    getOptionLabel={option => option.Name}
                    isOptionSelected={option =>
                      option.Name === button.RequestMethod
                    }
                  />
                </div>
                <div className="w-30 w-sm-30">
                  <Label>Text</Label>
                  <Input
                    type="text"
                    className="w-100 list-item-heading truncate"
                    name={button.Text}
                    placeholder="Text"
                    value={button.Text}
                    onChange={e => editButton("Text", e.target.value)}
                  />
                </div>
                <div className="w-30 w-sm-30">
                  <Label>Confirmation</Label>
                  <Input
                    type="text"
                    className="w-100 list-item-heading truncate"
                    name={button.Confirmation}
                    placeholder="Confirmation"
                    value={button.Confirmation}
                    onChange={e => editButton("Confirmation", e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Col>
    </>
  )
}

const mapStateToProps = ({ MenuItemForm }) => {
  const { requestMethodOptions, buttonTypeOptions } = MenuItemForm
  return { requestMethodOptions, buttonTypeOptions }
}
export default connect(mapStateToProps, {
  deleteButtonAction: deleteButton,
  editMenuItemFieldGroupButtonAction: editMenuItemFieldGroupButton,
})(ButtonEditorField)
