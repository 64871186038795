import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { unsearchableFieldTypes } from "constants/defaultValues"
import { getFormOptions } from "helpers/API"
import { getSelectTheme } from "helpers/Utils"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { Button, Label } from "reactstrap"

import NotificationAlert from "../../Common/Notifications/NotificationAlert"
import Field from "../Fields/FieldPicker"

const TableSearch = ({
  formID,
  appID,
  clientID,
  fields,
  searchFunction,
  propApplication_ID,
  propClient_ID,
}) => {
  const [chosenField, setChosenField] = useState(fields[0])
  const [currentValue, setCurrentValue] = useState("")
  const [fieldsWithOptions, setFieldsWithOptions] = useState(fields)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true)
      const formOptions = await getFormOptions(
        formID,
        propApplication_ID || appID,
        propClient_ID || clientID
      )

      const newFields = [...fields]

      if (formOptions) {
        fields.forEach((_field, fieldIndex) => {
          if (formOptions[_field.ID]) {
            newFields[fieldIndex].Options =
              formOptions[_field.ID].SelectionOptions
          } else if (_field.SelectionOptions) {
            newFields[fieldIndex].Options = _field.SelectionOptions
          }
        })
      }

      const filteredFields = newFields.filter(
        x => !unsearchableFieldTypes.includes(x.Type)
      )
      setFieldsWithOptions(filteredFields)
      setLoading(false)
    }, 100)
  }, [
    fields,
    formID,
    formID,
    appID,
    clientID,
    propApplication_ID,
    propClient_ID,
  ])

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <AvForm
          onSubmit={(event, errors, values) => {
            const mergedValues = {
              [chosenField.Name]: currentValue,
              ...values,
              Client_ID: propClient_ID || clientID,
            }

            if (
              !mergedValues[chosenField.Name] &&
              mergedValues[chosenField.Name] !== false
            ) {
              NotificationAlert({
                type: "warning",
                title: "",
                message: "Can't search empty field",
                expiration: 3000,
              })
            } else {
              searchFunction(mergedValues)
            }
          }}
        >
          <div className="row search-table justify-content-between">
            <div className="row pr-0">
              <Field
                item={chosenField}
                value={currentValue}
                updateValue={(name, val) => setCurrentValue(val)}
              />

              <div className="search-table-value">
                <Label>Value</Label>
                <Select
                  classNamePrefix="select2-selection"
                  onChange={e => {
                    setChosenField(e)
                    setCurrentValue(null)
                  }}
                  options={fieldsWithOptions}
                  defaultValue={fieldsWithOptions[0]}
                  isOptionSelected={option => option === chosenField}
                  getOptionLabel={option => option.Label}
                  theme={getSelectTheme}
                />
              </div>
            </div>

            <div className="search-table-button row pr-0 mt-3">
              <div>
                <Button className="float-right" color="primary">
                  Search
                </Button>
              </div>
            </div>
          </div>
        </AvForm>
      )}
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { clientID, appID } = CMS
  return { clientID, appID }
}
export default connect(mapStateToProps, {})(TableSearch)
