import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import Select from "react-select"

import {
  removeApplicationFromClient,
  setApplicationForClient,
  getApplicationsList,
} from "../../../../helpers/API"
import { getSelectTheme } from "helpers/Utils"

const ClientApplicationEditor = ({ appID, clientID, reload }) => {
  const [loading, setLoading] = useState(true)
  const [application, setApplication] = useState(appID)
  const [applicationOptions, setApplicationOptions] = useState([])

  const deleteAppEnabled = false

  useEffect(() => {
    setLoading(true)
    setTimeout(async () => {
      const applicationOptionsAPI = await getApplicationsList()
      setApplicationOptions(applicationOptionsAPI)
      setLoading(false)
    }, 100)
  }, [appID, clientID])

  const onSubmit = async () => {
    await setApplicationForClient(application, clientID)
    if (reload) reload()
  }

  const onDelete = async () => {
    await removeApplicationFromClient(appID, clientID)
    if (reload) reload()
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <div className="p-4 mb-5 mt-2">
          <Select
            theme={getSelectTheme}
            className="mb-3"
            classNamePrefix="select2-selection"
            onChange={e => setApplication(e.ID)}
            options={applicationOptions}
            defaultValue={applicationOptions.filter(x => x.ID === application)}
            isOptionSelected={option => option.ID === application}
            getOptionLabel={option => option.Name}
            isDisabled={appID}
          />

          <Button
            color="primary"
            className="float-right"
            onClick={() => onSubmit()}
            disabled={appID && true}
          >
            Submit
          </Button>

          {deleteAppEnabled && (
            <Button color="danger" onClick={() => onDelete()}>
              Delete app
            </Button>
          )}
        </div>
      )}
    </>
  )
}

export default ClientApplicationEditor
