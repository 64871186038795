import React, { useState } from "react"
import { connect } from "react-redux"
import { Button, Label, Modal, ModalBody } from "reactstrap"

import {
  defaultFileImage,
  imageTypes,
} from "../../../../constants/defaultValues"
import GalleryModal from "../../../Gallery/GalleryModal"

const File = ({ item, value, updateValue, disabled }) => {
  const [modalOpened, setModalOpened] = useState(false)

  return (
    <div className="w-100 mb-3">
      <Modal
        isOpen={modalOpened}
        toggle={() => setModalOpened(!modalOpened)}
        size="xl"
      >
        <ModalBody>
          <GalleryModal
            item={item}
            defaultValue={value}
            updateValue={updateValue}
            cancel={() => setModalOpened(false)}
          />
        </ModalBody>
      </Modal>
      <Label className="w-100">{item.Label}</Label>
      {value && value.length > 0 ? (
        <>
          <Button
            color="transparent"
            className="file-upload-area"
            onClick={() => setModalOpened(true)}
            disabled={disabled}
          >
            <div className="gallery-files-list-sm col-12 row p-0 m-0">
              {value.map(file => (
                <div className="col-12 col-sm-3 p-1">
                  <div
                    key={`modal_${file.ID}`}
                    className="gallery-file-list-file"
                  >
                    <img
                      src={
                        imageTypes.includes(file.Type)
                          ? file.URL
                          : defaultFileImage
                      }
                      className={`gallery-file-list-file-image mr-2 ${
                        !imageTypes.includes(file.Type) && "p-2"
                      } `}
                      alt={file.Name}
                    />
                    <span>{file.Name}</span>
                  </div>
                </div>
              ))}
            </div>
          </Button>
        </>
      ) : (
        <Button
          className="file-upload-button"
          onClick={() => setModalOpened(true)}
          disabled={disabled}
        >
          Upload Files
        </Button>
      )}
    </div>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { clientID } = CMS

  return { clientID }
}
export default connect(mapStateToProps, {})(File)
