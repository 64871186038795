import React from "react"

import StartPage from "../../../../components/Admin/AccountManager/StartPage"

const BusinessList = ({ history }) => {
  return (
    <StartPage
      title="acccounts-manager.businesses"
      clientCategory="Business"
      clientURL="businesses"
      history={history}
    />
  )
}

export default BusinessList
