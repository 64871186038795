import React, { useState } from "react"
import Select from "react-select"
import { Input, Label, Col, Button, Card } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { ReactSortable } from "react-sortablejs"
import { withTranslation } from "react-i18next"

import { ThemeColors } from "../../../helpers/ThemeColors"
import SingleImage from "../../Form/Fields/FieldTypes/SingleImage"
import StoreRow from "./StoreRow"
import { getNewID, getSelectTheme } from "helpers/Utils"

const colors = ThemeColors()

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.Name}</span>
  </div>
)

const BlockRow = ({
  block,
  editBlock,
  deleteBlock,
  blockTypeOptions,
  noSorting,
  opened,
  className,
  t,
}) => {
  const [showDetails, setShowDetails] = useState(opened)

  const editStore = (index, key, value) => {
    const newStores = block.Stores
    newStores[index][key] = value
    editBlock("Stores", newStores)
  }

  const deleteStore = index => {
    const newStores = block.Stores
    newStores.splice(index, 1)
    editBlock("Stores", newStores)
  }

  const addStore = () => {
    const newStores = block.Stores
    newStores.push({
      ID: getNewID(),
      Title: "",
      // BusinessID: "",
      VisibilityID: "",
      Subtitle: "",
      Description: "",
      BannerImage: "",
      SinglePageImage: "",
      IconImage: "",
      Color1: "0xFFF",
      Color2: "0xFFF",
    })
    editBlock("Stores", newStores)
  }

  return (
    <Col className={`mb-2 mt-2 ${className}`}>
      <AvForm>
        <div className="flex-grow-1 min-width-zero">
          <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
            {!noSorting && (
              <div className="align-self-center">
                <button
                  className="text-only-button handle"
                  type="button"
                  id={`move_${block.ID}`}
                  onClick={() => {}}
                >
                  <FontAwesomeIcon
                    icon={faGripVertical}
                    color={colors.separatorColor}
                  />
                </button>
              </div>
            )}
            <div
              className={`col-4 p-0 ${deleteBlock || !opened ? "" : "pr-1"}`}
            >
              <Select
                classNamePrefix="select2-selection"
                onChange={e => {
                  editBlock("Type", e.ID)
                }}
                options={blockTypeOptions}
                defaultValue={blockTypeOptions.find(x => x.ID === block.Type)}
                selectedOption={blockTypeOptions.find(x => x.ID === block.Type)}
                getOptionLabel={option => option.Name}
                isOptionSelected={option => option.ID === block.Type}
                formatGroupLabel={formatGroupLabel}
                theme={getSelectTheme}
              />
            </div>
            <div
              className={`col-${deleteBlock || !opened ? "6" : "8"} p-0 ${
                deleteBlock || !opened ? "" : "pl-1"
              }`}
            >
              <Input
                type="text"
                className="list-item-heading truncate w-100"
                name={block.Title}
                placeholder="Title"
                value={block.Title}
                onChange={e => editBlock("Title", e.target.value)}
              />
            </div>
            <div className="custom-control custom-checkbox align-self-center col-1 d-flex justify-content-center pl-0">
              {!opened && (
                <button
                  className="text-only-button col-6"
                  type="button"
                  id={`details_${block.ID}`}
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <i className="simple-icon-eye" />
                </button>
              )}

              {deleteBlock && (
                <button
                  className="text-only-button col-6"
                  type="button"
                  id={`delete_${block.ID}`}
                  onClick={() => deleteBlock()}
                >
                  <i className="simple-icon-trash" />
                </button>
              )}
            </div>
          </div>
        </div>
        {showDetails && (
          <div className="flex-grow-1 min-width-zero">
            <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              {noSorting && (
                <div className="col-6 p-1">
                  <Label>Order</Label>
                  <Input
                    type="number"
                    className="mb-2 list-item-heading truncate"
                    name={block.Order}
                    placeholder="0"
                    value={block.Order}
                    onChange={e => {
                      editBlock("Order", Number(e.target.value))
                    }}
                  />
                </div>
              )}
            </div>
            <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              <div className="col-6 p-1">
                <Label>Start time</Label>
                <Input
                  type="datetime-local"
                  className="mb-2 list-item-heading truncate"
                  name={block.StartTime}
                  placeholder="Start time"
                  value={block.StartTime}
                  onChange={e => editBlock("StartTime", e.target.value)}
                />
              </div>
              <div className="col-6 p-1">
                <Label>End time</Label>
                <Input
                  type="datetime-local"
                  className="mb-2 list-item-heading truncate"
                  name={block.EndTime}
                  placeholder="End time"
                  value={block.EndTime}
                  onChange={e => editBlock("EndTime", e.target.value)}
                />
              </div>
            </div>

            <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              <div className="col-12 p-1">
                <Label>Stores:</Label>
                <ReactSortable
                  list={block.Stores}
                  setList={storesList => {
                    // ReactSortable would sometimes push an undefined object
                    // to the end of the list
                    // To prevent this object from crashing the applicaton,
                    // a check is done, and those objects are removed
                    const checkedStoresList = [...storesList]
                    while (checkedStoresList.indexOf(undefined) !== -1) {
                      checkedStoresList.splice(
                        checkedStoresList.indexOf(undefined),
                        1
                      )
                    }
                    editBlock("Stores", checkedStoresList)
                  }}
                  className="list-unstyled "
                >
                  {block.Stores.map((store, storeIndex) => (
                    <Card key={store.ID}>
                      <StoreRow
                        type={block.Type}
                        store={store}
                        editStore={(key, value) =>
                          editStore(storeIndex, key, value)
                        }
                        deleteStore={() => deleteStore(storeIndex)}
                      />
                    </Card>
                  ))}
                </ReactSortable>
                <div className="d-flex mb-5 justify-content-around align-items-lg-center">
                  <Button
                    outline
                    color="primary"
                    className="mt-3"
                    onClick={() => addStore()}
                  >
                    <i className="simple-icon-plus btn-group-icon" />
                    {t("menu-editor.add-new")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </AvForm>
    </Col>
  )
}

export default withTranslation()(BlockRow)
