import React, { useState } from "react"
import Switch from "rc-switch"
import { Label } from "reactstrap"
import { AvGroup } from "availity-reactstrap-validation"
import "rc-switch/assets/index.css"

const SwitchInput = ({ item, disabled, value, updateValue }) => {
  const [_value, setValue] = useState(value)

  return (
    <AvGroup className="error-t-negative error-l-150 d-flex mb-3 align-items-center align-content-center justify-content-between">
      <Label className=" p-0">{item.Label}</Label>
      <Switch
        className="custom-switch custom-switch-primary custom-switch-small"
        checked={_value}
        onChange={e => {
          setValue(e)
          updateValue(e)
        }}
        disabled={disabled}
      />
    </AvGroup>
  )
}

export default SwitchInput
