import React, { useState, useEffect } from "react"
import { Row, Button, Col } from "reactstrap"
import { withTranslation } from "react-i18next"

import { getClientApplicationByClientID } from "../../../../helpers/API"
import TabNavigation from "../../../Common/UI/TabNavigation"

import FormSelector from "./Forms/Selector"
import ApplicationEditor from "./Application"
import VisibilityEditor from "./Visibility"
import DefaultImagesEditor from "./DefaultImages"
import DefaultTextsEditor from "./DefaultTexts"
import AdministratorsEditor from "./Administrators"
import { adminRoot } from "../../../../constants/defaultValues"
import Offer from "./Offer/Controler"

// editing clients
const ClientEditor = ({ history, ID, type, clientURL, t }) => {
  const [activeTab, setActiveTab] = useState("settings")
  const [chosenApplication, setChosenApplication] = useState()
  const [name, setName] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true)
      if (ID) {
        const chosenApplicationAPI = await getClientApplicationByClientID(ID)

        setName(chosenApplicationAPI.Name)
        setChosenApplication(
          chosenApplicationAPI.Relations && chosenApplicationAPI.Relations[0]
            ? chosenApplicationAPI.Relations[0].Application_ID
            : null
        )
        setLoading(false)
      } else {
        setLoading(false)
      }
    }, 500)
  }, [ID, type])

  const onAppChange = async () => {
    setLoading(true)

    const chosenApplicationAPI = await getClientApplicationByClientID(ID)

    setChosenApplication(
      chosenApplicationAPI.Relations[0]
        ? chosenApplicationAPI.Relations[0].Application_ID
        : null
    )

    setLoading(false)
  }

  const navItems = [
    { name: "settings", label: "client-editor.settings" },
    { name: "application", label: "client-editor.application" },
    { name: "offer", label: "client-editor.offer" },
  ]

  if (chosenApplication) {
    navItems.push({
      name: "default-images",
      label: "client-editor.default-images",
    })
    navItems.push({
      name: "default-texts",
      label: "client-editor.default-texts",
    })
    navItems.push({
      name: "administrator",
      label: "client-editor.administrator",
    })
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row>
            <Col>
              {loading ? (
                <div className="loading" />
              ) : (
                <>
                  {ID && (
                    <Row>
                      <Col>
                        <h1>{t(name ? name : " ")}</h1>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center">
                        <Button
                          color="secondary"
                          onClick={() =>
                            history.push(`${adminRoot}manager/${clientURL}`)
                          }
                        >
                          {t("form.cancel")}
                        </Button>
                      </Col>
                    </Row>
                  )}

                  {ID && (
                    <TabNavigation
                      activeTab={activeTab}
                      setActive={val => setActiveTab(val)}
                      navList={navItems}
                    />
                  )}

                  {/* settings for client */}
                  {activeTab === "settings" && (
                    <>
                      <FormSelector ID={ID} type={type} history={history} />
                    </>
                  )}

                  {/* application settings */}
                  {activeTab === "application" && (
                    <>
                      <ApplicationEditor
                        appID={chosenApplication}
                        clientID={ID}
                        reload={onAppChange}
                      />
                      {chosenApplication && (
                        <VisibilityEditor
                          appID={chosenApplication}
                          clientID={ID}
                        />
                      )}
                    </>
                  )}

                  {/* default image settings */}
                  {activeTab === "default-images" && (
                    <DefaultImagesEditor
                      clientID={ID}
                      chosenApplication={chosenApplication}
                    />
                  )}

                  {/* default texts settings */}
                  {activeTab === "default-texts" && (
                    <DefaultTextsEditor
                      clientID={ID}
                      chosenApplication={chosenApplication}
                    />
                  )}

                  {/* offer generator */}
                  {activeTab === "offer" && <Offer clientID={ID} />}

                  {/* admin settings */}
                  {activeTab === "administrator" && (
                    <AdministratorsEditor
                      clientID={ID}
                      appID={chosenApplication}
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(ClientEditor)
