import React, { Component } from "react"
import { Button, Label } from "reactstrap"
import DropzoneComponent from "react-dropzone-component"
import { connect } from "react-redux"

import { storage } from "../../../helpers/Firebase"
import NotificationAlert from "../Notifications/NotificationAlert"
import { changeAvatarURL } from "../../../store/auth/actions"

const ReactDOMServer = require("react-dom/server")

const dropzoneComponentConfig = {
  postUrl: "no-url",
}
const dropzoneConfig = {
  thumbnailHeight: 160,
  maxFiles: 1,
  acceptedFiles: "image/jpeg,image/png,image/jpg",
  autoProcessQueue: false,
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div className="dz-preview dz-file-preview mb-3">
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img data-dz-thumbnail className="img-thumbnail border-0" />
            <i className="simple-icon-doc preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-progress">
            <span className="dz-upload" data-dz-uploadprogress />
          </div>
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#/" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />
      </a>
    </div>
  ),
}

class AvatarUploader extends Component {
  constructor(props) {
    super(props)
    this.dropzone = React.createRef()
    this.state = {}
  }

  clear() {
    this.myDropzone.removeAllFiles(true)
  }

  render() {
    const { currentUser, changeAvatarURLAction } = this.props
    const { stateFile } = this.state

    const ref = storage
      .ref()
      .child(
        `Users/DataBase/${currentUser.MetaData.FirebaseUID}/Profile/avatar`
      )

    return (
      <>
        <Label>Upload Image</Label>
        <DropzoneComponent
          ref={this.dropzone}
          config={dropzoneComponentConfig}
          djsConfig={dropzoneConfig}
          eventHandlers={{
            init: dropzone => {
              this.myDropzone = dropzone
            },
            addedfile: file => {
              if (!stateFile) this.setState({ stateFile: file })
            },
            removedfile: () => {
              this.setState({ stateFile: undefined })
            },
          }}
        />
        <Button
          color="primary"
          className="float-right mt-2"
          disabled={!stateFile}
          onClick={() => {
            ref.put(stateFile).then(
              async () => {
                NotificationAlert({
                  type: "success",
                  title: "Success",
                  expiration: 3000,
                })

                const newURL = await ref.getDownloadURL()
                changeAvatarURLAction(newURL)
              },
              () => {
                NotificationAlert({
                  type: "error",
                  title: "Error",
                  message: "Try again later",
                  expiration: 3000,
                })
              }
            )
          }}
        >
          Change Image
        </Button>
      </>
    )
  }
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser } = Auth

  return { currentUser }
}
export default connect(mapStateToProps, {
  changeAvatarURLAction: changeAvatarURL,
})(AvatarUploader)
