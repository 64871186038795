import React, { useState } from "react"
import { connect } from "react-redux"
import {
  Row,
  Card,
  CardBody,
  Label,
  Button,
  Col,
  Modal,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import SingleLightbox from "../../components/Common/UI/SingleLightbox"
import { updateUserCall } from "../../helpers/API"
import AvatarUploader from "../../components/Common/UI/AvatarUploader"

const updateCallKeys = [
  "ID",
  "FirebaseUID",
  "DisplayName",
  "EMail",
  "EMailVerified",
  "FirstName",
  "LastName",
  "PhoneNumber",
]

const Accuont = ({ currentUser }) => {
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [modalOpened, setModalOpened] = useState(false)

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row>
            <Col>
              <Row>
                <Col className="mb-4 col-left">
                  <Card>
                    <div className="position-absolute card-top-buttons">
                      <Button
                        outline
                        color="white"
                        onClick={() => setModalOpened(true)}
                        style={{ boxShadow: "none" }}
                      >
                        <i className="simple-icon-pencil" />
                      </Button>
                    </div>
                    <Button
                      outline
                      color="white"
                      className="icon-button"
                      onClick={() => setModalOpened(true)}
                      style={{
                        padding: 0,
                        border: "none",
                      }}
                    >
                      <SingleLightbox
                        thumb={currentUser.avatarURL}
                        large={currentUser.avatarURL}
                        className="card-img-top"
                      />
                    </Button>

                    <CardBody>
                      <p>{currentUser.MetaData.DisplayName}</p>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="mb-4 col-right">
                  <AvForm
                    model={currentUser.MetaData}
                    onChange={() => setSubmitDisabled(false)}
                    onSubmit={async (event, errors, values) => {
                      const mergedValues = {}
                      updateCallKeys.forEach(key => {
                        if (values[key]) mergedValues[key] = values[key]
                        else mergedValues[key] = currentUser.MetaData[key]
                      })
                      await updateUserCall(mergedValues)
                    }}
                  >
                    <Label>EMail</Label>
                    <AvField name="EMail" type="text" disabled />

                    <Label>Display Name</Label>
                    <AvField name="DisplayName" type="text" />

                    <Label>First Name</Label>
                    <AvField name="FirstName" type="text" />

                    <Label>Last Name</Label>
                    <AvField name="LastName" type="text" />

                    <Label>Phone Number</Label>
                    <AvField name="PhoneNumber" type="text" />

                    <Button
                      color="primary"
                      className="float-right"
                      disabled={submitDisabled}
                    >
                      Submit
                    </Button>
                  </AvForm>
                </Col>
              </Row>
            </Col>
          </Row>

          <Modal
            isOpen={modalOpened}
            toggle={() => {
              setModalOpened(!modalOpened)
            }}
          >
            <ModalBody>
              <AvatarUploader />
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser } = Auth

  return { currentUser }
}

export default connect(mapStateToProps, {})(Accuont)
