import React from "react"
import { Input, Label, Row } from "reactstrap"
import Switch from "rc-switch"
import "rc-switch/assets/index.css"

const WorkingHoursDay = ({ label, value, updateValue, disabled }) => {
  return (
    <Row className="col-12 p-1 align-items-center">
      <Label className="col-3 mb-0">{label}</Label>
      <div className="col-3 pr-1 pl-1">
        <Input
          type="time"
          value={
            value === "closed" || value === "unkown"
              ? "00:00"
              : value.split("-")[0]
          }
          onChange={e => {
            const newWorkingHoursOnDay = value.split("-")
            newWorkingHoursOnDay[0] = e.target.value
            updateValue(newWorkingHoursOnDay.join("-"))
          }}
          disabled={disabled || value === "closed" || value === "unkown"}
        />
      </div>

      <div className="col-3 pr-1 pl-1">
        <Input
          type="time"
          value={
            value === "closed" || value === "unkown"
              ? "00:00"
              : value.split("-")[1]
          }
          onChange={e => {
            const newWorkingHoursOnDay = value.split("-")
            newWorkingHoursOnDay[1] = e.target.value
            updateValue(newWorkingHoursOnDay.join("-"))
          }}
          disabled={disabled || value === "closed" || value === "unkown"}
        />
      </div>

      <div className="col-1" />

      <div className="justify-content-end col-1">
        <Label className="mb-0">Opened</Label>
      </div>

      <div className="col-1">
        <Switch
          className="custom-switch custom-switch-primary custom-switch-small"
          disabled={disabled || value === "unknown"}
          checked={value !== "closed"}
          onChange={e => {
            updateValue(e ? "00:00-00:00" : "closed")
          }}
        />
      </div>
    </Row>
  )
}

export default WorkingHoursDay
