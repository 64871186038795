import Module from "../pages/app/user/module/module"
import Gallery from "../pages/app/user/gallery/gallery"
import Administrators from "../pages/app/user/admin-settings/administrators"
import Roles from "../pages/app/user/admin-settings/roles"
import BusinessHomepage from "../pages/app/user/business-homepage/bussiness-homepage"

import { adminRoot } from "../constants/defaultValues"

export default [
  {
    path: `${adminRoot}module/:ID`,
    component: Module,
  },
  {
    path: `${adminRoot}gallery`,
    component: Gallery,
  },
  {
    path: `${adminRoot}administrators`,
    component: Administrators,
  },
  {
    path: `${adminRoot}roles`,
    component: Roles,
  },
  {
    path: `${adminRoot}business-homepage`,
    component: BusinessHomepage,
  },
]
