import React, { useEffect, useState } from "react"
import {
  AvField,
  AvForm,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation"
import { Label, Button } from "reactstrap"

import {
  getClientDefaultTexts,
  setClientDefaultTexts,
} from "../../../../helpers/API"
import { defaultTextsLabels } from "constants/defaultValues"

const DefaultTexts = ({ clientID, chosenApplication }) => {
  const [fields, setFields] = useState()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    setTimeout(async () => {
      const defaultTextsAPI = await getClientDefaultTexts(clientID)

      const Fields = []
      let i = 0

      for (i; i < 20; i += 1) {
        Fields.push({
          ID: `default_texts_field_${i}`,
          FieldType_ID: "22489062-0529-4533-9051-D70D9EF28C01",
          FieldInputType_ID: "5CC4CC85-77E8-4758-B982-BA9E3F91572C",
          Type: "Text",
          InputType: "Text",
          Order: i,
          Name: `Text${i}`,
          Label:
            defaultTextsLabels[chosenApplication] &&
            defaultTextsLabels[chosenApplication][i]
              ? defaultTextsLabels[chosenApplication][i]
              : `Text ${i}`,
          Prompt: `Enter Text ${i}`,
          Format: "",
          Required: false,
          ReadOnly: false,
          Visible: true,
          Description: "",
        })
      }

      setData(defaultTextsAPI)
      setFields(Fields)
      setLoading(false)
    }, 100)
  }, [])

  const onSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      setClientDefaultTexts(values, clientID)
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <AvForm
          className="av-tooltip tooltip-label-right p-4"
          onSubmit={(event, errors, values) => onSubmit(event, errors, values)}
          model={data}
        >
          {fields.map(field => (
            <AvGroup className="error-t-negative error-l-150" key={field.ID}>
              <Label>{field.Label}</Label>
              <AvField name={field.Name} type="text" />
              <AvFeedback>{field.Prompt}</AvFeedback>
            </AvGroup>
          ))}
          <Button color="primary float-right">Submit</Button>
        </AvForm>
      )}
    </>
  )
}

export default DefaultTexts
