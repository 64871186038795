import {
  MENU_ITEM_FORM_GET,
  MENU_ITEM_FORM_GET_SUCCESS,
  INITIAL_MENU_ITEM_FORM_GET_SUCCESS,
  MENU_ITEM_FORM_GET_ERROR,
  RELOAD_ENTITIES,
  RELOAD_ENTITIES_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  form: null,
  loading: false,
  fieldTypeOptions: [],
  fieldGroupTypeOptions: [],
  entitiesOptions: [],
  globalEntitiesOptions: [],
  requestMethodOptions: [],
  buttonTypeOptions: [],
  menuItemOptions: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MENU_ITEM_FORM_GET:
      return { ...state, loading: true }

    case INITIAL_MENU_ITEM_FORM_GET_SUCCESS:
      return {
        ...state,
        form: { ...action.payload.form },
        fieldTypeOptions: [...action.payload.fieldTypeOptions],
        fieldGroupTypeOptions: [...action.payload.fieldGroupTypeOptions],
        entitiesOptions: [...action.payload.entitiesOptions],
        globalEntitiesOptions: [...action.payload.globalEntitiesOptions],
        requestMethodOptions: [...action.payload.requestMethodOptions],
        buttonTypeOptions: [...action.payload.buttonTypeOptions],
        menuItemOptions: [...action.payload.menuItemOptions],
        loading: false,
      }

    case MENU_ITEM_FORM_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        form: { ...action.payload },
      }
    }

    case MENU_ITEM_FORM_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case RELOAD_ENTITIES:
      return {
        ...state,
        loading: true,
      }

    case RELOAD_ENTITIES_SUCCESS:
      return { ...state, loading: false, entitiesOptions: [...action.payload] }

    default:
      return { ...state }
  }
}
