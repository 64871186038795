import React, { useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"

import { ThemeColors } from "../../../../../../../../helpers/ThemeColors"

const colors = ThemeColors()

const MenuEditorFieldChoicesDetails = ({ field, editField }) => {
  const [modalOpened, setModalOpened] = useState(false)
  const [valuesToQuickAdd, setValuesToQuickAdd] = useState([])

  const editOption = (index, name, value) => {
    const newOptions = [...field.SelectionOptions]
    newOptions[index][name] = value
    editField("SelectionOptions", newOptions)
  }

  const deleteOption = index => {
    const newOptions = [...field.SelectionOptions]
    newOptions.splice(index, 1)
    editField("SelectionOptions", newOptions)
  }

  const addOption = () => {
    const newOptions = [...field.SelectionOptions]
    newOptions.push({
      Label: "",
      Type: "Option",
      ID: `option_id_${Math.floor(Math.random() * 1000000)}-${Math.floor(
        Math.random() * 10000
      )}-${Math.floor(Math.random() * 1000000)}`,
    })
    editField("SelectionOptions", newOptions)
  }

  const addGroup = () => {
    const newOptions = [...field.SelectionOptions]
    newOptions.push({
      Label: "",
      Type: "Group",
      ID: `option_id_${Math.floor(Math.random() * 1000000)}-${Math.floor(
        Math.random() * 10000
      )}-${Math.floor(Math.random() * 1000000)}`,
    })
    editField("SelectionOptions", newOptions)
  }

  return (
    <>
      <Modal isOpen={modalOpened} toggle={() => setModalOpened(!modalOpened)}>
        <ModalHeader>Quick add</ModalHeader>
        <ModalBody>
          <Input
            type="text"
            className="list-item-heading truncate mb-2"
            placeholder="Values seperated by a coma"
            value={valuesToQuickAdd.join(",")}
            onChange={e => setValuesToQuickAdd(e.target.value.split(","))}
          />
          <Button
            color="primary"
            size="lg"
            className="top-right-button mr-1"
            onClick={() => {
              let index = field.SelectionOptions.length
              valuesToQuickAdd.forEach(valueToQuickAdd => {
                addOption()
                editOption(index, "Label", valueToQuickAdd)
                index += 1
              })
            }}
          >
            Submit
          </Button>
        </ModalBody>
      </Modal>

      <p className="mt-3">Items:</p>
      <ReactSortable
        list={field.SelectionOptions}
        setList={fieldsList => {
          // ReactSortable would sometimes push an undefined object
          // to the end of the list
          // To prevent this object from crashing the applicaton,
          // a check is done, and those objects are removed
          const checkedFieldsList = [...fieldsList]
          while (checkedFieldsList.indexOf(undefined) !== -1) {
            checkedFieldsList.splice(checkedFieldsList.indexOf(undefined), 1)
          }
          editField("SelectionOptions", checkedFieldsList)
        }}
        className="list-unstyled "
      >
        {field.SelectionOptions.map((option, optionIndex) => (
          <div
            key={`${field.ID}_option_${option.ID}`}
            className={`p-2 d-flex flex-lg-row justify-content-between min-width-zero ${
              option.Type === "Group" && "background-grey"
            }`}
          >
            <div className="align-self-center pr-4 w-10">
              <button
                className="text-only-button"
                type="button"
                id={`move_${field.ID}_${option.ID}`}
                onClick={() => {}}
              >
                <FontAwesomeIcon
                  icon={faGripVertical}
                  color={colors.separatorColor}
                />
              </button>
            </div>

            {option.Type === "Option" && (
              <>
                <div className="w-80 w-sm-80 mr-2">
                  <Input
                    type="text"
                    className="list-item-heading truncate"
                    placeholder="Label"
                    value={option.Label}
                    onChange={e =>
                      editOption(optionIndex, "Label", e.target.value)
                    }
                  />
                </div>

                {/* <div className="w-40 w-sm-40 mr-2">
                  <Input
                    type="text"
                    className="list-item-heading truncate"
                    placeholder="Value"
                    value={option.Value}
                    onChange={(e) =>
                      editOption(optionIndex, 'Value', e.target.value)
                    }
                  />
                </div> */}
              </>
            )}

            {option.Type === "Group" && (
              <>
                <div className="w-80 w-sm-80 mr-2">
                  <Input
                    type="text"
                    className="list-item-heading truncate"
                    placeholder="Label"
                    value={option.Label}
                    onChange={e =>
                      editOption(optionIndex, "Label", e.target.value)
                    }
                  />
                </div>
              </>
            )}

            <div className="align-self-center pr-4 w-10">
              <button
                className="text-only-button"
                type="button"
                id={`delete_${field.ID}_${option.ID}`}
                onClick={() => deleteOption(optionIndex)}
              >
                <i className="simple-icon-close" />
              </button>
            </div>
          </div>
        ))}
      </ReactSortable>
      <div className="p-2 d-flex flex-lg-row justify-content-center min-width-zero">
        {field.Type === "Dropdown" ||
        field.Type === "Multiple Choice Dropdown" ? (
          <>
            <button
              className="text-only-button link-button mr-5"
              type="button"
              onClick={() => addGroup()}
            >
              Add Group
            </button>
            <button
              className="text-only-button link-button ml-5 mr-5"
              type="button"
              onClick={() => addOption()}
            >
              Add Option
            </button>

            <button
              className="text-only-button link-button ml-5"
              type="button"
              onClick={() => setModalOpened(true)}
            >
              Quick add
            </button>
          </>
        ) : (
          <>
            <button
              className="text-only-button link-button mr-5"
              type="button"
              onClick={() => addOption()}
            >
              Add Option
            </button>

            <button
              className="text-only-button link-button ml-5"
              type="button"
              onClick={() => setModalOpened(true)}
            >
              Quick add
            </button>
          </>
        )}
      </div>
    </>
  )
}

export default MenuEditorFieldChoicesDetails
