import React, { useEffect, useState } from "react"
import { Button, Input, Row } from "reactstrap"

import { getEntityFieldsByEntityID } from "../../../../../../../../helpers/API"

const SelectionLabelCreator = ({ entityID, label, updateValue, close }) => {
  const [fields, setFields] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(async () => {
      const fieldsAPI = await getEntityFieldsByEntityID(entityID)
      setFields(fieldsAPI.Relations)
      setLoading(false)
    }, 100)
  }, [entityID])

  const addFieldToLabel = nameToAdd => {
    const newLabel = `${label || ""}{{${nameToAdd}}}`
    updateValue(newLabel)
  }

  return (
    <div>
      {loading ? (
        <div className="loading" />
      ) : (
        <div>
          <Row className="ml-1">
            {fields.map(field => (
              <Button
                outline
                size="btn-sm mb-1 mr-1 w-initial"
                onClick={() => addFieldToLabel(field.FieldName)}
                color="primary"
                className="default"
                key={`button_label_${field.Field_ID}`}
              >
                {field.FieldName}
              </Button>
            ))}
          </Row>
          <Input
            type="text"
            className="list-item-heading truncate"
            name={label}
            placeholder="Selection Label"
            value={label}
            onChange={e => updateValue(e.target.value)}
          />
          <Button
            color="secondary"
            className="mt-2 float-right"
            onClick={() => close()}
          >
            Close
          </Button>
        </div>
      )}
    </div>
  )
}

export default SelectionLabelCreator
