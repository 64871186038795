import React, { useState, useEffect } from "react"
import { Row, Label, Button, Col } from "reactstrap"
import { withRouter } from "react-router-dom"
import {
  AvForm,
  AvGroup,
  AvField,
  AvFeedback,
} from "availity-reactstrap-validation"
import Switch from "rc-switch"
import "rc-switch/assets/index.css"
import { ReactSortable } from "react-sortablejs"
import { withTranslation } from "react-i18next"

import {
  getQuestionCollectionByQuestionCollectionID,
  updateQuestionCollection,
} from "../../../../helpers/API"
import QuestionRow from "../../../../components/Admin/CMSCreator/QuestionCollections/QuestionRow"
import NotificationAlert from "../../../../components/Common/Notifications/NotificationAlert"
import { adminRoot } from "../../../../constants/defaultValues"

const QuestionCollections = ({ location, history, t }) => {
  const [defaultValues, setDefaultValues] = useState([])
  const [loading, setLoading] = useState(true)
  const [appID, setAppID] = useState()

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true)

      const params = new URLSearchParams(location.search)

      const applicationID = params.get("appID")
      const ID = params.get("ID")

      setAppID(applicationID)

      if (ID) {
        const questionCollectionData =
          await getQuestionCollectionByQuestionCollectionID(ID)

        setDefaultValues(questionCollectionData)
        setLoading(false)
      } else {
        setDefaultValues({
          Application_ID: applicationID,
          Relations: [],
          Active: false,
        })
        setLoading(false)
      }
    }, 500)
  }, [location.search])

  const onSubmit = async (event, errors, values) => {
    if (errors.length === 0) {
      const mergedValues = { ...defaultValues, ...values }

      mergedValues.Relations.forEach((question, index) => {
        if (question.Question_ID.includes("question_")) {
          mergedValues.Relations[index].Question_ID = undefined
        }
        mergedValues.Relations[index].QuestionOrder = index
        mergedValues.Relations[index].chosen = undefined
        mergedValues.Relations[index].selected = undefined
      })

      const response = await updateQuestionCollection(mergedValues)

      if (response.ID) {
        setDefaultValues({ ...response })
        history.push(
          `${adminRoot}question-collection?appID=${appID}&ID=${response.ID}`
        )
      }
    } else {
      let errorMessage = "Missing: "
      errors.forEach(error => {
        errorMessage += error
        errorMessage += ", "
      })

      NotificationAlert({
        type: "warning",
        title: "",
        message: errorMessage,
        expiration: 3000,
      })
    }
  }

  const addOption = () => {
    const newOption = {
      QuestionText: "",
      QuestionActive: false,
      Question_ID: `question_${Math.round(Math.random() * 10000)}-${Math.round(
        Math.random() * 10000
      )}-${Math.round(Math.random() * 10000)}`,
    }

    const newQuestions = [...defaultValues.Relations]
    newQuestions.push(newOption)
    setDefaultValues({ ...defaultValues, Relations: [...newQuestions] })
  }

  const deleteOption = index => {
    const newQuestions = [...defaultValues.Relations]
    newQuestions.splice(index, 1)
    setDefaultValues({ ...defaultValues, Relations: [...newQuestions] })
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row className="app-row">
            <Col>
              {loading ? (
                <div className="loading" />
              ) : (
                <>
                  <h1>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {defaultValues && defaultValues.ID
                      ? t(
                          defaultValues.DisplayName
                            ? defaultValues.DisplayName
                            : " "
                        )
                      : t("menu.application.question-collections")}
                  </h1>

                  <AvForm
                    className="av-tooltip tooltip-label-right"
                    model={defaultValues}
                    onSubmit={(event, errors, values) =>
                      onSubmit(event, errors, values)
                    }
                  >
                    <Row className="justify-content-between mb-2 p-3">
                      <Button
                        color="secondary"
                        size="m col-2"
                        onClick={() => {
                          history.push(
                            `${adminRoot}questionCollections?appID=${appID}`
                          )
                        }}
                      >
                        {t("form.cancel")}
                      </Button>

                      <Button
                        color="primary col-2"
                        size="m"
                        className="float-right"
                      >
                        {t("form.submit")}{" "}
                      </Button>
                    </Row>

                    <AvGroup className="error-t-negative error-l-150">
                      <Label>Display Name</Label>
                      <AvField name="DisplayName" type="text" required />
                      <AvFeedback>Display Name is required</AvFeedback>
                    </AvGroup>

                    <AvGroup className="error-t-negative error-l-150">
                      <Label>Description</Label>
                      <AvField name="Description" type="text" required />
                      <AvFeedback>Description is required</AvFeedback>
                    </AvGroup>

                    <AvGroup className="justify-content-between d-flex">
                      <Label>Active</Label>
                      <Switch
                        className="custom-switch custom-switch-primary custom-switch-small mb-2"
                        checked={defaultValues.Active}
                        onChange={e =>
                          setDefaultValues({ ...defaultValues, Active: e })
                        }
                      />
                    </AvGroup>

                    <Label>Questions</Label>
                    <ReactSortable
                      list={defaultValues.Relations}
                      setList={questionsList => {
                        // ReactSortable would sometimes push an undefined object
                        // to the end of the list
                        // To prevent this object from crashing the applicaton,
                        // a check is done, and those objects are removed
                        const checkedQuestionsList = [...questionsList]
                        while (checkedQuestionsList.indexOf(undefined) !== -1) {
                          checkedQuestionsList.splice(
                            checkedQuestionsList.indexOf(undefined),
                            1
                          )
                        }
                        setDefaultValues({
                          ...defaultValues,
                          Relations: [...checkedQuestionsList],
                        })
                      }}
                      className="list-unstyled"
                    >
                      {defaultValues.Relations.map((question, index) => (
                        <QuestionRow
                          key={`question_row_${question.Question_ID}`}
                          item={question}
                          updateValue={val => {
                            const newQuestions = [...defaultValues.Relations]
                            newQuestions[index] = val
                            setDefaultValues({
                              ...defaultValues,
                              Relations: [...newQuestions],
                            })
                          }}
                          deleteOption={() => deleteOption(index)}
                        />
                      ))}
                    </ReactSortable>

                    <div className="p-2 d-flex flex-lg-row justify-content-center min-width-zero">
                      <button
                        className="text-only-button link-button"
                        type="button"
                        onClick={() => addOption()}
                      >
                        Add Option
                      </button>
                    </div>

                    {/* <Label>{JSON.stringify(defaultValues)}</Label> */}
                  </AvForm>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(withRouter(QuestionCollections))
