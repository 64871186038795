import React from "react"
import { Label } from "reactstrap"
import { AvGroup, AvField, AvFeedback } from "availity-reactstrap-validation"
import { validateOIBAndMB } from "../../../../helpers/Utils"

const OIB = ({ item, required, disabled }) => {
  return (
    <AvGroup className="error-t-negative error-l-150">
      <Label>{item.Label}</Label>
      <AvField
        name={item.Name}
        type="text"
        disabled={disabled}
        validate={{
          async: val => validateOIBAndMB(val, !item.Required),
          required: { value: required },
        }}
      />
      <AvFeedback>{item.Prompt}</AvFeedback>
    </AvGroup>
  )
}

export default OIB
