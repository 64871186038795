import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Form from "../../../Form/Components/Form"
import { getCMSApplicationEditingVersion } from "../../../../store/cms/actions"
import { adminRoot } from "../../../../constants/defaultValues"

const MenuItemSettings = ({
  location,
  history,
  form,
  appID,
  parent,
  isLink,
  ID,
  getCMSApplicationEditingVersionAction,
}) => {
  const onSubmit = newID => {
    getCMSApplicationEditingVersionAction(appID)

    history.push(
      `${location.pathname.split("menuItemEditor")[0]}menuItemEditor${
        parent ? "/" : "/edit/"
      }${newID}?appID=${appID}${parent ? `&parent=${parent}` : ""}&ID=${newID}`
    )
  }

  const onDelete = () => {
    getCMSApplicationEditingVersionAction(appID)
    history.push(`${adminRoot}applicationEditor?appID=${appID}`)
  }

  return (
    <Form
      form={form}
      ID={ID}
      onSubmit={onSubmit}
      onDelete={onDelete}
      propValues={
        isLink
          ? { ParentMenuItem_ID: parent, Application_ID: appID }
          : {
              ParentMenuItem_ID: parent,
              Application_ID: appID,
              LinkType: null,
              Form_ID: null,
              EnableCitySelection: false,
            }
      }
      rules={{ create: "True", update: "True", delete: "True" }}
      privateAPI
    />
  )
}

const mapStateToProps = () => {
  return {}
}
export default connect(mapStateToProps, {
  getCMSApplicationEditingVersionAction: getCMSApplicationEditingVersion,
})(withRouter(MenuItemSettings))
