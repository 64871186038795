import React, { Component } from "react"
import DropzoneComponent from "react-dropzone-component"
import { connect } from "react-redux"
import { Button } from "reactstrap"

import { storage } from "../../helpers/Firebase"
import { createFile, deleteFilePermanently } from "../../helpers/API"
import NotificationAlert from "../Common/Notifications/NotificationAlert"

const ReactDOMServer = require("react-dom/server")

const dropzoneComponentConfig = {
  postUrl: "no-url",
}

const dropzoneConfig = {
  thumbnailHeight: 160,
  uploadMultiple: true,
  autoProcessQueue: false,
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div className="dz-preview dz-file-preview mb-3">
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img data-dz-thumbnail className="img-thumbnail border-0" />
            <i className="simple-icon-doc preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-progress">
            <span className="dz-upload" data-dz-uploadprogress />
          </div>
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#/" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />
      </a>
    </div>
  ),
}

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileToID: {},
    }
  }

  clear() {
    this.dropzone.removeAllFiles(true)
  }

  render() {
    const { clientID, cancel } = this.props

    const ref = storage.ref().child(`Clients/DataBase/${clientID}/ClientData/`)

    return (
      <>
        <DropzoneComponent
          config={dropzoneComponentConfig}
          djsConfig={dropzoneConfig}
          isMulti
          eventHandlers={{
            init: dropzone => {
              this.dropzone = dropzone
            },
            addedfile: file => {
              const { fileToID } = this.state

              const timeStamp = Date.now()

              const imageRef = ref.child(`${timeStamp}`)

              imageRef.put(file).then(
                async () => {
                  const downloadURL = await imageRef.getDownloadURL()

                  const newFile = await createFile(
                    clientID,
                    file.name,
                    downloadURL,
                    `Clients/DataBase/${clientID}/ClientData/${timeStamp}`,
                    file.type
                  )
                  if (!newFile.ErrorMessage) {
                    const newFileToID = { ...fileToID }
                    newFileToID[file.upload.uuid] = newFile
                    this.setState({ fileToID: newFileToID })
                    this.dropzone.options.success.call(this.dropzone, file)
                    this.dropzone.options.complete.call(this.dropzone, file)
                  } else {
                    NotificationAlert({ type: "error", title: "Failed" })
                  }
                },
                () => {
                  NotificationAlert({ type: "error", title: "Failed" })
                }
              )
            },
            removedfile: async file => {
              const { fileToID } = this.state

              const fileToRemove = fileToID[file.upload.uuid]

              const deleteFileCall = await deleteFilePermanently(
                fileToRemove.ID
              )

              storage
                .refFromURL(fileToRemove.URL)
                .delete()
                .then(
                  () => {
                    NotificationAlert({ type: "success", title: "Success" })
                  },
                  () => {
                    NotificationAlert({ type: "error", title: "Failed" })
                  }
                )

              if (!deleteFileCall.ErrorMessage)
                NotificationAlert({
                  type: "success",
                  title: "Success",
                })
            },
          }}
        />
        <Button onClick={() => cancel()} color="secondary mt-2">
          Cancel
        </Button>
      </>
    )
  }
}

const mapStateToProps = ({ CMS }) => {
  const { clientID } = CMS

  return { clientID }
}
export default connect(mapStateToProps, {})(Gallery)
