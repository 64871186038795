import React, { useState, useEffect } from "react"
import moment from "moment"
import { connect } from "react-redux"
import { pdf, PDFViewer } from "@react-pdf/renderer"
import { withTranslation } from "react-i18next"

import logoSm from "../../../../../assets/images/logo/logo-dark.png"
import { getNewOfferNumber, createOffer } from "../../../../../helpers/API"
import { companyData } from "../../../../../constants/defaultValues"
import Offer from "./Offer"
import { styles } from "./Styling"
import EditingTable from "./EditingTable"
import { uploadOfferPDF } from "../../../../../helpers/Utils"

const NewOffer = ({
  clientID,
  clientData,
  currentUser,
  afterOfferCreate,
  t,
}) => {
  const [data] = useState(clientData)
  const [loading, setLoading] = useState(true)
  const [offer, setOffer] = useState()
  const [offerNumber, setOfferNumber] = useState("xxxx")

  useEffect(() => {
    setLoading(true)

    const locale = moment()
    locale.locale("hr")

    setOffer({
      id: `${Math.round(Math.random() * 100000000)}-${Math.round(
        Math.random() * 1000000
      )}-${Math.round(Math.random() * 100000000)}`,
      logo: logoSm,
      title: "PONUDA",
      footerText:
        "U očekivanju pozitivnog odgovora zahvaljujemo Vam se na povjerenju i veselimo daljnjoj suradnji.",
      receiver: {
        company: data.Name,
        address: `${data.Address} ${data.HouseNumber}${data.HouseNumberAddition}`,
        city: `${data.ZIPCode} ${data.MunicipalityOrCity}`,
        oib: data.PersonalIdentificationNumber,
      },
      sender: {
        name: `${currentUser.MetaData.FirstName} ${currentUser.MetaData.LastName}`,
        company: companyData.Name,
        address: `${companyData.Address}`,
        city: `${companyData.ZIPCode} ${companyData.City}`,
        oib: companyData.OIB,
        iban: companyData.IBAN,
      },
      offer: {
        offer_no: offerNumber,
        trans_date: locale.utc().format("D. M. YYYY"),
        city: companyData.City,
        referenceNumber: offerNumber,
      },
      items: [
        {
          sno: "85584627-126437-65473920",
          desc: "Mjesec dana CMS-a",
          qty: 1,
          discount: 10,
          rate: 500.0,
        },
        {
          sno: "17984627-125537-65473920",
          desc: "3 mjeseca CMS-a",
          qty: 1,
          discount: 20,
          rate: 1500.0,
        },
        {
          sno: "63284627-123637-65473920",
          desc: "Godinu dana CMS-a",
          qty: 1,
          discount: 50,
          rate: 3000.0,
        },
      ],
    })
    setLoading(false)
  }, [data])

  const callback = async (pdfURL, offerNum, newOffer) => {
    const createdOffer = await createOffer(
      clientID,
      data.PersonalIdentificationNumber,
      data.Name,
      offerNum,
      Number.parseFloat(
        newOffer.items
          .map(item => item.qty * item.rate * (1 - item.discount / 100))
          .reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          ) * 1.25
      ).toFixed(2),
      pdfURL,
      newOffer
    )

    afterOfferCreate(createdOffer.ID)
  }

  const saveOffer = async () => {
    const offerNumberAPI = await getNewOfferNumber()
    const newOffer = {
      ...offer,
      offer: {
        ...offer.offer,
        offer_no: offerNumberAPI,
        referenceNumber: offerNumberAPI,
      },
    }
    setOffer(newOffer)
    setOfferNumber(offerNumberAPI)

    const blob = await pdf(<Offer offer={newOffer} styles={styles} />).toBlob()
    const file = new File([blob], `${offerNumberAPI}.pdf`, {
      type: "application/pdf",
    })

    uploadOfferPDF(clientID, file, offerNumberAPI, url =>
      callback(url, offerNumberAPI, newOffer)
    )
  }

  return (
    <>
      {loading || !offer ? (
        <div className="loading" />
      ) : (
        <div className="p-4 mt-2">
          <EditingTable setOffer={setOffer} offer={offer} />
          <div className="d-flex justify-content-center mb-2">
            <button
              className="btn btn-primary btn-xs "
              onClick={() =>
                setOffer({
                  ...offer,
                  items: [
                    ...offer.items,
                    {
                      sno: `${Math.round(
                        Math.random() * 100000000
                      )}-${Math.round(Math.random() * 1000000)}-${Math.round(
                        Math.random() * 100000000
                      )}`,
                      desc: "",
                      qty: 1,
                      discount: 0,
                      rate: 100.0,
                    },
                  ],
                })
              }
            >
              {t("offer.add")}
            </button>
          </div>
          <PDFViewer
            style={{ height: "100vh", width: "100%" }}
            showToolbar={false}
          >
            <Offer offer={offer} styles={styles} />
          </PDFViewer>
          <div className="d-flex justify-content-center mt-2">
            <button
              className="btn btn-primary btn-xs "
              onClick={() => saveOffer()}
            >
              {t("offer.create")}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser } = Auth

  return {
    currentUser,
  }
}
export default withTranslation()(connect(mapStateToProps, {})(NewOffer))
