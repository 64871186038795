import React, { useEffect, useState } from "react"
import { CustomInput, FormGroup, Label } from "reactstrap"

import { getAllClientsByCategory, getAllRegions } from "../../../helpers/API"
import { compareCroatianString } from "../../../helpers/Utils"
import CheckboxButtons from "../Fields/FieldTypes/CheckboxButtons"
import { clientTypeCategoryIDs } from "constants/defaultValues"

const CitySelection = ({ selected, setSelected }) => {
  const [cities, setCities] = useState([])
  const [regions, setRegions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(async () => {
      const data = await getAllClientsByCategory(
        clientTypeCategoryIDs["Local government"]
      )

      const regionsAPI = await getAllRegions()

      regionsAPI.sort((a, b) => compareCroatianString(a.Name, b.Name))

      const sortedData = data.Relations

      sortedData.sort((a, b) => compareCroatianString(a.Name, b.Name))

      setCities(sortedData)
      setRegions(regionsAPI)
      setLoading(false)
    }, 100)
  }, [])

  const selected_full = loading
    ? []
    : selected.map(selectedCity => cities.find(x => x.ID === selectedCity))

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <div className="p-2">
          <CustomInput
            type="checkbox"
            id="select-all-button"
            label={
              cities.length === selected_full.length
                ? "Remove All"
                : "Select All"
            }
            checked={cities.length === selected_full.length}
            onChange={() => {
              if (cities.length === selected_full.length) {
                const newList = []
                setSelected(newList)
              } else {
                setSelected([...cities.map(x => x.ID)])
              }
            }}
          />

          <Label className="mt-3">Regions</Label>
          <FormGroup check style={{ padding: "0" }} className="mb-3 col-12">
            {regions.map(region => {
              const list = region.List.split(", ")
              const checked = list.every(x => selected.includes(x))
              return (
                <CustomInput
                  type="checkbox"
                  key={`${region.ID}_checkbox_region`}
                  id={`${region.ID}_checkbox_region`}
                  label={region.Name}
                  checked={checked}
                  onChange={() => {
                    if (checked) {
                      setSelected(
                        selected_full
                          .filter(x => !list.includes(x.ID))
                          .map(y => y.ID)
                      )
                    } else {
                      const newSelected = [...selected]
                      list.forEach(city => {
                        if (!selected.includes(city)) {
                          newSelected.push(city)
                        }
                      })
                      setSelected(newSelected)
                    }
                  }}
                  className="col-4 mr-0"
                  inline
                />
              )
            })}
          </FormGroup>

          <Label>Cities</Label>
          <CheckboxButtons
            item={{ Label: "", Options: cities, ID: "city-selection" }}
            value={selected_full}
            updateValue={val => setSelected(val.map(x => x.ID))}
          />
        </div>
      )}
    </>
  )
}

export default CitySelection
