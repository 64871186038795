import { all, call, fork, put, takeEvery } from "redux-saga/effects"

import {
  CMS_GET,
  CMS_GET_APPLICATION_EDITING_VERSION,
  CMS_GET_ACCOUNT_EDITOR_VERSION,
  SET_CLIENT_ID,
} from "./actionTypes"

import { getCMSSuccess, getCMSError } from "./actions"
import {
  makeUserMenu,
  makeAdminMenu,
  makeAdminApplicationEditingMenu,
  getCurrentUser,
  getSystemSettings,
  makeAccountEditorUserMenu,
  setStyle,
} from "../../helpers/Utils"
import {
  getApplicationsList,
  getApplicationMenuByApplicationID,
  getClientMenuItemStatuses,
} from "../../helpers/API"
import { database } from "../../helpers/Firebase"
import { adminRoot, Environment } from "../../constants/defaultValues"

import store from "../index"

const getCMSRequest = async () => {
  return await new Promise(success => {
    setTimeout(async () => {
      const currentUser = getCurrentUser()

      // if user is admin, give CMS for admins
      if (currentUser.role < 4) {
        let systemSettings = []
        if (currentUser.role === 0) {
          systemSettings = getSystemSettings()
        }

        const style = "default"
        setStyle(style)

        const applicationsList = await getApplicationsList()

        const menuItems = makeAdminMenu(applicationsList, systemSettings)
        success({
          menuItems,
          systemSettings,
          style,
          metaData: { Name: "CityX" },
        })
      } else if (store.getState().CMS.clientID) {
        const clientID = store.getState().CMS.clientID
        // if user is not admin, get his CMS from Firebase
        database
          .ref(`${Environment}Clients/DataBase/${clientID}`)
          .once("value", async clientSnapshot => {
            const client = clientSnapshot.val()

            // client can have multiple apps in the future
            // now, the first app is chosen
            let firstCMS
            try {
              firstCMS =
                client.Applications.DataBase[
                  Object.keys(client.Applications.DataBase)[0]
                ]
            } catch (e) {
              window.location.href = "/401?reason=no_application"
            }

            let style = firstCMS.MetaData.PrimaryJSON
            const altStyle = firstCMS.MetaData.SecondaryJSON
            style = setStyle(style, altStyle)

            database
              .ref(
                `${Environment}Applications/DataBase/${firstCMS.MetaData.ID}/LatestBuild`
              )
              .once("value", async snapshot => {
                const newestVersion = snapshot.val()

                const clientSpecificInformation =
                  await getClientMenuItemStatuses(
                    clientID,
                    firstCMS.MetaData.ID
                  )

                const menuItems = makeUserMenu(
                  JSON.parse(firstCMS.Structure),
                  clientSpecificInformation,
                  clientID
                )

                success({
                  cms: firstCMS,
                  menuItems,
                  newVersion: `${newestVersion}` !== `${firstCMS.BuildNumber}`,
                  clientID,
                  appID: firstCMS.MetaData.ID,
                  style: style,
                  metaData: client.MetaData,
                })
              })
          })
      } else {
        const menuItems = [
          {
            id: "menu.home",
            label: "menu.home",
            icon: "simple-icon-home",
            to: adminRoot,
          },
        ]
        success({ menuItems })
      }
    }, 1000)
  })
    .then(response => response)
    .catch(error => error)
}

const getApplicationEditingVersionRequest = async chosenApplicationID => {
  return await new Promise(success => {
    setTimeout(async () => {
      const currentUser = getCurrentUser()

      // check if user is allowed to edit CMS
      if (currentUser.role === 0) {
        // get items for application
        const applicationMenuItems = await getApplicationMenuByApplicationID(
          chosenApplicationID
        )
        // create menu items from applicationMenuItems
        const menuItems = await makeAdminApplicationEditingMenu(
          applicationMenuItems
        )

        const style = "default"
        setStyle(style)

        success({
          menuItems,
          appID: chosenApplicationID,
          style,
          metaData: applicationMenuItems,
        })
      } else {
        throw new Error("User not authorized for this action")
      }
    }, 1000)
  })
    .then(response => response)
    .catch(error => error)
}

const getClientAccountEditorVersionRequest = async clientID => {
  return await new Promise(success => {
    setTimeout(async () => {
      const currentUser = getCurrentUser()
      // check if user is allowed to get this menu
      if (currentUser.role < 4) {
        database
          .ref(`${Environment}Clients/DataBase/${clientID}`)
          .once("value", async clientSnapshot => {
            const client = clientSnapshot.val()

            // client can have multiple apps in the future
            // now, the first app is chosen
            let firstCMS
            try {
              firstCMS =
                client.Applications.DataBase[
                  Object.keys(client.Applications.DataBase)[0]
                ]
            } catch (e) {
              history.push("/500")
            }

            let style = firstCMS.MetaData.PrimaryJSON
            const altStyle = firstCMS.MetaData.SecondaryJSON
            style = setStyle(style, altStyle)

            database
              .ref(
                `${Environment}Applications/DataBase/${firstCMS.MetaData.ID}/LatestBuild`
              )
              .once("value", snapshot => {
                const newestVersion = snapshot.val()

                const menuItems = makeAccountEditorUserMenu(
                  JSON.parse(firstCMS.Structure),
                  clientID
                )

                success({
                  cms: firstCMS,
                  menuItems,
                  newVersion: `${newestVersion}` !== `${firstCMS.BuildNumber}`,
                  clientID,
                  appID: firstCMS.MetaData.ID,
                  style,
                  metaData: client.MetaData,
                })
              })
          })
      } else {
        const menuItems = [
          {
            id: "menu.home",
            label: "menu.home",
            icon: "simple-icon-home",
            to: adminRoot,
          },
        ]
        success({ menuItems })
      }
    }, 1000)
  })
    .then(response => response)
    .catch(error => error)
}

const setClientIDAsync = async clientID => {
  const currentUser = getCurrentUser()

  database
    .ref(
      `${Environment}Users/DataBase/${currentUser.MetaData.FirebaseUID}/SelectedClient`
    )
    .set(clientID)
}

function* getCMSItems() {
  try {
    const response = yield call(getCMSRequest)
    const {
      cms,
      metaData,
      menuItems,
      systemSettings,
      newVersion,
      clientID,
      appID,
      style,
    } = response
    yield put(
      getCMSSuccess(
        cms,
        metaData,
        menuItems,
        systemSettings,
        newVersion,
        clientID,
        appID,
        style
      )
    )
  } catch (error) {
    yield put(getCMSError(error))
  }
}

function* getApplicationEditingVersionItems({ payload }) {
  try {
    const chosenApplicationID = payload
    const response = yield call(
      getApplicationEditingVersionRequest,
      chosenApplicationID
    )
    const { cms, menuItems, systemSettings, appID, style, metaData } = response
    yield put(
      getCMSSuccess(
        cms,
        metaData,
        menuItems,
        systemSettings,
        undefined,
        undefined,
        appID,
        style
      )
    )
  } catch (error) {
    yield put(getCMSError(error))
  }
}

function* getClientAccountEditorVersion({ payload }) {
  try {
    const clientID = payload
    const response = yield call(getClientAccountEditorVersionRequest, clientID)
    const { cms, menuItems, newVersion, appID, style, metaData } = response
    yield put(
      getCMSSuccess(
        cms,
        metaData,
        menuItems,
        undefined,
        newVersion,
        clientID,
        appID,
        style
      )
    )
  } catch (error) {
    yield put(getCMSError(error))
  }
}

function* setClientID({ payload }) {
  try {
    const clientID = payload
    yield call(setClientIDAsync, clientID)
  } catch (error) {
    yield put(getCMSError(error))
  }
}

export function* watchGetCMS() {
  yield takeEvery(CMS_GET, getCMSItems)
}

export function* watchGetApplicationEditingVersion() {
  yield takeEvery(
    CMS_GET_APPLICATION_EDITING_VERSION,
    getApplicationEditingVersionItems
  )
}

export function* watchGetClientAccountEditorVersion() {
  yield takeEvery(CMS_GET_ACCOUNT_EDITOR_VERSION, getClientAccountEditorVersion)
}

export function* watchSetClientID() {
  yield takeEvery(SET_CLIENT_ID, setClientID)
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCMS),
    fork(watchGetApplicationEditingVersion),
    fork(watchGetClientAccountEditorVersion),
    fork(watchSetClientID),
  ])
}
