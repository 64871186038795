import React, { useEffect, useState } from "react"
import { CustomInput, Label } from "reactstrap"
import { AvGroup } from "availity-reactstrap-validation"

import CheckboxButtons from "./CheckboxButtons"
import { clientTypeCategoryIDs } from "constants/defaultValues"
import { getAllClientsByCategory } from "helpers/API"
import { compareCroatianString } from "helpers/Utils"

const RegionCitySelector = ({
  item,
  value,
  required,
  disabled,
  updateValue,
}) => {
  const [loading, setLoading] = useState(true)
  const [cities, setCities] = useState([])
  const selected = value
    .split(", ")
    .filter(x => x !== "")
    .map(x => ({ ID: x }))

  useEffect(() => {
    loadData()
  }, [item, required, disabled])

  const loadData = async () => {
    setLoading(true)
    const data = await getAllClientsByCategory(
      clientTypeCategoryIDs["Local government"]
    )

    const sortedData = data.Relations

    sortedData.sort((a, b) => compareCroatianString(a.Name, b.Name))

    setCities(sortedData)
    setLoading(false)
  }

  const setSelected = val => {
    updateValue(val.map(x => x.ID).join(", "))
  }

  return loading ? (
    <div />
  ) : (
    <AvGroup className="error-t-negative error-l-150">
      <Label>{item.Label}</Label>
      <CustomInput
        type="checkbox"
        id="select-all-button"
        label={cities.length === selected.length ? "Remove All" : "Select All"}
        checked={cities.length === selected.length}
        onChange={() => {
          if (cities.length === selected.length) {
            const newList = []
            setSelected(newList)
          } else {
            setSelected([...cities])
          }
        }}
      />
      <CheckboxButtons
        item={{ Label: "", Options: cities, ID: "city-selection" }}
        value={selected}
        updateValue={val => setSelected(val)}
      />
    </AvGroup>
  )
}

export default RegionCitySelector
