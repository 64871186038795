import { combineReducers } from "redux"

import Layout from "./layout/reducer"
import Auth from "./auth/reducer"
import CMS from "./cms/reducer"
import MenuItemForm from "./menuItemForm/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Auth,
  CMS,
  MenuItemForm,
})

export default rootReducer
