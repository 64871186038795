import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Row, Button, Table, Col } from "reactstrap"

import {
  getClientApplicationRolesByClientAndApplicationID,
  setClientApplicationRoles,
} from "../../../../helpers/API"
import RoleRow from "../../../../components/User/AdminSettings/RoleRow"

const Roles = ({ cms, appID, clientID, cmsLoading, history }) => {
  const [loading, setLoading] = useState(true)
  const [roles, setRoles] = useState([])

  useEffect(() => {
    setTimeout(async () => {
      if (cmsLoading || !cms) return

      const rolesRequest =
        await getClientApplicationRolesByClientAndApplicationID(clientID, appID)

      const sortedRoles = rolesRequest.Data.sort((a, b) => {
        const aValue = a.Administrator ? 3 : a.Contributor ? 2 : 1
        const bValue = b.Administrator ? 3 : b.Contributor ? 2 : 1
        return bValue - aValue
      })

      setRoles(sortedRoles)
      setLoading(false)
    }, 100)
  }, [cmsLoading, cms, clientID, appID])

  const changeName = (roleIndex, newValue) => {
    const newRoles = [...roles]

    newRoles[roleIndex].Name = newValue

    setRoles(newRoles)
  }

  const deleteRole = roleIndex => {
    const newRoles = [...roles]

    newRoles.splice(roleIndex, 1)

    setRoles(newRoles)
  }

  const addRole = () => {
    const newRoles = [...roles]

    const newRole = new Map()
    newRole.Rules = new Map()

    newRole.Name = ""

    Object.keys(roles[0].Rules).forEach(levelOneMenuItemID => {
      const levelOneMenuItem = roles[0].Rules[levelOneMenuItemID]

      newRole.Rules[levelOneMenuItemID] = {
        Read: "False",
        Update: "False",
        Create: "False",
        Delete: "False",
        MenuItem_ID: levelOneMenuItemID,
        MenuItemDisplayName: levelOneMenuItem.MenuItemDisplayName,
        MenuItemCMSStatus: levelOneMenuItem.MenuItemCMSStatus,
        MenuItemClientSpecificName: levelOneMenuItem.MenuItemClientSpecificName,
        Rules: new Map(),
      }

      Object.keys(levelOneMenuItem.Rules).forEach(levelTwoMenuItemID => {
        const levelTwoMenuItem = levelOneMenuItem.Rules[levelTwoMenuItemID]

        newRole.Rules[levelOneMenuItemID].Rules[levelTwoMenuItemID] = {
          Read: "False",
          Update: "False",
          Create: "False",
          Delete: "False",
          MenuItem_ID: levelTwoMenuItemID,
          MenuItemDisplayName: levelTwoMenuItem.MenuItemDisplayName,
          MenuItemCMSStatus: levelTwoMenuItem.MenuItemCMSStatus,
          MenuItemClientSpecificName:
            levelTwoMenuItem.MenuItemClientSpecificName,
          Rules: new Map(),
        }
      })
    })

    newRoles.push({ ...newRole })

    setRoles(newRoles)
  }

  const publishRoles = async () => {
    setLoading(true)
    // const rolesToPublish = [...roles]

    // roles.forEach((role, roleIndex) => {
    //   Object.keys(role.Rules).forEach(levelOneMenuItemID => {
    //     const levelOneMenuItem = role.Rules[levelOneMenuItemID]

    //     let readChildren = false
    //     let updateChildren = false
    //     let createChildren = false
    //     let deleteChildren = false

    //     Object.keys(levelOneMenuItem.Rules).forEach(levelTwoMenuItemID => {
    //       const levelTwoMenuItem = levelOneMenuItem.Rules[levelTwoMenuItemID]

    //       if (levelTwoMenuItem.Read) {
    //         readChildren = true

    //         if (levelTwoMenuItem.Update) {
    //           updateChildren = true
    //         }

    //         if (levelTwoMenuItem.Create) {
    //           createChildren = true
    //         }

    //         if (levelTwoMenuItem.Delete) {
    //           deleteChildren = true
    //         }
    //       } else {
    //         rolesToPublish[roleIndex].Rules[levelOneMenuItemID].Rules[
    //           levelTwoMenuItemID
    //         ].Update = false
    //         rolesToPublish[roleIndex].Rules[levelOneMenuItemID].Rules[
    //           levelTwoMenuItemID
    //         ].Create = false
    //         rolesToPublish[roleIndex].Rules[levelOneMenuItemID].Rules[
    //           levelTwoMenuItemID
    //         ].Delete = false
    //       }
    //     })

    //     rolesToPublish[roleIndex].Rules[levelOneMenuItemID].Read = readChildren
    //     rolesToPublish[roleIndex].Rules[levelOneMenuItemID].Update =
    //       updateChildren
    //     rolesToPublish[roleIndex].Rules[levelOneMenuItemID].Create =
    //       createChildren
    //     rolesToPublish[roleIndex].Rules[levelOneMenuItemID].Delete =
    //       deleteChildren
    //   })
    // })

    const newRoles = await setClientApplicationRoles(clientID, appID, roles)

    if (newRoles.ErrorMessage) {
      history.push("/500")
      return
    }

    const sortedRoles = newRoles.sort((a, b) => {
      const aValue = a.Administrator ? 3 : a.Contributor ? 2 : 1
      const bValue = b.Administrator ? 3 : b.Contributor ? 2 : 1
      return bValue - aValue
    })

    setRoles(sortedRoles)
    setLoading(false)
  }

  const switchRule = (
    roleIndex,
    levelOneMenuItemID,
    levelTwoMenuItemID,
    rule,
    val
  ) => {
    const newRoles = [...roles]

    newRoles[roleIndex].Rules[levelOneMenuItemID].Rules[levelTwoMenuItemID][
      rule
    ] = val

    setRoles(newRoles)
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <div className="page-content">
            <div className="container-fluid p-1">
              <Row>
                <Col>
                  <Row className="mb-3 p-2">
                    <Col>
                      <Button color="primary" onClick={() => addRole()}>
                        Add New Role
                      </Button>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      <Button color="secondary" onClick={() => publishRoles()}>
                        Publish
                      </Button>
                    </Col>
                  </Row>
                  <Table
                    responsive
                    className="responsive-bordered-table table-sm"
                    style={{
                      backgroundColor: "white",
                      marginBottom: "250px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          rowSpan="2"
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                          }}
                        >
                          Name
                        </th>
                        {Object.keys(roles[0].Rules)
                          .filter(
                            levelOneMenuItemID =>
                              roles[0].Rules[levelOneMenuItemID]
                                .MenuItemCMSStatus === "Active"
                          )
                          .map(levelOneMenuItemID => {
                            const levelOneMenuItem =
                              roles[0].Rules[levelOneMenuItemID]
                            return [
                              ...Object.keys(levelOneMenuItem.Rules)
                                .filter(
                                  levelTwoMenuItemID =>
                                    levelOneMenuItem.Rules[levelTwoMenuItemID]
                                      .MenuItemCMSStatus === "Active"
                                )
                                .map(levelTwoMenuItemID => (
                                  <th
                                    colSpan="4"
                                    key={`title_${levelTwoMenuItemID}`}
                                  >
                                    {
                                      levelOneMenuItem.Rules[levelTwoMenuItemID]
                                        .MenuItemClientSpecificName
                                    }
                                  </th>
                                )),
                            ]
                          })}
                        <th rowSpan="2">Delete</th>
                      </tr>
                      <tr>
                        {Object.keys(roles[0].Rules)
                          .filter(
                            levelOneMenuItemID =>
                              roles[0].Rules[levelOneMenuItemID]
                                .MenuItemCMSStatus === "Active"
                          )
                          .map(levelOneMenuItemID => {
                            const levelOneMenuItem =
                              roles[0].Rules[levelOneMenuItemID]
                            return [
                              ...Object.keys(levelOneMenuItem.Rules)
                                .filter(
                                  levelTwoMenuItemID =>
                                    levelOneMenuItem.Rules[levelTwoMenuItemID]
                                      .MenuItemCMSStatus === "Active"
                                )
                                .map(() => {
                                  return [
                                    <th
                                      key={`read_header_${levelOneMenuItem.ID}`}
                                    >
                                      Read
                                    </th>,
                                    <th
                                      key={`update_header_${levelOneMenuItem.ID}`}
                                    >
                                      Update
                                    </th>,
                                    <th
                                      key={`create_header_${levelOneMenuItem.ID}`}
                                    >
                                      Create
                                    </th>,
                                    <th
                                      key={`delete_header_${levelOneMenuItem.ID}`}
                                    >
                                      Delete
                                    </th>,
                                  ]
                                }),
                            ]
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {roles.map((role, roleIndex) => (
                        <RoleRow
                          key={`role_table_${role.ID}`}
                          keyVal={`role_table_${role.ID}`}
                          role={role}
                          changeName={newValue =>
                            changeName(roleIndex, newValue)
                          }
                          deleteRole={() => deleteRole(roleIndex)}
                          switchRule={(
                            levelOneMenuItemID,
                            levelTwoMenuItemID,
                            rule,
                            val
                          ) =>
                            switchRule(
                              roleIndex,
                              levelOneMenuItemID,
                              levelTwoMenuItemID,
                              rule,
                              val
                            )
                          }
                        />
                      ))}
                    </tbody>
                  </Table>

                  {/* {roles.map((role, roleIndex) => (
                <RoleCard
                  key={`role_table_${role.ID}`}
                  role={role}
                  changeName={(newValue) => changeName(roleIndex, newValue)}
                  deleteRole={() => deleteRole(roleIndex)}
                  switchRule={(
                    levelOneMenuItemIndex,
                    levelTwoMenuItemIndex,
                    rule
                  ) =>
                    switchRule(
                      roleIndex,
                      levelOneMenuItemIndex,
                      levelTwoMenuItemIndex,
                      rule
                    )
                  }
                />
              ))} */}
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { cms, appID, clientID, loading } = CMS
  return { cms, appID, clientID, cmsLoading: loading }
}
export default connect(mapStateToProps, {})(Roles)
