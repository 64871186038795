import React, { useRef, useState } from "react"
import { Label, Modal, ModalBody } from "reactstrap"
import ReactQuill from "react-quill"

import {
  imageTypes,
  quillFormats,
  quillModules,
} from "../../../../constants/defaultValues"
import GalleryModal from "../../../Gallery/GalleryModal"

const RichText = ({ item, disabled, value, updateValue }) => {
  const [modalOpened, setModalOpened] = useState(false)
  const [cursorIndex, setCursorIndex] = useState()
  const richTextRef = useRef()
  const [modules] = useState({
    toolbar: {
      container: quillModules.toolbar,
      handlers: {
        image: () => {
          setCursorIndex(
            richTextRef.current.getEditorSelection()
              ? richTextRef.current.getEditorSelection().index
              : richTextRef.current.getEditor().getLength()
          )
          setModalOpened(true)
        },
      },
    },
  })

  const addImages = selectedImages => {
    selectedImages.forEach((selectedImage, selectedImageIndex) => {
      richTextRef.current
        .getEditor()
        .insertEmbed(
          cursorIndex + selectedImageIndex,
          "image",
          selectedImage.URL
        )
    })

    richTextRef.current.getEditor().focus(cursorIndex)
  }

  return (
    <>
      <Modal
        isOpen={modalOpened}
        toggle={() => setModalOpened(!modalOpened)}
        size="xl"
      >
        <ModalBody>
          <GalleryModal
            updateValue={addImages}
            cancel={() => setModalOpened(false)}
            types={imageTypes}
          />
        </ModalBody>
      </Modal>
      <Label>{item.Label}</Label>
      <ReactQuill
        className="mb-3"
        theme="snow"
        value={value}
        ref={richTextRef}
        onChange={val => {
          updateValue(val)
        }}
        modules={modules}
        formats={quillFormats}
        readOnly={disabled}
      />
    </>
  )
}

export default RichText
