import { noImageImage } from "constants/defaultValues"
import React from "react"

const NewsTable = ({ data, actionButtons, openForm, setSort, sortBy }) => {
  const customKeys = ["Naziv", "Autor", "Status"]

  const getBadgeColor = i => {
    if (i.DateOfEntry) {
      return "bg-danger"
    }
    if (i.DateOfPublication) {
      return "bg-warning"
    }
    const dateOfPublication = new Date(i.DateOfPublication)
    const now = new Date()

    if (dateOfPublication.getTime() < now.getTime()) {
      return "bg-warning"
    }

    return "bg-success"
  }

  const getBadgeName = i => {
    if (i.DateOfEntry) {
      return "Neuneseno"
    }
    if (i.DateOfPublication) {
      return "Neobjavljeno"
    }
    const dateOfPublication = new Date(i.DateOfPublication)
    const now = new Date()

    if (dateOfPublication.getTime() < now.getTime()) {
      return "Neobjavljeno"
    }

    return "Objavljeno"
  }

  return (
    <div className="table-responsive mb-2">
      <table className="table table-striped table-sm mb-0">
        <thead>
          <tr>
            {[
              ...customKeys.map(key => (
                <th
                  key={`heading_${key}`}
                  onClick={() => {
                    setSort(key)
                  }}
                  className={`${sortBy === key ? "sorted-desc" : ""}${
                    sortBy === `inv${key}` ? "sorted-asc" : ""
                  }`}
                >
                  {key}
                </th>
              )),
              actionButtons &&
                actionButtons.map(actionButton => (
                  <th key={`actionButtonTitle_${actionButton.id}`}>
                    {actionButton.title}
                  </th>
                )),

              <th key={`copy`}>Copy ID</th>,
            ]}
          </tr>
        </thead>
        <tbody>
          {[
            ...data.map(i => (
              <tr key={`${i.ID}_row`}>
                <td>
                  <button
                    className="text-only-button"
                    type="button"
                    onClick={() => openForm(i.ID)}
                  >
                    <img
                      src={i.Picture || noImageImage}
                      alt={i.Picture}
                      className="avatar-xs rounded-circle me-2"
                    />
                    {i.Title}
                  </button>
                </td>
                <td>{i.Author}</td>
                <td>
                  <span className={`badge ${getBadgeColor(i)}`}>
                    {getBadgeName(i)}
                  </span>
                </td>
                {
                  (actionButtons &&
                    actionButtons.map(actionButton => (
                      <td key={`actionButtonItem_${actionButton.id}_${i.id}`}>
                        <button
                          className={
                            actionButton.disabled
                              ? "text-only-button-disabled"
                              : "text-only-button"
                          }
                          type="button"
                          onClick={() => actionButton.function(i.ID)}
                          disabled={actionButton.disabled}
                        >
                          {typeof actionButton.buttonTitle === "function"
                            ? actionButton.buttonTitle(i)
                            : actionButton.buttonTitle}
                        </button>
                      </td>
                    )),
                  (
                    <td key={`copy_${i.ID}`}>
                      <button
                        className="text-only-button"
                        type="button"
                        onClick={() =>
                          window.navigator.clipboard.writeText(i.ID)
                        }
                      >
                        <i className="simple-icon-note" />
                      </button>
                    </td>
                  ))
                }
              </tr>
            )),
          ]}
        </tbody>
      </table>
    </div>
  )
}

export default NewsTable
