import React, { useEffect, useState } from "react"
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation"
import { Button, Row, Label, Col, Card, CardBody } from "reactstrap"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import { getCMSApplicationEditingVersion } from "../../../../store/cms/actions"
import {
  getApplicationByApplicationID,
  makeApplicationBuild,
  rebuildEntitiesStructure,
} from "../../../../helpers/API"
import RichText from "../../../../components/Form/Fields/FieldTypes/RichText"
import { adminRoot } from "../../../../constants/defaultValues"

const ApplicationVersionBuilder = ({
  t,
  history,
  getCMSApplicationEditingVersionAction,
}) => {
  const [loading, setLoading] = useState(true)
  const [description, setDescription] = useState("")
  const [application, setApplication] = useState()
  const [appID, setAppID] = useState()
  const [working, setWorking] = useState(false)

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true)
      const params = new URLSearchParams(location.search)

      const applicationID = params.get("appID")
      const defApplication = await getApplicationByApplicationID(applicationID)

      setApplication(defApplication)
      setAppID(applicationID)

      setLoading(false)
    }, 500)
  }, [])

  const onSubmit = async (event, errors, values) => {
    if (errors.length === 0) {
      setWorking(true)

      const response = await makeApplicationBuild({
        Application_ID: appID,
        Version: values.Version,
        Description: description,
      })

      if (!response.ErrorMessage) {
        setLoading(true)
        getCMSApplicationEditingVersionAction(appID)
        history.push(`${adminRoot}applicationBuilder?appID=${appID}`)
        const defApplication = await getApplicationByApplicationID(appID)

        setApplication(defApplication)
        setLoading(false)
      }

      setDescription("")
      setWorking(false)
    } else {
      console.log(errors)
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row>
            <Col>
              {loading ? (
                <div className="loading" />
              ) : (
                <>
                  <h1 className="mb-4">{t("cms-creator.new-build-version")}</h1>

                  <h3 className="mb-2">
                    {`${t("cms-creator.latest-build")} ${
                      application.LatestBuildNumber
                    }`}
                  </h3>
                  <AvForm
                    className="av-tooltip tooltip-label-right"
                    onSubmit={(event, errors, values) =>
                      onSubmit(event, errors, values)
                    }
                  >
                    <AvGroup className="error-t-negative error-l-150">
                      <Label>Build Name</Label>
                      <AvField name="Version" type="text" required />

                      <Label>Description</Label>
                      <RichText
                        item={{ Name: "Description" }}
                        value={description}
                        updateValue={val => setDescription(val)}
                      />
                    </AvGroup>

                    <div className="col-12 justify-content-end d-flex">
                      <Button color="primary" disabled={working}>
                        {t("form.submit")}
                      </Button>
                    </div>
                  </AvForm>

                  <Card className="mt-4 ">
                    <CardBody>
                      <h1>{t("cms-creator.update-entities-structure")}</h1>

                      <Button
                        className="mt-3"
                        color="primary"
                        onClick={async () => {
                          setWorking(true)
                          await rebuildEntitiesStructure()
                          setWorking(false)
                        }}
                        disabled={working}
                      >
                        {t("form.submit")}
                      </Button>
                    </CardBody>
                  </Card>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default connect(
  () => {
    return {}
  },
  { getCMSApplicationEditingVersionAction: getCMSApplicationEditingVersion }
)(withTranslation()(withRouter(ApplicationVersionBuilder)))
