import React, { useState } from "react"
import { Input, Label, Col, CustomInput } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"
import AvForm from "availity-reactstrap-validation/lib/AvForm"

import { ThemeColors } from "../../../helpers/ThemeColors"
import SingleImage from "../../Form/Fields/FieldTypes/SingleImage"
import RichText from "../../../components/Form/Fields/FieldTypes/RichText"

const colors = ThemeColors()

const StoreRow = ({ store, editStore, deleteStore, className, type }) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <Col className={`mb-2 mt-2 ${className}`}>
      <AvForm>
        <div className="flex-grow-1 min-width-zero">
          <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
            <div className="align-self-center">
              <button
                className="text-only-button handle"
                type="button"
                id={`move_${store.ID}`}
                onClick={() => {}}
              >
                <FontAwesomeIcon
                  icon={faGripVertical}
                  color={colors.separatorColor}
                />
              </button>
            </div>

            <div className="col-10">
              <Input
                type="text"
                className="list-item-heading truncate w-100"
                name={store.Title}
                placeholder="Title"
                value={store.Title}
                onChange={e => editStore("Title", e.target.value)}
              />
            </div>

            <div className="custom-control custom-checkbox align-self-center col-1 d-flex justify-content-center pl-0">
              <button
                className="text-only-button col-6"
                type="button"
                id={`details_${store.ID}`}
                onClick={() => setShowDetails(!showDetails)}
              >
                <i className="simple-icon-eye" />
              </button>

              {deleteStore && (
                <button
                  className="text-only-button col-6"
                  type="button"
                  id={`delete_${store.ID}`}
                  onClick={() => deleteStore()}
                >
                  <i className="simple-icon-trash" />
                </button>
              )}
            </div>
          </div>
        </div>
        {showDetails && (
          <div className="flex-grow-1 min-width-zero">
            <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              <div className="col-12 p-1">
                <Label>Subtitle</Label>
                <Input
                  type="text"
                  className="mb-2 list-item-heading truncate"
                  name={store.Subtitle}
                  placeholder="Subtitle"
                  value={store.Subtitle}
                  onChange={e => editStore("Subtitle", e.target.value)}
                />
              </div>
            </div>

            <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              <div className="col-12 p-1 mb-2">
                <RichText
                  item={{ Label: "Description" }}
                  value={store.Description}
                  updateValue={e => editStore("Description", e)}
                />
              </div>
            </div>

            <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              <div className="col-12 p-1">
                <SingleImage
                  item={{
                    Label: "BannerImage",
                    ID: `banner_image_${store.ID}`,
                    name: "BannerImage",
                    Prompt: "",
                  }}
                  value={store.BannerImage}
                  updateValue={val => editStore("BannerImage", val)}
                  path="BizStoreImages/"
                />
              </div>
            </div>

            <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              <div className="col-12 p-1">
                <SingleImage
                  item={{
                    Label: "SinglePageImage",
                    ID: `single_page_image_${store.ID}`,
                    name: "SinglePageImage",
                    Prompt: "",
                  }}
                  value={store.SinglePageImage}
                  updateValue={val => editStore("SinglePageImage", val)}
                  path="BizStoreImages/"
                />
              </div>
            </div>

            <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              <div className="col-12 p-1">
                <SingleImage
                  item={{
                    Label: "IconImage",
                    ID: `icon_image_${store.ID}`,
                    name: "IconImage",
                    Prompt: "",
                  }}
                  value={store.IconImage}
                  updateValue={val => editStore("IconImage", val)}
                  path="BizStoreImages/"
                />
              </div>
            </div>

            <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              <div className="col-12 p-1">
                <Label>Color 1</Label>
                <Input
                  type="text"
                  className="mb-2 list-item-heading truncate"
                  name={store.Color1}
                  placeholder="Color1"
                  value={store.Color1}
                  onChange={e => editStore("Color1", e.target.value)}
                />
              </div>
            </div>

            <div className="p-2 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              <div className="col-12 p-1">
                <Label>Color 2</Label>
                <Input
                  type="text"
                  className="mb-2 list-item-heading truncate"
                  name={store.Color2}
                  placeholder="Color2"
                  value={store.Color2}
                  onChange={e => editStore("Color2", e.target.value)}
                />
              </div>
            </div>

            <div className="p-2 pt-0 d-flex flex-lg-row justify-content-between min-width-zero col-12">
              {/* <div className="col-6 p-1">
                <Label>Business ID</Label>
                <Input
                  type="text"
                  className="mb-2 list-item-heading truncate"
                  name={store.BusinessID}
                  placeholder="Business ID"
                  value={store.BusinessID}
                  onChange={e => editStore("BusinessID", e.target.value)}
                />
              </div> */}
              <div className="col-12 p-1">
                <Label>Visibility ID</Label>
                <Input
                  type="text"
                  className="mb-2 list-item-heading truncate"
                  name={store.VisibilityID}
                  placeholder="Visibility ID"
                  value={store.VisibilityID}
                  onChange={e => editStore("VisibilityID", e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
      </AvForm>
    </Col>
  )
}

export default StoreRow
