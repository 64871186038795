import React, { useEffect, useState } from "react"
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import { Button, Label } from "reactstrap"
import Select from "react-select"

import {
  createOrUpdateClient,
  deleteClientByClientID,
  getAllClientTypes,
  getAllCounties,
  getAllCountries,
  getClientByClientID,
} from "../../../../../helpers/API"
import { getSelectTheme, validateOIBAndMB } from "../../../../../helpers/Utils"
import NotificationAlert from "../../../../Common/Notifications/NotificationAlert"
import {
  adminRoot,
  cityStatusOptions,
} from "../../../../../constants/defaultValues"
import ButtonWithWarning from "../../../../Common/UI/ButtonWithWarning"

const CityForm = ({ ID, deleteEnabled, history }) => {
  const [loading, setLoading] = useState(true)

  const [defaultValues, setDefaultValues] = useState()
  const [status, setStatus] = useState()

  const [clientTypes, setClientTypes] = useState([])
  const [countries, setCountries] = useState([])
  const [counties, setCounties] = useState([])
  const [country, setCountry] = useState()
  const [county, setCounty] = useState()

  const [types] = useState(["City", "Municipality"])

  useEffect(() => {
    setTimeout(async () => {
      const clientTypesAPI = await getAllClientTypes()
      const countriesAPI = await getAllCountries()
      const countiesAPI = await getAllCounties()

      if (ID) {
        const defaultValuesAPI = await getClientByClientID(ID)
        setDefaultValues(defaultValuesAPI)

        setCountry(defaultValuesAPI.Country_ID || countriesAPI[0].ID)
        setCounty(defaultValuesAPI.County_ID)
        setStatus(defaultValuesAPI.Status)
      } else {
        setDefaultValues({
          ClientType_ID: clientTypesAPI.find(x => types[0] === x.Name).ID,
        })
      }

      setClientTypes(clientTypesAPI)
      setCountries(countriesAPI)
      setCounties(countiesAPI)

      setLoading(false)
    }, 100)
  }, [ID])

  const onSubmit = async (event, errors, values) => {
    if (errors.length === 0 && county && country && status) {
      const client = await createOrUpdateClient({
        ...values,
        Country_ID: country,
        County_ID: county,
        ID: ID,
        Status: status,
      })

      if (!client.ErrorMessage) {
        history.push(
          `${adminRoot}manager/cities/edit?ID=${
            client.MetaData ? client.MetaData.ID : client.ID
          }`
        )
      }
    } else {
      let errorMessage = "Missing: "
      errors.forEach(error => {
        errorMessage += error
        errorMessage += ", "
      })
      if (!country) errorMessage += "Country, "
      if (!county) errorMessage += "County, "
      if (!status) errorMessage += "Status, "

      NotificationAlert({
        type: "warning",
        title: "",
        message: errorMessage,
        expiration: 3000,
      })
    }
  }

  const deleteClient = async () => {
    const api = await deleteClientByClientID(ID)

    if (api.ErrorMessage) {
      NotificationAlert({
        type: "error",
        title: "Error",
        message: api.ErrorMessage.Message,
        expiration: 3000,
      })
    } else {
      history.push(`${adminRoot}manager/cities`)
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <AvForm
            className="av-tooltip tooltip-label-right p-1 pt-3"
            model={defaultValues}
            onSubmit={(event, errors, values) =>
              onSubmit(event, errors, values)
            }
          >
            <AvRadioGroup
              className="error-l-150 radio-buttons"
              name="ClientType_ID"
              required
              inline
            >
              <Label className="d-block">Type</Label>
              {clientTypes.map(clientType =>
                types.includes(clientType.Name) ? (
                  <AvRadio
                    customInput
                    label={clientType.Name}
                    value={clientType.ID}
                    key={clientType.ID}
                  />
                ) : (
                  <AvRadio
                    customInput
                    label={clientType.Name}
                    value={clientType.ID}
                    disabled
                    key={clientType.ID}
                  />
                )
              )}
            </AvRadioGroup>

            <AvGroup>
              <Label>City name</Label>
              <AvInput name="Name" required value={defaultValues.Name} />
              <AvFeedback>City name is required!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <Label>OIB</Label>
              <AvInput
                name="PersonalIdentificationNumber"
                type="number"
                required
                value={defaultValues.PersonalIdentificationNumber}
                validate={{ async: validateOIBAndMB }}
              />
              <AvFeedback>OIB is required!</AvFeedback>
            </AvGroup>

            <Label>Country</Label>
            <Select
              theme={getSelectTheme}
              className="mb-3"
              classNamePrefix="select2-selection"
              onChange={e => setCountry(e.ID)}
              options={countries}
              defaultValue={countries.filter(x => x.ID === country)}
              isOptionSelected={option => option.ID === country}
              getOptionLabel={option => option.Name}
            />

            <Label>County</Label>
            <Select
              theme={getSelectTheme}
              className="mb-3"
              classNamePrefix="select2-selection"
              onChange={e => setCounty(e.ID)}
              options={counties}
              defaultValue={counties.filter(x => x.ID === county)}
              isOptionSelected={option => option.ID === county}
              getOptionLabel={option => option.Name}
            />

            <h4>Contact person</h4>

            <AvGroup>
              <Label>Display name</Label>
              <AvInput
                name="ContactPerson"
                value={defaultValues.ContactPerson}
              />
              <AvFeedback>Display name is required!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <Label>Phone number</Label>
              <AvInput
                name="ContactMobilePhoneNumber"
                type="phone"
                value={defaultValues.ContactMobilePhoneNumber}
              />
              <AvFeedback>Phone number is required!</AvFeedback>
            </AvGroup>

            <AvGroup className="mb-3">
              <Label>Email</Label>
              <AvInput
                name="ContactEMail"
                type="email"
                value={defaultValues.ContactEMail}
              />
              <AvFeedback>Email is required!</AvFeedback>
            </AvGroup>

            <h4>Status</h4>
            <Select
              theme={getSelectTheme}
              className="mb-3"
              classNamePrefix="select2-selection"
              onChange={e => setStatus(e.ID)}
              options={cityStatusOptions}
              defaultValue={cityStatusOptions.filter(x => x.ID === status)}
              isOptionSelected={option => option.ID === status}
              getOptionLabel={option => option.Name}
            />

            {deleteEnabled && (
              <ButtonWithWarning color="danger" onClick={() => deleteClient()}>
                Delete
              </ButtonWithWarning>
            )}

            <Button color="primary" className="float-right">
              Submit
            </Button>
          </AvForm>
        </>
      )}
    </>
  )
}

export default CityForm
