import React, { Component } from "react"
import ChartistGraph from "react-chartist"

class LineArea extends Component {
  render() {
    var lineChartData = this.props.data
    var lineChartOptions = this.props.options
    return (
      <React.Fragment>
        <ChartistGraph
          data={lineChartData}
          style={{ height: this.props.height }}
          options={lineChartOptions}
          type={"Line"}
        />
      </React.Fragment>
    )
  }
}

export default LineArea
