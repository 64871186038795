import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="mr-2">{props.currentUser.MetaData.DisplayName}</span>
          <img
            className="rounded-circle header-profile-user"
            src={props.currentUser.avatarURL}
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/app/account">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Account")}
          </DropdownItem>
          {props.currentUser.role === 4 && (
            <>
              <div className="dropdown-divider" />

              <DropdownItem tag="a" href="/clientPicker">
                <i className="bx bx-user font-size-16 align-middle me-1" />
                {props.t("Change client")}
              </DropdownItem>
            </>
          )}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser } = Auth
  return { currentUser }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
)
