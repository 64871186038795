import React from "react"
import { NavItem, Nav, NavLink } from "reactstrap"
import classnames from "classnames"
import { withTranslation } from "react-i18next"

const TabNavigation = ({ navList, activeTab, setActive, t }) => {
  return (
    <Nav tabs className="nav-tabs-custom">
      {navList.map(item => (
        <NavItem key={`_${item.name}`}>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === item.name,
            })}
            onClick={() => setActive(item.name)}
          >
            <span> {t(item.label)}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default withTranslation()(React.memo(TabNavigation))
