import CitiesEditor from "../pages/app/account-editor/cities"
import BusinessEditor from "../pages/app/account-editor/businesses"
import Tools from "../pages/app/account-editor/tools"
import { adminRoot } from "../constants/defaultValues"
import userCreateNew from "pages/app/account-editor/tools/cities/userCreateNew"

export default [
  {
    path: `${adminRoot}editor/cities`,
    component: CitiesEditor,
  },
  {
    path: `${adminRoot}editor/businesses`,
    component: BusinessEditor,
  },

  //tools
  {
    path: `${adminRoot}editor/tools/user-create-new`,
    component: userCreateNew,
  },

  {
    path: `${adminRoot}editor/tools/:appID`,
    component: Tools,
  },
]
