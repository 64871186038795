import { getSelectTheme } from "helpers/Utils"
import React from "react"
import Select from "react-select"
import { Label } from "reactstrap"

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

const formatGroupLabel = data => (
  <div style={groupStyles} key={data.ID}>
    <span>{data.Label ? data.Label : data.Name}</span>
  </div>
)

const Dropdown = ({ item, disabled, value, updateValue }) => {
  const modifiedOptions = []

  let lastGroupIndex = false

  item.Options.forEach(option => {
    if (option.Type === "Group") {
      modifiedOptions.push({ ...option, options: [] })
      lastGroupIndex = modifiedOptions.length - 1
    } else if (lastGroupIndex || lastGroupIndex === 0) {
      modifiedOptions[lastGroupIndex].options.push(option)
    } else {
      modifiedOptions.push(option)
    }
  })

  return (
    <div className="mb-3">
      <Label>{item.Label}</Label>
      <Select
        classNamePrefix="select2-selection"
        onChange={e => updateValue(e.ID)}
        options={modifiedOptions}
        value={item.Options.find(x => x.ID === value)}
        isOptionSelected={option => option.ID === value}
        getOptionLabel={option => (option.Label ? option.Label : option.Name)}
        formatGroupLabel={formatGroupLabel}
        isDisabled={disabled}
        theme={getSelectTheme}
      />
    </div>
  )
}

export default Dropdown
