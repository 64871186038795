import React from "react"
import { Document, Page, Image, View, Text } from "@react-pdf/renderer"

const Offer = ({ offer, styles, title }) => {
  if (!offer) return <div className="loading" />
  return (
    <Document title={title || offer.id}>
      <Page size="A4" style={styles.page}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Image style={styles.logo} src={offer.logo} />

          <View style={styles.titleContainer}>
            <Text style={styles.reportTitle}>{offer.title}</Text>
          </View>
        </View>

        <View style={styles.headerContainer}>
          <Text>{offer.sender.company}</Text>
          <Text>{offer.sender.address}</Text>
          <Text>{offer.sender.city}</Text>
          <Text>OIB: {offer.sender.oib}</Text>
          <Text>ŽR: {offer.sender.iban}</Text>
        </View>

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View>
            <Text style={styles.billTo}>Kupac:</Text>
            <Text>{offer.receiver.company}</Text>
            <Text>{offer.receiver.address}</Text>
            <Text>{offer.receiver.city}</Text>
            <Text>OIB: {offer.receiver.oib}</Text>
          </View>

          <View style={{ flexDirection: "column", minWidth: "30%" }}>
            <View style={styles.offerNoContainer}>
              <Text style={styles.label}>Broj ponude:</Text>
              <Text style={styles.offerNo}>{offer.offer.offer_no}</Text>
            </View>
            <View style={styles.offerDateContainer}>
              <Text style={styles.label}>Mjesto: </Text>
              <Text style={styles.offerDate}>{offer.offer.city}</Text>
            </View>
            <View style={styles.offerDateContainer}>
              <Text style={styles.label}>Datum: </Text>
              <Text style={styles.offerDate}>{offer.offer.trans_date}</Text>
            </View>
            <View style={styles.offerDateContainer}>
              <Text style={styles.label}>Poziv na broj: </Text>
              <Text style={styles.offerDate}>
                {offer.offer.referenceNumber}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.tableContainer}>
          <View style={styles.container}>
            <Text style={styles.description}>Vrsta robe ili usluge</Text>
            <Text style={styles.qty}>Količina</Text>
            <Text style={styles.rate}>Cijena</Text>
            <Text style={styles.discount}>Popust</Text>
            <Text style={styles.amount}>Ukupno</Text>
            <Text style={styles.PDV}>PDV</Text>
            <Text style={styles.amountWithPDV}>Cijena s PDV</Text>
          </View>
          {offer.items.map(item => (
            <View style={styles.row} key={item.sno.toString()}>
              <Text style={styles.description}>{item.desc}</Text>
              <Text style={styles.qty}>{item.qty}</Text>
              <Text style={styles.rate}>{item.rate.toFixed(2)}</Text>
              <Text style={styles.discount}>{`${item.discount}%`}</Text>
              <Text style={styles.amount}>
                {(item.qty * item.rate * (1 - item.discount / 100)).toFixed(2)}
              </Text>
              <Text style={styles.PDV}>
                {(
                  item.qty *
                  item.rate *
                  (1 - item.discount / 100) *
                  0.25
                ).toFixed(2)}
              </Text>
              <Text style={{ ...styles.amountWithPDV }}>
                {`${(
                  item.qty *
                  item.rate *
                  (1 - item.discount / 100) *
                  1.25
                ).toFixed(2)}  `}
              </Text>
            </View>
          ))}

          <View style={styles.row}>
            <Text style={styles.totalDescription}>TOTAL</Text>
            <Text style={styles.total}>
              {Number.parseFloat(
                offer.items
                  .map(item => item.qty * item.rate * (1 - item.discount / 100))
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  )
              ).toFixed(2)}
            </Text>
            <Text style={styles.total}>
              {Number.parseFloat(
                offer.items
                  .map(item => item.qty * item.rate * (1 - item.discount / 100))
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  ) * 0.25
              ).toFixed(2)}
            </Text>
            <Text style={styles.totalWithPDV}>
              {Number.parseFloat(
                offer.items
                  .map(item => item.qty * item.rate * (1 - item.discount / 100))
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  ) * 1.25
              ).toFixed(2)}
            </Text>
          </View>
        </View>
        <View style={styles.footerContainer}>
          <Text style={styles.footerText}>{offer.footerText}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default Offer
