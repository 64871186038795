import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import { withRouter } from "react-router-dom"

import Form from "./Components/Form"
import TabNavigation from "../Common/UI/TabNavigation"
import UploadPage from "./Components/UploadPage"
import CitySelection from "./Components/CitySelection"
import { withTranslation } from "react-i18next"
import TableControler from "./Table/TableControler"
import CitySelectionTab from "./Components/CitySelectionTab"

// the start page that opens when results of GET ALL API call should be shown for user
const Controler = ({
  module,
  opened,
  rules,
  privateAPI,
  uploadTab,
  location,
  history,
  title,
  noSearch,
  noPagination,
  noPageSize,
  hideCreate,
  limit,
  openFormOverride,
  contributorEntities,
  t,
}) => {
  const [activeTab, setActiveTab] = useState("Entries")
  const [secondaryActiveTab, setSecondaryActiveTab] = useState("Form")
  const entityID = module.Form.FieldGroups.find(
    x => x.Type === "Input"
  ).Entity_ID

  const openForm = ID => {
    if (openFormOverride) {
      openFormOverride(ID)
    } else {
      history.push(
        `${location.pathname}${location.search}${
          location.search ? "&" : "?"
        }opened=${ID || "new"}`
      )
    }
  }

  const cancelForm = () => {
    history.push(
      `${location.pathname}${location.search}`
        .split("?opened=")[0]
        .split("&opened=")[0]
    )
  }

  const addID = ID => {
    if (location.search === "?opened=new") {
      history.push(`${location.pathname}?opened=${ID}`)
    } else {
      history.push(
        `${
          `${location.pathname}${location.search}`
            .split("?opened=")[0]
            .split("&opened=")[0]
        }${location.search ? "&" : "?"}opened=${ID}`
      )
    }
  }

  const navItems = [{ name: "Entries", label: "form.entries" }]

  if (uploadTab) navItems.push({ name: "Upload", label: "form.upload" })

  const secondaryNavItems = [
    { name: "Form", label: "form.form" },
    { name: "CitySelection", label: "form.city-selection" },
  ]

  return (
    <>
      <div className="container-fluid p-1">
        <Row>
          <Col>
            {title && !opened && (
              <h4 className="d-flex flex-column min-width-zero margin-bottom-10">
                {t(title)}
              </h4>
            )}

            <>
              {(!opened && navItems.length) > 1 && (
                <TabNavigation
                  activeTab={activeTab}
                  setActive={val => setActiveTab(val)}
                  navList={navItems}
                />
              )}

              {opened && opened !== "new" && module.EnableCitySelection && (
                <TabNavigation
                  activeTab={secondaryActiveTab}
                  setActive={val => setSecondaryActiveTab(val)}
                  navList={secondaryNavItems}
                />
              )}

              {activeTab === "Entries" && (
                <>
                  {opened ? (
                    <>
                      {secondaryActiveTab === "Form" && (
                        <Form
                          form={module.Form}
                          ID={opened === "new" ? undefined : opened}
                          onCancel={cancelForm}
                          onDelete={cancelForm}
                          onAdd={addID}
                          rules={rules}
                          privateAPI={privateAPI}
                        />
                      )}

                      {secondaryActiveTab === "CitySelection" && (
                        <CitySelectionTab
                          ID={opened}
                          entityID={entityID}
                          cancel={cancelForm}
                        />
                      )}
                    </>
                  ) : (
                    <TableControler
                      openForm={openForm}
                      module={module}
                      privateAPI={privateAPI}
                      contributorEntities={contributorEntities}
                      limit={limit}
                      rules={rules}
                      noSearch={noSearch}
                      noPageSize={noPageSize}
                      noPagination={noPagination}
                      hideCreate={hideCreate}
                    />
                  )}
                </>
              )}

              {activeTab === "Upload" && (
                <>
                  <UploadPage />
                </>
              )}
            </>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default withRouter(withTranslation()(Controler))
