import React, { useState, useEffect } from "react"
import { Row, Col, Modal, ModalBody, Button } from "reactstrap"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

import DynamicTable from "../../../Table/DynamicTable"
import TablesSearch from "../../../Table/TableSearch"
import {
  makeAPICallWithErrorHandling,
  removePublicAPIRelation,
  searchDatabase,
  addPublicAPIRelation,
  getFormOptions,
} from "../../../../../helpers/API"
import Form from "../../../Components/Form"
import { fieldGroupTableCustomSettings } from "./customRules"

// the start page that opens when results of GET ALL API call should be shown for user
const Controler = ({
  module,
  fieldGroup,
  title,
  rules,
  privateAPI,
  history,
  cmsLoading,
  parentID,
  parentURL,
  parentRelationKey,
  parentPerPage,
  noSubTables,
  t,
  propClient_ID,
  propApplication_ID,
}) => {
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState()
  const [IDList, setIDList] = useState()
  const [opened, setOpened] = useState()
  const [showAllResults, setShowAllResults] = useState(false)
  const [searchOpened, setSearchOpened] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchResults, setSearchResults] = useState()

  const inputFieldGroup = module.Form.FieldGroups.find(x => x.Type === "Input")

  useEffect(() => {
    loadData()
  }, [module])

  const loadData = () => {
    setLoading(true)

    setTimeout(async () => {
      const url = `${parentURL}/${parentID}/${inputFieldGroup.EntityRequestURL}`
      const apiURL = privateAPI ? "{{APIURL}}" : "{{PUBLICAPIURL}}"

      let limit = false
      if (!showAllResults) {
        if (parentPerPage) limit = parentPerPage
        else if (module.PerPage) limit = module.PerPage
        else limit = 5
      }

      const sufix = limit ? `?perPage=${limit}` : ""

      const tableData = await makeAPICallWithErrorHandling(
        `${apiURL}${url}${sufix}`,
        "GET",
        {},
        history
      )

      setResults(tableData.Relations)
      setIDList([...tableData.Relations.map(x => x.ID)])
      setLoading(false)
    })
  }

  const openForm = ID => {
    setOpened(ID || "new")
  }

  const cancelForm = () => {
    setOpened(undefined)
  }

  const refresh = () => {
    setLoading(true)
    setResults(undefined)
    setIDList(undefined)
    loadData()
  }

  const removeRelation = async ID => {
    const apiURL = privateAPI ? "{{APIURL}}" : "{{PUBLICAPIURL}}"
    const api = await removePublicAPIRelation(
      `${apiURL}${parentURL}/${inputFieldGroup.EntityRequestURL}`,
      parentID,
      ID,
      parentRelationKey,
      inputFieldGroup.EntityRelationKey
    )

    if (!api.ErrorMessage) {
      refresh()
    }
  }

  const addRelation = async ID => {
    const apiURL = privateAPI ? "{{APIURL}}" : "{{PUBLICAPIURL}}"
    const api = await addPublicAPIRelation(
      `${apiURL}${parentURL}/${inputFieldGroup.EntityRequestURL}`,
      parentID,
      ID,
      parentRelationKey,
      inputFieldGroup.EntityRelationKey
    )

    if (!api.ErrorMessage) {
      refresh()
    }
  }

  const showSearchResults = async values => {
    setSearchLoading(true)
    const searchResult = await searchDatabase(
      inputFieldGroup.EntityTableName,
      `v${inputFieldGroup.EntityTableName}`,
      values
    )
    setSearchResults(searchResult.Data)
    setSearchLoading(false)
  }

  const customConfigParameters = {
    data: {
      module,
      fieldGroup,
      rules,
      results,
      state: {
        loading,
        results,
        IDList,
        opened,
        showAllResults,
        searchOpened,
        searchLoading,
        searchResults,
      },
    },
    functions: {
      actions: {
        setShowAllResults,
        loadData,
        openForm,
        cancelForm,
        refresh,
        removeRelation,
        addRelation,
        showSearchResults,
      },
      state: {
        setLoading,
        setResults,
        setIDList,
        setOpened,
        setShowAllResults,
        setSearchOpened,
        setSearchLoading,
        setSearchResults,
      },
    },
  }

  const parameterObserver = {
    getActionButtons: () => {
      if (
        typeof fieldGroupTableCustomSettings[fieldGroup.ID]?.actionButtons ===
        "function"
      ) {
        return fieldGroupTableCustomSettings[fieldGroup.ID].actionButtons(
          customConfigParameters
        )
      } else {
        return [
          {
            id: "819465712395-57149654135-3410859714",
            title: "Relations",
            buttonTitle: "Remove",
            function: x => removeRelation(x),
            disabled:
              !rules.update === "True" && !rules.update === "IfContributor",
          },
        ]
      }
    },
    getBottomButton: () => {
      if (
        typeof fieldGroupTableCustomSettings[fieldGroup.ID]?.bottomButton ===
        "function"
      ) {
        return fieldGroupTableCustomSettings[fieldGroup.ID].bottomButton(
          customConfigParameters
        )
      } else {
        return (
          <>
            {results.length > (module.PerPage ? module.PerPage - 1 : 4) && (
              <div className="p-2 d-flex flex-lg-row justify-content-center min-width-zero">
                <Button
                  color="none"
                  onClick={() => {
                    setShowAllResults(!showAllResults)
                    loadData()
                  }}
                >
                  {showAllResults ? (
                    <div>
                      Hide All <i className="iconsminds-arrow-up" />
                    </div>
                  ) : (
                    <div>
                      Show All <i className="iconsminds-arrow-down" />
                    </div>
                  )}
                </Button>
              </div>
            )}
          </>
        )
      }
    },
    getTopButton: () => {
      if (
        typeof fieldGroupTableCustomSettings[fieldGroup.ID]?.topButton ===
        "function"
      ) {
        return fieldGroupTableCustomSettings[fieldGroup.ID].topButton(
          customConfigParameters
        )
      } else if (fieldGroup?.NoExistingEntries) {
        return undefined
      } else {
        return (
          <div className="d-inline-block mr-2 align-top">
            <Button
              color="secondary"
              onClick={() => setSearchOpened(true)}
              disabled={
                !rules.update === "True" && !rules.update === "IfContributor"
              }
            >
              Add existing
            </Button>
          </div>
        )
      }
    },
  }

  return (
    <>
      <Modal isOpen={opened} size="xl" toggle={() => setOpened(undefined)}>
        <ModalBody>
          <Form
            form={module.Form}
            ID={opened === "new" ? undefined : opened}
            onCancel={cancelForm}
            onDelete={cancelForm}
            onAdd={ID => {
              addRelation(ID)
            }}
            rules={rules}
            noSubTables={noSubTables}
            privateAPI={privateAPI}
            propApplication_ID={propApplication_ID}
            propClient_ID={propClient_ID}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={searchOpened}
        size="xl"
        toggle={() => {
          setSearchOpened(!searchOpened)
          setSearchResults()
        }}
      >
        <ModalBody>
          <TablesSearch
            formID={module.Form.ID}
            fields={inputFieldGroup.Fields}
            searchFunction={values => showSearchResults(values)}
            propApplication_ID={propApplication_ID}
            propClient_ID={propClient_ID}
          />
          {searchResults && !searchLoading && !loading && (
            <DynamicTable
              item={searchResults}
              openForm={openForm}
              actionButtons={[
                {
                  id: "819465712395-57149654135-3412948543714",
                  title: "Relations",
                  buttonTitle: x =>
                    IDList && IDList.includes(x.ID) ? "Remove" : "Add",
                  function: x =>
                    IDList && IDList.includes(x)
                      ? removeRelation(x)
                      : addRelation(x),
                },
              ]}
              noPageSize
              noPagination
              noSearch
              hideCreate
            />
          )}
          {searchLoading && <div className="loading" />}
        </ModalBody>
      </Modal>
      <Row>
        <Col>
          {/* {title && (
              <div className="d-flex flex-column min-width-zero margin-bottom-10">
                {t(title)}
              </div>
            )} */}
          {loading || cmsLoading ? (
            <div className="loading" />
          ) : (
            <>
              {(title || module.DisplayName) && (
                <div className="d-flex flex-column min-width-zero ">
                  <h3>{t(title || module.DisplayName)}</h3>
                </div>
              )}
              <DynamicTable
                item={results}
                openForm={openForm}
                create={
                  rules.update === "True" || rules.update === "IfContributor"
                }
                refresh={loadData}
                topButton={parameterObserver.getTopButton()}
                actionButtons={parameterObserver.getActionButtons()}
                bottomButton={parameterObserver.getBottomButton()}
                noPagination={!showAllResults}
                noPageSize={!showAllResults}
                noSearch={!showAllResults}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { loading } = CMS

  return { cmsLoading: loading }
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, {})(Controler))
)
