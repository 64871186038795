import React from "react"
import { connect } from "react-redux"

import Input from "./FieldGroupTypes/Input"
import Table from "./FieldGroupTypes/Table"

const FieldGroupPicker = ({
  fieldGroup,
  ID,
  formID,
  excludeKeys,
  propValues,
  rules,
  parentURL,
  parentRelationKey,
  privateAPI,
  onCancel,
  onDelete,
  onAdd,
  onSubmit,
  noSubTables,
  noDelete,
  currentUser,
  propApplication_ID,
  propClient_ID,
}) => {
  if (!fieldGroup.Visible) return <div />

  if (fieldGroup.AdminOnly && currentUser.role > 3) return <div />

  if (fieldGroup.Type === "Input") {
    return (
      <>
        <Input
          fieldGroup={fieldGroup}
          ID={ID}
          formID={formID}
          excludeKeys={excludeKeys}
          propValues={propValues}
          onAdd={onAdd}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onDelete={onDelete}
          rules={rules}
          privateAPI={privateAPI}
          noDelete={noDelete}
          propClient_ID={propClient_ID}
          propApplication_ID={propApplication_ID}
        />
      </>
    )
  }

  if (fieldGroup.Type === "Table" && !noSubTables) {
    return (
      <>
        <Table
          fieldGroup={fieldGroup}
          ID={ID}
          rules={rules}
          parentURL={parentURL}
          parentRelationKey={parentRelationKey}
          privateAPI={privateAPI}
          propClient_ID={propClient_ID}
          propApplication_ID={propApplication_ID}
        />
      </>
    )
  }

  console.log("False Field Group input or no ID")
  return <div />
}

const mapStateToProps = ({ Auth }) => {
  const { currentUser } = Auth

  return { currentUser }
}

export default connect(mapStateToProps, {})(FieldGroupPicker)
