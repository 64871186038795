import { getSelectTheme } from "helpers/Utils"
import React from "react"
import Select from "react-select"
import { Label } from "reactstrap"

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.Label ? data.Label : data.Name}</span>
  </div>
)

const MultipleChoiceDropdown = ({ item, value, updateValue, disabled }) => {
  const modifiedOptions = []

  let lastGroupIndex = false

  item.Options.forEach(option => {
    if (option.Type === "Group") {
      modifiedOptions.push({
        ...option,
        options: [],
      })
      lastGroupIndex = modifiedOptions.length - 1
    } else if (lastGroupIndex || lastGroupIndex === 0) {
      modifiedOptions[lastGroupIndex].options.push({
        ...option,
      })
    } else {
      modifiedOptions.push({ ...option })
    }
  })

  return (
    <>
      <Label>{item.Label}</Label>
      <Select
        className="mb-3"
        classNamePrefix="select2-selection"
        onChange={(e, f) => {
          if (f.action === "select-option") {
            const newValues = [...value]
            newValues.push({ ID: f.option.ID })
            updateValue(newValues)
          } else {
            const newValues = [...value]
            newValues.splice(
              value.indexOf(value.find(x => x.ID === f.removedValue.ID)),
              1
            )
            updateValue(newValues)
          }
        }}
        options={modifiedOptions}
        value={item.Options.filter(option =>
          value.find(x => x.ID === option.ID)
        )}
        isOptionSelected={option => value.find(x => x.ID === option.ID)}
        getOptionLabel={option => (option.Label ? option.Label : option.Name)}
        getOptionValue={option => option.ID}
        isMulti
        formatGroupLabel={formatGroupLabel}
        isDisabled={disabled}
        theme={getSelectTheme}
      />
    </>
  )
}

export default MultipleChoiceDropdown
