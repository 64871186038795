import React, { useState } from "react"
import { AvGroup } from "availity-reactstrap-validation"
import { Label } from "reactstrap"
import DatePicker from "react-datepicker"

const DateInput = ({ item, disabled, updateValue, value }) => {
  const [dateValue, setDate] = useState(value)

  return (
    <AvGroup>
      <Label>{item.Label}</Label>
      <div className="mb-3">
        <DatePicker
          className="form-control"
          selected={dateValue}
          onChange={val => {
            setDate(val)
            updateValue(val)
          }}
          placeholderText={dateValue}
          disabled={disabled}
        />
      </div>
    </AvGroup>
  )
}

export default DateInput
