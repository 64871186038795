import React, { useEffect, useState } from "react"

import { makeStylingForDynamicTableFromFieldGroup } from "helpers/Utils"
import DynamicTable from "./DynamicTable"
import { makeAPICallWithErrorHandling } from "helpers/API"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import NotificationAlert from "components/Common/Notifications/NotificationAlert"

const TableControler = ({
  openForm,
  module,
  privateAPI,
  contributorEntities,
  limit,
  rules,
  noSearch,
  noPageSize,
  noPagination,
  hideCreate,
  cmsLoading,
}) => {
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState([])

  useEffect(() => {
    loadData()
  }, [module])

  const loadData = async () => {
    setLoading(true)

    const fullTableData = []

    await Promise.all(
      module.Form.FieldGroups.filter(x => x.Type === "Input").map(
        async inputFieldGroup => {
          const url = inputFieldGroup.EntityRequestURL
          const apiURL = privateAPI ? "{{APIURL}}" : "{{PUBLICAPIURL}}"

          let iterations = [{}]
          if (contributorEntities && contributorEntities.length > 0) {
            iterations = contributorEntities
          }

          const data = []
          const addedIds = {}

          await Promise.all(
            iterations.map(async contributorEntity => {
              const notContributor =
                !contributorEntity.MetaData ||
                contributorEntity.MetaData.RequestURL === url

              let contributorEntityIsInField

              let fullURL

              if (notContributor) {
                fullURL = `${apiURL}${url}${limit ? `?perPage=${limit}` : ""}`
              } else {
                const contributorField = inputFieldGroup.Fields.find(
                  x => x.SelectionEntity_ID === contributorEntity.MetaData.ID
                )

                if (contributorField) {
                  // TODO - custom URL mapping
                  NotificationAlert({
                    type: "warning",
                    title: "",
                    message:
                      "There was an error. Contact the support with error code 4343",
                    expiration: 5000,
                  })
                } else {
                  fullURL = `${apiURL}${
                    contributorEntity.MetaData.RequestURL
                  }/${contributorEntity.EntityInstance_ID}/${url}${
                    limit ? `?perPage=${limit}` : ""
                  }`
                }
              }

              const tableData = await makeAPICallWithErrorHandling(
                fullURL,
                "GET",
                {},
                history
              )

              if (!contributorEntity.MetaData) {
                // user's role isn't contributor
                tableData.forEach(item => {
                  if (!addedIds[item.ID]) {
                    data.push(item)
                    addedIds[item.ID] = true
                  }
                })
              } else if (notContributor) {
                // user's role is contributor but the user is trying to get the table of the entity that he is contributor of (tour guide getting the tour guides table)
                tableData
                  .filter(x => x.ID === contributorEntity.EntityInstance_ID)
                  .forEach(item => {
                    if (!addedIds[item.ID]) {
                      data.push(item)
                      addedIds[item.ID] = true
                    }
                  })
              } else {
                if (contributorEntityIsInField) {
                  // user's role is contributor and the user is getting a table where the user's contributor entity is in a Field of entities (Dropdown, Radio button....)
                  tableData.forEach(item => {
                    if (!addedIds[item.ID]) {
                      data.push(item)
                      addedIds[item.ID] = true
                    }
                  })
                } else {
                  // user's role is contributor and the user is getting a table where the user's contributor entity is in a Table FieldGroup
                  tableData.Relations.forEach(item => {
                    if (!addedIds[item.ID]) {
                      data.push(item)
                      addedIds[item.ID] = true
                    }
                  })
                }
              }
            })
          )

          const styling =
            makeStylingForDynamicTableFromFieldGroup(inputFieldGroup)

          fullTableData.push({
            data: data,
            styling,
          })
        }
      )
    )

    setResults(fullTableData)
    setLoading(false)
  }

  return loading || cmsLoading ? (
    <div className="loading" />
  ) : (
    <>
      {results.map(result => (
        <div
          key={`${
            results.data && results.data[0]
              ? result.data[0].ID
              : "api_call_results_no_data"
          }`}
        >
          <DynamicTable
            item={result.data}
            styling={result.styling}
            openForm={openForm}
            create={rules.create === "True" || rules.create === "IfContributor"}
            refresh={loadData}
            noSearch={noSearch}
            noPagination={noPagination}
            noPageSize={noPageSize}
            hideCreate={hideCreate}
          />
        </div>
      ))}
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { loading } = CMS

  return { cmsLoading: loading }
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, {})(TableControler))
)
