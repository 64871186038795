import React from "react"
import { withRouter } from "react-router-dom"

import ErrorPage from "../../components/Common/Utility/ErrorPage"

const Pages500 = ({ location }) => {
  let reason = location.search
    ? new URLSearchParams(location.search).get("reason")
    : ""

  return (
    <ErrorPage
      code="500"
      title="Internal server error"
      description="Ooops, seems like there was an error on the server. Please try again later or contact your administrator."
      reason={reason}
    />
  )
}

export default withRouter(Pages500)
