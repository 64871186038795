import React from "react"

import StartPage from "../../../components/Admin/AccountEditor/StartPage"

const Cities = ({ history }) => {
  return (
    <StartPage
      title="acccounts-manager.cities"
      clientCategory="Local government"
      history={history}
    />
  )
}

export default Cities
