import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

import { Col, Container, Row, Card, CardBody } from "reactstrap"
import Controler from "../../components/Form/Controler"
import Donut from "components/Charts/Donut"
import LineArea from "components/Charts/LineArea"
import { ThemeColors } from "helpers/ThemeColors"

const Dashboard = ({
  reducersLoading,
  appID,
  cms,
  clientID,
  currentUser,
  history,
}) => {
  const colors = ThemeColors()

  const [module, setModule] = useState()
  const [rules, setRules] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (
      reducersLoading ||
      !cms ||
      appID !== "715D58A9-1DE5-4350-A1C9-38EEE254A79C"
    )
      return
    const ID = "D96E4D39-3FBE-450D-BD11-1456791411ED"

    const levelOneMenuItems = JSON.parse(cms.Structure).MenuItems

    const chosenModuleParentID = Object.keys(levelOneMenuItems).find(
      x => levelOneMenuItems[x].MenuItems[ID]
    )
    const chosenModuleParent = levelOneMenuItems[chosenModuleParentID]
    const chosenModule = chosenModuleParent.MenuItems[ID]
    if (currentUser.role < 4) {
      setRules({ create: "True", update: "True", delete: "True" })
    } else {
      const role =
        currentUser.Clients[clientID].Applications[cms.MetaData.ID].Roles[
          Object.keys(
            currentUser.Clients[clientID].Applications[cms.MetaData.ID].Roles
          )[0]
        ]
      const moduleRules = role.Rules[chosenModuleParentID].Rules[ID]
      setRules({
        create: moduleRules.Create,
        update: moduleRules.Update,
        delete: moduleRules.Delete,
      })
    }
    setModule(chosenModule)
    setLoading(false)
  }, [location.pathname, location.search, cms, clientID, reducersLoading])

  return (
    <React.Fragment>
      {loading || reducersLoading ? (
        <div className="loading" />
      ) : (
        <div className="page-content">
          <Container fluid className="pt-4">
            {!appID == "715D58A9-1DE5-4350-A1C9-38EEE254A79C" && (
              <Row>
                <Col xl={9}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">
                        Novi korisnici u Vašem gradu
                      </h4>
                      <Row>
                        <Col lg={7}>
                          <div>
                            <LineArea
                              data={{
                                labels: [2, 3, 4, 5, 6, 7, 8, 9],
                                series: [
                                  [
                                    2456, 1882, 2125, 2650, 2351, 2260, 2562,
                                    2420,
                                  ],
                                ],
                              }}
                              options={{
                                fullWidth: true,

                                chartPadding: {
                                  right: 40,
                                },
                              }}
                              height="300px"
                            />
                          </div>
                        </Col>
                        <Col lg={5}>
                          <Row>
                            <Col md={6}>
                              <div className="text-center">
                                <p className="text-muted mb-4">Ovaj mjesec</p>
                                <h3>2,420</h3>
                                <Donut
                                  series={[(2420 / 3000) * 100]}
                                  options={{
                                    plotOptions: {
                                      radialBar: {
                                        hollow: {
                                          size: "45%",
                                        },
                                        dataLabels: {
                                          value: {
                                            show: false,
                                          },
                                        },
                                      },
                                    },
                                    colors: [colors.primary],
                                    labels: [""],
                                  }}
                                  height="140px"
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="text-center">
                                <p className="text-muted mb-4">Prošli mjesec</p>
                                <h3>2,562</h3>
                                <Donut
                                  series={[(2562 / 3000) * 100]}
                                  options={{
                                    plotOptions: {
                                      radialBar: {
                                        hollow: {
                                          size: "45%",
                                        },
                                        dataLabels: {
                                          value: {
                                            show: false,
                                          },
                                        },
                                      },
                                    },
                                    colors: [colors.primary],
                                    labels: [""],
                                  }}
                                  height="140px"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={3}>
                  <Card className="bg-primary">
                    <CardBody>
                      <div className="text-center text-white py-4">
                        <h5 className="mt-0 mb-4 text-white-50 font-size-16">
                          Pregleda zadnje novosti
                        </h5>
                        <h1>1628</h1>
                        <p className="font-size-14 pt-1">Uveden CityX</p>
                        <p className="text-white-50 mb-0">
                          <Link
                            to={`/app/module/D96E4D39-3FBE-450D-BD11-1456791411ED${
                              location.search
                            }${location.search ? "&" : "?"}opened=new`}
                            className="text-white"
                          >
                            Objavite novu novost
                          </Link>
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

            <Row>
              <Col xl={12}>
                <Controler
                  module={module}
                  title={"Last 10 news"}
                  rules={rules}
                  openFormOverride={ID => {
                    history.push(
                      `/app/module/D96E4D39-3FBE-450D-BD11-1456791411ED?clientID=${clientID}&opened=${ID}`
                    )
                  }}
                  limit={10}
                  noSearch
                  noPagination
                  noPageSize
                  hideCreate
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = ({ CMS, Auth }) => {
  const { loading: cmsLoading, cms, clientID, appID } = CMS
  const { loading: authLoading, currentUser } = Auth

  return {
    reducersLoading: cmsLoading && authLoading,
    cms,
    clientID,
    currentUser,
    appID,
  }
}
export default withRouter(connect(mapStateToProps, {})(Dashboard))
