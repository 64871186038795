import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button,
} from "reactstrap"

import { pageSizeOptions } from "../../../constants/defaultValues"
import {
  compareCroatianString,
  filterUnwantedKeysForTable,
  prepareStringForManipulating,
} from "../../../helpers/Utils"
import DynamicTableStyledCell from "./DynamicTableStyledCell"
import CustomTablePicker from "./CustomTable/CustomTablePicker"

const DynamicTable = ({
  item,
  openForm,
  create,
  actionButtons,
  topButton,
  bottomButton,
  styling,
  noSearch,
  noPagination,
  noPageSize,
  hideCreate,
  t,
}) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [entriesPerPage, setEntriesPerPage] = useState(50)
  const [canPrevious, setCanPrevious] = useState(false)
  const [canNext, setCanNext] = useState(item && item.length > entriesPerPage)
  const [data, setData] = useState(item.length ? item : [...item])
  const [sortBy, setSortBy] = useState("")
  const [filterKeyword, setFilterKeyword] = useState("")
  const [sorted, setSorted] = useState(false)
  const showPageSizeOptions = !noPageSize

  useEffect(() => {
    setData(item.length ? item : [...item])
    setCanNext(item && item.length > entriesPerPage)
  }, [item])

  let keys = filterUnwantedKeysForTable(data[0] ? Object.keys(data[0]) : [])

  if (styling) {
    keys = keys.sort((a, b) => styling[a].Order - styling[b].Order)
  }

  // changes page and canNext and canPrevious
  const changePage = page => {
    setCurrentPage(page)
    setCanPrevious(page !== 0)
    setCanNext(data.length > entriesPerPage * (page + 1))
  }

  // changes entries per page, if canNext should be allowed and if the page should be changed
  const changeEntriesPerPage = size => {
    setEntriesPerPage(size)

    let p = currentPage
    while (data.length <= size * p) {
      p -= 1
    }

    if (p !== currentPage) {
      setCurrentPage(p)
      setCanPrevious(p !== 0)
    }
    setCanNext(data.length > size * (p + 1))
  }

  // renders Pagination items for each page
  const renderPages = () => {
    const pageButtons = []
    for (let i = 0; i * entriesPerPage < data.length; i += 1) {
      const active = currentPage === i
      pageButtons.push(
        <PaginationItem key={i} active={active}>
          <PaginationLink onClick={() => changePage(i)}>{i + 1}</PaginationLink>
        </PaginationItem>
      )
    }
    return pageButtons
  }

  // sorts data
  const setSort = key => {
    let inverse = false
    if (sortBy === key) {
      inverse = true
    }
    if (sorted) setSortBy(inverse ? `inv${key}` : key)
    setData(
      data.sort((a, b) => {
        const x = a[key]
        const y = b[key]

        if (typeof x === "string" && typeof y === "string") {
          return compareCroatianString(x, y)
        }

        let ret = 0
        if ((x < y && !inverse) || (x > y && inverse)) {
          ret = -1
        } else if ((x > y && !inverse) || (x < y && inverse)) {
          ret = 1
        }
        return ret
      })
    )
    setSorted(true)
  }

  if (keys.length && !sorted) setSort(keys[0])

  // filters data
  const filterFunction = x => {
    if (filterKeyword === "") {
      return true
    }

    let match = false

    keys.forEach(key => {
      if (
        prepareStringForManipulating(x[key]).indexOf(
          prepareStringForManipulating(filterKeyword)
        ) !== -1 &&
        key !== "ID"
      ) {
        match = true
      }
    })

    return match
  }

  return (
    <div>
      <div className="col-12 d-flex justify-content-between mb-2 mt-2">
        <div className="search-sm d-inline-block float-md-left align-top mt-2 mb-2">
          {!noSearch && data.length > 0 && (
            <input
              type="text"
              name="keyword"
              id="search"
              placeholder={t("Search")}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  setFilterKeyword(e.target.value)
                }
              }}
            />
          )}
        </div>

        <div className="">
          {topButton && topButton}
          {!hideCreate && (
            <Button
              color="primary"
              onClick={() => openForm(undefined)}
              disabled={!create}
            >
              Create New
            </Button>
          )}
        </div>
      </div>
      {data.length > 0 ? (
        <>
          {styling && styling.custom ? (
            <CustomTablePicker
              type={styling.custom}
              data={data
                .filter(x => filterFunction(x))
                .slice(
                  currentPage * entriesPerPage,
                  (currentPage + 1) * entriesPerPage
                )}
              keys={keys}
              actionButtons={actionButtons}
              setSort={setSort}
              sortBy={sortBy}
              openForm={openForm}
            />
          ) : (
            <div className="table-responsive mb-2">
              <Table className="table table-striped table-sm mb-0">
                <thead>
                  <tr>
                    {[
                      ...keys.map(key => (
                        <th
                          key={`heading_${key}`}
                          onClick={() => {
                            setSort(key)
                          }}
                          className={`${sortBy === key ? "sorted-desc" : ""}${
                            sortBy === `inv${key}` ? "sorted-asc" : ""
                          }`}
                        >
                          {styling ? styling[key].Name : key}
                        </th>
                      )),
                      actionButtons &&
                        actionButtons.map(actionButton => (
                          <th key={`actionButtonTitle_${actionButton.id}`}>
                            {actionButton.title}
                          </th>
                        )),

                      <th key={`copy`}>Copy ID</th>,
                    ]}
                  </tr>
                </thead>
                <tbody>
                  {data
                    .filter(x => filterFunction(x))
                    .slice(
                      currentPage * entriesPerPage,
                      (currentPage + 1) * entriesPerPage
                    )
                    .map(i => (
                      <tr key={`row_${i.ID}`}>
                        {[
                          ...keys.map((key, index) =>
                            index === 0 && openForm ? (
                              <td key={`${key}_${i[key]}_${i.ID}`}>
                                <button
                                  className="text-only-button"
                                  type="button"
                                  onClick={() => openForm(i.ID)}
                                >
                                  {i[key]}
                                </button>
                              </td>
                            ) : (
                              <DynamicTableStyledCell
                                key={`${key}_${i[key]}_${i.ID}`}
                                _key={`${key}_${i[key]}_${i.ID}`}
                                content={i[key]}
                                styling={styling && styling[key]}
                              />
                            )
                          ),
                          actionButtons &&
                            actionButtons.map(actionButton => (
                              <td
                                key={`actionButtonItem_${actionButton.id}_${i.id}`}
                              >
                                <button
                                  className={
                                    actionButton.disabled
                                      ? "text-only-button-disabled"
                                      : "text-only-button"
                                  }
                                  type="button"
                                  onClick={() => actionButton.function(i.ID)}
                                  disabled={actionButton.disabled}
                                >
                                  {typeof actionButton.buttonTitle ===
                                  "function"
                                    ? actionButton.buttonTitle(i)
                                    : actionButton.buttonTitle}
                                </button>
                              </td>
                            )),

                          <td key={`copy_${i.ID}`}>
                            <button
                              className="text-only-button"
                              type="button"
                              onClick={() =>
                                window.navigator.clipboard.writeText(i.ID)
                              }
                            >
                              <i className="simple-icon-note" />
                            </button>
                          </td>,
                        ]}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}

          {!noPagination && (
            <div className="d-inline-block pt-2">
              <Pagination
                className="d-flex align-items-center"
                size="sm"
                listClassName="justify-content-center m-0"
                aria-label="Pagination"
              >
                <PaginationItem className={`${!canPrevious && "disabled"}`}>
                  <PaginationLink
                    className="prev"
                    onClick={() => {
                      if (!canPrevious) return
                      changePage(currentPage - 1)
                    }}
                    disabled={!canPrevious}
                  >
                    <i className="simple-icon-arrow-left" />
                  </PaginationLink>
                </PaginationItem>

                {renderPages()}
                <PaginationItem className={`${!canNext && "disabled"}`}>
                  <PaginationLink
                    className="next"
                    onClick={() => {
                      if (!canNext) return
                      changePage(currentPage + 1)
                    }}
                    disabled={!canNext}
                  >
                    <i className="simple-icon-arrow-right" />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          )}

          {showPageSizeOptions && (
            <div className="float-right pt-2">
              <span className="text-muted text-small mr-1">Items </span>
              <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle caret color="outline-primary" size="xs">
                  {entriesPerPage}
                </DropdownToggle>
                <DropdownMenu right>
                  {pageSizeOptions.map(size => {
                    return (
                      <DropdownItem
                        key={size}
                        onClick={() => {
                          changeEntriesPerPage(size)
                        }}
                      >
                        {size}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )}

          {bottomButton && bottomButton}
        </>
      ) : (
        <div className="w-100">
          <h2>No data</h2>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(React.memo(DynamicTable))
