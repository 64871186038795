export const ThemeColors = () => {
  const rootStyle = getComputedStyle(document.body)
  return {
    primary: rootStyle.getPropertyValue("--primary-color").trim(),
    primary25: rootStyle.getPropertyValue("--primary-color-25").trim(),
    secondary: rootStyle.getPropertyValue("--secondary-color").trim(),
    secondary25: rootStyle.getPropertyValue("--secondary-color-25").trim(),
    separatorColor: rootStyle.getPropertyValue("--separator-color").trim(),
    danger: rootStyle.getPropertyValue("--separator-color").trim(),
  }
}
