import React, { useState } from "react"
import { Button, Modal, ModalBody } from "reactstrap"
import NewVersionModal from "./NewVersionModal"

const NewVersionNotification = () => {
  const [modalOpened, setModalOpened] = useState(true)
  return (
    <>
      <Modal isOpen={modalOpened} style={{ paddingTop: "100px" }}>
        <ModalBody>
          <NewVersionModal />
        </ModalBody>
      </Modal>
      <Button
        color="info"
        onClick={() => setModalOpened(!modalOpened)}
        className="default"
        style={{
          borderRadius: 0,
          position: "fixed",
          top: 0,
          left: 0,
          height: "50px",
          zIndex: 100000,
          width: "-webkit-fill-available",
          padding: 0,
        }}
      >
        <p style={{ margin: 0 }}>
          New Version is available for your application. Click to update!
        </p>
      </Button>
    </>
  )
}

export default NewVersionNotification
