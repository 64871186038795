import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/storage"
import "firebase/firestore"

import { firebaseConfig } from "../constants/defaultValues"

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const database = firebase.database()
const storage = firebase.storage()
const firestore = firebase.firestore()

const authObject = firebase.auth
const databaseObject = firebase.database
const storageObject = firebase.storage
const firestoreObject = firebase.firestore

export {
  auth,
  database,
  storage,
  firestore,
  authObject,
  databaseObject,
  storageObject,
  firestoreObject,
}
