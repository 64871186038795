import { adminRoot } from "constants/defaultValues"
import { getApplicationByApplicationID } from "helpers/API"
import { firestore } from "helpers/Firebase"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"

const Tools = ({ match, t }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [tools, setTools] = useState([])

  useEffect(() => {
    setTimeout(async () => {
      const appID = match.params.appID

      const app = await getApplicationByApplicationID(appID)

      setData(app)
      setLoading(false)
      const t = await getTools(app.ID)
      setTools(t)
    }, 100)
  }, [match.params.appID])

  const getTools = async ID => {
    switch (ID) {
      case "715D58A9-1DE5-4350-A1C9-38EEE254A79C":
        let size
        await firestore
          .collection("City/Requests/UserCreateNew")
          .where("Status", "==", "Pending")
          .get()
          .then(snapshot => {
            size = snapshot.size
          })
        return [
          {
            label: "tools.user-create-new",
            url: `./user-create-new`,
            notifications: size,
          },
        ]
      default:
        return []
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row>
            <Col>
              {loading ? (
                <div className="loading" />
              ) : (
                <>
                  <div className="d-flex flex-column min-width-zero margin-bottom-10">
                    <h1>{data.DisplayName}</h1>
                  </div>
                  {tools.map(tool => (
                    <div className="d-flex flex-column min-width-zero margin-bottom-10">
                      <Row className="row">
                        <Col lg={6}>
                          <a href={tool.url} target="_blank">
                            {t(tool.label)}
                          </a>
                        </Col>
                        <Col lg={6}>
                          <a href={tool.url} target="_blank">
                            {tool.notifications}
                          </a>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  {tools.length === 0 && (
                    <div className="d-flex flex-column min-width-zero margin-bottom-10">
                      <h3>There are no tools for this application</h3>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(withRouter(Tools))
