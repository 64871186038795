import { getBadgeColorForDateTime } from "helpers/Utils"
import React from "react"
import moment from "moment"
import { noImageImage } from "constants/defaultValues"

const DynamicTableStyledCell = ({ _key, content, styling }) => {
  if (
    !styling ||
    styling.Type === "Text" ||
    styling.Type === "Number" ||
    styling.Type === "Email" ||
    styling.Type === "Phone Number" ||
    styling.Type === "OIB" ||
    styling.Type === "Rich Text Field" ||
    styling.Type === "Text Field" ||
    styling.Type === "Working Hours" ||
    styling.Type === "Link" ||
    styling.Type === "Time"
  )
    return (
      <td key={_key} style={{ color: "black" }}>
        {content}
      </td>
    )

  if (styling.Type === "Single Image")
    return (
      <td key={_key}>
        <div>
          <img
            src={content || noImageImage}
            alt=""
            className="avatar-xs rounded-circle me-2"
          />
        </div>
      </td>
    )

  if (content && (styling.Type === "Date" || styling.Type === "DateTime")) {
    const date = new Date(content)

    const locale = moment(date)
    locale.locale("hr")

    return (
      <td key={_key}>
        <span className={`badge ${getBadgeColorForDateTime(date)}`}>
          {locale.utc().format("D. M. YYYY")}
        </span>
      </td>
    )
  }

  return (
    <td key={_key} style={{ color: "black" }}>
      {content}
    </td>
  )
}

export default DynamicTableStyledCell
