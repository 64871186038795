import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import TableControler from "./Table/TableControler"
import PlaceholderTable from "../../Table/PlaceholderTable"
import { Card, CardBody } from "reactstrap"

const Table = ({
  fieldGroup,
  ID,
  cms,
  loading,
  rules,
  parentURL,
  parentRelationKey,
  privateAPI,
  propClient_ID,
  propApplication_ID,
}) => {
  const [module, setModule] = useState()

  console.log(fieldGroup)

  useEffect(() => {
    if (!cms) {
      return
    }

    const qModule = JSON.parse(cms.Structure).MenuItems[
      fieldGroup.ParentMenuItem_ID
    ].MenuItems[fieldGroup.MenuItem_ID]

    setModule(qModule)
  }, [cms, fieldGroup, ID])

  return (
    <>
      {loading || !module || !cms ? (
        <div className="loading" />
      ) : (
        <Card className="mt-4">
          <CardBody>
            {ID ? (
              <TableControler
                module={module}
                fieldGroup={fieldGroup}
                title={fieldGroup.DisplayName}
                parentID={ID}
                parentURL={parentURL}
                parentRelationKey={parentRelationKey}
                parentPerPage={
                  module.Form.FieldGroups.find(x => x.Type === "Input").PerPage
                }
                rules={rules}
                privateAPI={privateAPI}
                propClient_ID={propClient_ID}
                propApplication_ID={propApplication_ID}
              />
            ) : (
              <PlaceholderTable title={fieldGroup.DisplayName} />
            )}
          </CardBody>
        </Card>
      )}
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { cms, loading } = CMS

  return {
    cms,
    loading,
  }
}

export default connect(mapStateToProps, {})(Table)
