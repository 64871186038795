import React, { useEffect, useState } from "react"
import { Button, Label, Input } from "reactstrap"
import Select from "react-select"

import {
  menuItemAppStatusOptions,
  menuItemCMSStatusOptions,
} from "../../../../constants/defaultValues"
import {
  getClientMenuItemStatuses,
  setClientMenuItemStatuses,
} from "../../../../helpers/API"
import { getSelectTheme } from "helpers/Utils"

const Visibility = ({ clientID, appID }) => {
  const [loading, setLoading] = useState(true)
  const [menuItems, setMenuItems] = useState({})
  const [working, setWorking] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(async () => {
      const menuItemsAPI = await getClientMenuItemStatuses(clientID, appID)
      setMenuItems(menuItemsAPI)
      setLoading(false)
    }, 100)
  }, [clientID, appID])

  const onSubmit = async () => {
    setWorking(true)
    await setClientMenuItemStatuses(clientID, appID, menuItems)
    setWorking(false)
  }

  const editLevelOne = (levelOneID, name, value) => {
    const newMenuItems = { ...menuItems }
    newMenuItems[levelOneID][name] = value
    setMenuItems(newMenuItems)
  }

  const editLevelTwo = (levelOneID, levelTwoID, name, value) => {
    const newMenuItems = { ...menuItems }
    newMenuItems[levelOneID].MenuItems[levelTwoID][name] = value
    setMenuItems(newMenuItems)
  }

  const editAllStatus = (name, value) => {
    Object.keys(menuItems).forEach(levelOneID => {
      editLevelOne(levelOneID, name, value)
      Object.keys(menuItems[levelOneID].MenuItems).forEach(levelTwoID => {
        editLevelTwo(levelOneID, levelTwoID, name, value)
      })
    })
  }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <>
          <div className="col-12">
            <div className="d-flex flex-lg-row justify-content-between min-width-zero pl-2 pr-2">
              <div className="col-6 p-1">
                <Label>Name</Label>
              </div>
              <div className="col-3 p-1">
                <Label>CMS Status</Label>
              </div>
              <div className="col-3 p-1">
                <Label>Status</Label>
              </div>
            </div>
          </div>

          {Object.keys(menuItems).map(menuItemID => (
            <div className="col-12" key={`menuItemStatusRow_${menuItemID}`}>
              <div className="d-flex flex-lg-row justify-content-between min-width-zero p-2 background-grey">
                <div className="col-6 p-1">
                  <Input
                    type="text"
                    className="list-item-heading truncate"
                    name={`menu_item_name_${menuItemID}`}
                    placeholder="Menu Item Name"
                    value={menuItems[menuItemID].ClientSpecificName}
                    onChange={e =>
                      editLevelOne(
                        menuItemID,
                        "ClientSpecificName",
                        e.target.value
                      )
                    }
                  />
                </div>

                <div className="col-3 p-1">
                  <Select
                    theme={getSelectTheme}
                    classNamePrefix="select2-selection"
                    onChange={e => editLevelOne(menuItemID, "CMSStatus", e.ID)}
                    options={menuItemCMSStatusOptions}
                    defaultValue={menuItemCMSStatusOptions.filter(
                      x => x.ID === menuItems[menuItemID].CMSStatus
                    )}
                    value={menuItemCMSStatusOptions.filter(
                      x => x.ID === menuItems[menuItemID].CMSStatus
                    )}
                    isOptionSelected={option =>
                      option.ID === menuItems[menuItemID].CMSStatus
                    }
                    getOptionLabel={option => option.Name}
                  />
                </div>

                <div className="col-3 p-1">
                  <Select
                    theme={getSelectTheme}
                    classNamePrefix="select2-selection"
                    onChange={e => editLevelOne(menuItemID, "Status", e.ID)}
                    options={menuItemAppStatusOptions}
                    defaultValue={menuItemAppStatusOptions.filter(
                      x => x.ID === menuItems[menuItemID].Status
                    )}
                    value={menuItemAppStatusOptions.filter(
                      x => x.ID === menuItems[menuItemID].Status
                    )}
                    isOptionSelected={option =>
                      option.ID === menuItems[menuItemID].Status
                    }
                    getOptionLabel={option => option.Name}
                  />
                </div>
              </div>

              {Object.keys(menuItems[menuItemID].MenuItems).map(
                menuItemTwoID => (
                  <div
                    className="d-flex flex-lg-row justify-content-between min-width-zero p-2 col-12"
                    key={`levelTwoMenuItemRow_${menuItemTwoID}`}
                  >
                    <div className="col-6 p-1">
                      <Input
                        type="text"
                        className="list-item-heading truncate"
                        name={`menu_item_name_${menuItemTwoID}`}
                        placeholder="Menu Item Name"
                        value={
                          menuItems[menuItemID].MenuItems[menuItemTwoID]
                            .ClientSpecificName
                        }
                        onChange={e =>
                          editLevelTwo(
                            menuItemID,
                            menuItemTwoID,
                            "ClientSpecificName",
                            e.target.value
                          )
                        }
                      />
                    </div>

                    <div className="col-3 p-1">
                      <Select
                        theme={getSelectTheme}
                        classNamePrefix="select2-selection"
                        onChange={e =>
                          editLevelTwo(
                            menuItemID,
                            menuItemTwoID,
                            "CMSStatus",
                            e.ID
                          )
                        }
                        options={menuItemCMSStatusOptions}
                        defaultValue={menuItemCMSStatusOptions.filter(
                          x =>
                            x.ID ===
                            menuItems[menuItemID].MenuItems[menuItemTwoID]
                              .CMSStatus
                        )}
                        value={menuItemCMSStatusOptions.filter(
                          x =>
                            x.ID ===
                            menuItems[menuItemID].MenuItems[menuItemTwoID]
                              .CMSStatus
                        )}
                        isOptionSelected={option =>
                          option.ID ===
                          menuItems[menuItemID].MenuItems[menuItemTwoID]
                            .CMSStatus
                        }
                        getOptionLabel={option => option.Name}
                      />
                    </div>

                    <div className="col-3 p-1">
                      <Select
                        theme={getSelectTheme}
                        classNamePrefix="select2-selection"
                        onChange={e =>
                          editLevelTwo(
                            menuItemID,
                            menuItemTwoID,
                            "Status",
                            e.ID
                          )
                        }
                        options={menuItemAppStatusOptions}
                        defaultValue={menuItemAppStatusOptions.filter(
                          x =>
                            x.ID ===
                            menuItems[menuItemID].MenuItems[menuItemTwoID]
                              .Status
                        )}
                        value={menuItemAppStatusOptions.filter(
                          x =>
                            x.ID ===
                            menuItems[menuItemID].MenuItems[menuItemTwoID]
                              .Status
                        )}
                        isOptionSelected={option =>
                          option.ID ===
                          menuItems[menuItemID].MenuItems[menuItemTwoID].Status
                        }
                        getOptionLabel={option => option.Name}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          ))}

          <div className="col-12 mt-1">
            <div className="d-flex flex-lg-row justify-content-between min-width-zero p-2 background-grey">
              <div className="col-6 p-1">
                <Label className="mt-2">Edit all</Label>
              </div>

              <div className="col-3 p-1">
                <Select
                  theme={getSelectTheme}
                  classNamePrefix="select2-selection"
                  onChange={e => editAllStatus("CMSStatus", e.ID)}
                  options={menuItemCMSStatusOptions}
                  defaultValue={[]}
                  value={[]}
                  isOptionSelected={option => false}
                  getOptionLabel={option => option.Name}
                />
              </div>

              <div className="col-3 p-1">
                <Select
                  theme={getSelectTheme}
                  classNamePrefix="select2-selection"
                  onChange={e => editAllStatus("Status", e.ID)}
                  options={menuItemAppStatusOptions}
                  defaultValue={[]}
                  value={[]}
                  isOptionSelected={option => false}
                  getOptionLabel={option => option.Name}
                />
              </div>
            </div>
          </div>

          <Button
            color="primary"
            className="float-right mr-4 mb-2"
            onClick={() => onSubmit()}
            disabled={working}
          >
            Submit
          </Button>
        </>
      )}
    </>
  )
}

export default Visibility
