import React, { useState } from "react"
import { Button, Modal } from "reactstrap"

const ButtonWithWarning = ({
  className,
  color,
  disabled,
  size,
  heading,
  body,
  modalButtonTitle,
  children,
  onClick,
}) => {
  const [modalOpened, setModalOpened] = useState(false)

  return (
    <>
      <Modal
        isOpen={modalOpened}
        toggle={() => {
          setModalOpened(!modalOpened)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {heading || "Delete forever?"}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalOpened(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {body ||
            `Continuing will permamently delete this item. Are you sure you want to continue?`}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModalOpened(false)
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={onClick}
          >
            {modalButtonTitle || "Continue"}
          </button>
        </div>
      </Modal>
      <Button
        className={className}
        color={color}
        size={size}
        disabled={disabled}
        onClick={() => setModalOpened(true)}
      >
        {children}
      </Button>
    </>
  )
}

export default ButtonWithWarning
