import React from "react"
import moment from "moment"
import { Container, Row } from "reactstrap"

const Footer = () => {
  const now = new Date()
  const locale = moment()
  locale.locale("hr")

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <div className="col-6 align-items-start d-flex">
              <p className="mb-0 text-muted">
                © CityX Apps, {locale.utc(now.toLocaleString()).format("YYYY")}
              </p>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <a href="https://www.cityx.hr">www.cityx.hr</a>
            </div>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
