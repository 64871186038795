import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import styles from "../../constants/styles"

const Sidebar = ({ style, newVersion }) => {
  return (
    <React.Fragment>
      <div className={`vertical-menu ${newVersion && "new-version"}`}>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        {style && styles[style] && styles[style].images.navBottom && (
          <div className="sidebar-bottom-image">
            <span className="sidebar-bottom-lg w-100">
              <img
                src={styles[style].images.navBottom}
                className="w-100"
                alt=""
              />
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { style, newVersion } = CMS

  return {
    style,
    newVersion,
  }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
