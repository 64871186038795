import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { auth, database, storage } from "../../helpers/Firebase"
import {
  GET_USER_INFO,
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "./actionTypes"

import { SET_CLIENT_ID } from "../cms/actionTypes"

import { CMS_GET, CMS_GET_ACCOUNT_EDITOR_VERSION } from "../cms/actionTypes"

import {
  getUserInfoSuccess,
  loginUserSuccess,
  loginUserError,
  registerUserError,
  registerUserSuccess,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  getUserInfoNoUser,
} from "./actions"

import { adminRoot, Environment, UserRole } from "../../constants/defaultValues"
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils"
import { registerUserCall, setRolesToUser } from "../../helpers/API"

import store from "../index"
import { setClientID } from "store/actions"

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword)
}

const loginWithEmailPasswordAsync = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then(user => user)
    .catch(error => error)

const getUserDataAsync = async uid => {
  let userDatabaseInfo
  await database
    .ref(`${Environment}Users/DataBase/${uid}`)
    .once("value", snapshot => {
      userDatabaseInfo = snapshot.val()
    })
    .catch(error => {
      userDatabaseInfo = error
    })

  const userCustomClaims = await auth.currentUser.getIdTokenResult()

  const userInfo = { ...userDatabaseInfo, customClaims: userCustomClaims }

  const files = await storage
    .ref()
    .child(`Users/DataBase/${uid}/Profile`)
    .list()

  const avatar = files.items.find(item => item.name === "avatar")

  if (avatar) {
    userInfo.avatarURL = await avatar.getDownloadURL()
  } else {
    userInfo.avatarURL = "/avatar_placeholder.png"
  }

  userInfo.uid = uid

  userInfo.role =
    UserRole[
      userDatabaseInfo.Roles[Object.keys(userDatabaseInfo.Roles)[0]].Name
    ]

  const params = new URLSearchParams(window.location.search)

  if (userInfo.SelectedClient && userInfo.Clients[userInfo.SelectedClient]) {
    store.dispatch({
      type: SET_CLIENT_ID,
      payload: userInfo.SelectedClient,
    })
  } else if (userInfo.Clients.length === 1) {
    store.dispatch({
      type: SET_CLIENT_ID,
      payload: Object.keys(userInfo.Clients)[0],
    })
  }

  try {
    if (userInfo.role < 4) {
      if (params.get("clientID")) {
        const clientID = params.get("clientID")

        userInfo.eeID = userDatabaseInfo.Authorizations[clientID].EEID

        userInfo.eeSecret = userDatabaseInfo.Authorizations[clientID].EESecret
      } else {
        userInfo.eeID = userDatabaseInfo.Authorizations.Global.EEID

        userInfo.eeSecret = userDatabaseInfo.Authorizations.Global.EESecret
      }
    } else {
      const usersClientID = store.getState().CMS.clientID

      if (usersClientID) {
        userInfo.eeID = userInfo.Authorizations[usersClientID].EEID

        userInfo.eeSecret = userInfo.Authorizations[usersClientID].EESecret
      }
    }
  } catch (e) {
    window.location.href = "/401?reason=no_application"
  }

  return userInfo
}

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user
  const { history } = payload
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password)
    if (!loginUser.message) {
      const user = yield call(getUserDataAsync, loginUser.user.uid)
      if (!user.message) {
        setCurrentUser(user)
        yield put(loginUserSuccess(user))
        history.push(adminRoot)
      }
    } else {
      yield put(loginUserError(loginUser.message))
    }
  } catch (error) {
    yield put(loginUserError(error))
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword)
}

const registerWithEmailPasswordAsync = async (email, password, name) => {
  const user = await registerUserCall(email, password, name)
  return user
}

const setRolesToUserAsync = async (userID, roles) => {
  const user = await setRolesToUser(userID, roles)
  return user
}

function* registerWithEmailPassword({ payload }) {
  const { email, password, name } = payload.user
  const { history } = payload
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password,
      name
    )

    if (!registerUser.ErrorMessage) {
      const userRoles = yield call(
        setRolesToUserAsync,
        registerUser.MetaData.ID,
        [{ Role_ID: "75EF8757-B6DB-4850-B6B6-F8E27977FBC7" }]
      )

      if (!userRoles.ErrorMessage) {
        const user = yield call(
          getUserDataAsync,
          registerUser.MetaData.FirebaseUID
        )

        setCurrentUser(user)
        yield put(registerUserSuccess(user))
        history.push("/login")
      }
    } else {
      yield put(registerUserError(registerUser.ErrorMessage))
    }
  } catch (error) {
    yield put(registerUserError(error))
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout)
}

const logoutAsync = async history => {
  await auth
    .signOut()
    .then(user => user)
    .catch(error => error)
  history.push("/login")
}

function* logout({ payload }) {
  const { history } = payload
  setCurrentUser()
  yield call(logoutAsync, history)
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword)
}

const forgotPasswordAsync = async email => {
  return await auth
    .sendPasswordResetEmail(email)
    .then(user => user)
    .catch(error => error)
}

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email)
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess("success"))
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message))
    }
  } catch (error) {
    yield put(forgotPasswordError(error))
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword)
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then(user => user)
    .catch(error => error)
}

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    )
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess("success"))
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message))
    }
  } catch (error) {
    yield put(resetPasswordError(error))
  }
}

export function* watchGetUserInfo() {
  yield takeEvery(GET_USER_INFO, getUserInfo)
}

function* getUserInfo({ payload }) {
  const userUID = payload
  if (userUID) {
    const userInfo = yield call(getUserDataAsync, userUID)
    if (!userInfo.message) {
      setCurrentUser(userInfo)
      yield put(getUserInfoSuccess(userInfo))
    } else {
      yield put(loginUserError(userInfo.message))
    }
  } else {
    yield put(getUserInfoNoUser())
  }
}

export function* watchGetCMS() {
  yield takeEvery(CMS_GET, changeEEID)
}

export function* watchGetCMSAccountEditorVersion() {
  yield takeEvery(CMS_GET_ACCOUNT_EDITOR_VERSION, changeEEID)
}

function* changeEEID({ payload }) {
  const clientID = payload
  const userInfo = getCurrentUser()

  if (clientID) {
    userInfo.eeID = userInfo.Authorizations[clientID].EEID

    userInfo.eeSecret = userInfo.Authorizations[clientID].EESecret
  } else if (userInfo.role < 4) {
    userInfo.eeID = userInfo.Authorizations.Global.EEID

    userInfo.eeSecret = userInfo.Authorizations.Global.EESecret
  } else {
    const usersClientID = store.getState().CMS.clientID
    if (usersClientID) {
      userInfo.eeID = userInfo.Authorizations[usersClientID].EEID

      userInfo.eeSecret = userInfo.Authorizations[usersClientID].EESecret
    }
  }

  setCurrentUser(userInfo)
  yield put(getUserInfoSuccess(userInfo))
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUserInfo),
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchGetCMS),
    fork(watchGetCMSAccountEditorVersion),
  ])
}
