import React, { Component } from "react"
import DropzoneComponent from "react-dropzone-component"
import { connect } from "react-redux"
import { Button, Col, Row } from "reactstrap"

import { storage } from "../../helpers/Firebase"
import { createFile, deleteFile } from "../../helpers/API"
import { getFileFromURL } from "../../helpers/Utils"
import NotificationAlert from "../Common/Notifications/NotificationAlert"
import { imageTypes } from "../../constants/defaultValues"

const ReactDOMServer = require("react-dom/server")

const dropzoneComponentConfig = {
  postUrl: "no-url",
}

const dropzoneConfig = {
  thumbnailHeight: 160,
  uploadMultiple: true,
  autoProcessQueue: false,
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div className="dz-preview dz-file-preview mb-3">
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            <img data-dz-thumbnail className="img-thumbnail border-0" />
            <i className="simple-icon-doc preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            <span data-dz-name />
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-progress">
            <span className="dz-upload" data-dz-uploadprogress />
          </div>
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#/" className="remove" data-dz-remove>
        <i className="glyph-icon simple-icon-trash" />
      </a>
    </div>
  ),
}

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileToID: {},
    }
  }

  addDefault = async dropzone => {
    const { defaultValue } = this.props

    if (defaultValue) {
      defaultValue.forEach(async val => {
        await getFileFromURL(val.URL, fileToAdd =>
          this.afterFileGotten(fileToAdd, dropzone, val)
        )
      })
      dropzone.files = defaultValue
    }
  }

  afterFileGotten = (blobToAdd, dropzone, val) => {
    const file = new File([blobToAdd], val.Name, {
      type: val.Type,
    })

    Object.defineProperty(file, "File_ID", {
      value: val.ID,
      writable: false,
    })

    this.blobToDataURL(blobToAdd, dataURL =>
      this.afterConvertedToDataURL(file, dataURL, dropzone)
    )
  }

  blobToDataURL = (blob, callback) => {
    const fileReader = new FileReader()
    fileReader.onload = e => callback(e.target.result)
    fileReader.readAsDataURL(blob)
  }

  afterConvertedToDataURL = (file, dataURL, dropzone) => {
    const fileToAdd = file
    fileToAdd.dataURL = dataURL
    fileToAdd.accepted = true

    dropzone.options.addedfile.call(dropzone, fileToAdd)
    if (imageTypes.includes(file.type))
      dropzone.options.thumbnail.call(dropzone, fileToAdd, dataURL)
    dropzone.options.complete.call(dropzone, fileToAdd)
  }

  clear() {
    this.dropzone.removeAllFiles(true)
  }

  render() {
    const { clientID, defaultValue, title } = this.props

    const ref = storage.ref().child(`Clients/DataBase/${clientID}/ClientData/`)

    return (
      <>
        {title && (
          <>
            <Row className="align-items-center">
              <Col md={8}>
                <h1>{title}</h1>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Button
                    onClick={e => {
                      e.preventDefault()
                      this.dropzone.element.click()
                    }}
                    color="primary"
                    className={`${title ? "" : "mb-2"}`}
                  >
                    Upload
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}

        {!title && (
          <Button
            size="l"
            onClick={e => {
              e.preventDefault()
              this.dropzone.element.click()
            }}
            color="primary"
            className="mb-2"
          >
            Upload
          </Button>
        )}

        <DropzoneComponent
          config={dropzoneComponentConfig}
          djsConfig={dropzoneConfig}
          style={{ height: "0.8vh" }}
          isMulti
          eventHandlers={{
            init: dropzone => {
              this.dropzone = dropzone
              this.addDefault(dropzone)
            },
            addedfile: file => {
              const { fileToID } = this.state

              const timeStamp = Date.now()

              const imageRef = ref.child(`${timeStamp}`)

              imageRef.put(file).then(
                async () => {
                  const downloadURL = await imageRef.getDownloadURL()

                  const newFile = await createFile(
                    clientID,
                    file.name,
                    downloadURL,
                    `Clients/DataBase/${clientID}/ClientData/${timeStamp}`,
                    file.type
                  )

                  if (!newFile.ErrorMessage) {
                    const newFileToID = { ...fileToID }
                    newFileToID[file.upload.uuid] = newFile
                    this.setState({ fileToID: newFileToID })
                    this.dropzone.options.success.call(this.dropzone, file)
                    this.dropzone.options.complete.call(this.dropzone, file)
                  }
                },
                () => {
                  NotificationAlert({ type: "error", title: "Failed" })
                }
              )
            },
            removedfile: async file => {
              const { fileToID } = this.state

              if (file.upload) {
                this.dropzone.files = this.dropzone.files.filter(
                  x => x.upload.uuid !== file.upload.uuid
                )

                const deleteFileCall = await deleteFile(
                  fileToID[file.upload.uuid]
                )

                if (!deleteFileCall.ErrorMessage)
                  NotificationAlert({
                    type: "success",
                    title: "Success",
                  })
              } else {
                this.dropzone.files = this.dropzone.files.filter(
                  x => x.ID !== file.File_ID
                )
                const deleteFileCall = await deleteFile(
                  defaultValue.find(x => x.ID === file.File_ID)
                )

                if (!deleteFileCall.ErrorMessage)
                  NotificationAlert({
                    type: "success",
                    title: "Success",
                  })
              }
            },
          }}
        />
      </>
    )
  }
}

const mapStateToProps = ({ CMS }) => {
  const { clientID } = CMS

  return { clientID }
}
export default connect(mapStateToProps, {})(Gallery)
