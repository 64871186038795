import React from "react"

import NewsTable from "./NewsTable"

const CustomTablePicker = ({
  type,
  keys,
  data,
  actionButtons,
  openForm,
  setSort,
  sortBy,
}) => {
  if (type === "News") {
    return (
      <NewsTable
        data={data}
        keys={keys}
        actionButtons={actionButtons}
        openForm={openForm}
        setSort={setSort}
        sortBy={sortBy}
      />
    )
  }

  return (
    <NewsTable
      data={data}
      keys={keys}
      actionButtons={actionButtons}
      openForm={openForm}
      setSort={setSort}
      sortBy={sortBy}
    />
  )
}

export default CustomTablePicker
