import React from "react"

import StartPage from "../../../../components/Admin/AccountManager/StartPage"

const CitiesList = ({ history }) => {
  return (
    <StartPage
      title="acccounts-manager.cities"
      clientCategory="Local government"
      clientURL="cities"
      history={history}
    />
  )
}

export default CitiesList
