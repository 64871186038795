import React from "react"

import StartPage from "../../../components/Admin/AccountEditor/StartPage"

const Businesses = ({ history }) => {
  return (
    <StartPage
      title="acccounts-manager.businesses"
      clientCategory="Business"
      history={history}
    />
  )
}

export default Businesses
