import React from "react"
import { Table, Row, Col } from "reactstrap"
import { withTranslation } from "react-i18next"

const PlaceholderTable = ({ title, t }) => {
  return (
    <Row>
      <Col>
        {title && (
          <div className="d-flex flex-column min-width-zero mt-5">
            <h3>{t(title)}</h3>
          </div>
        )}

        <Table striped className="r-table table mt-2">
          <thead>
            <tr>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> </td>
            </tr>
            <tr>
              <td> </td>
            </tr>
            <tr>
              <td> </td>
            </tr>
            <tr>
              <td> </td>
            </tr>
            <tr>
              <td> </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default withTranslation()(PlaceholderTable)
