import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Col, Row, Card } from "reactstrap"
import { connect } from "react-redux"
import moment from "moment"
import { AvForm, AvInput, AvGroup } from "availity-reactstrap-validation"

import logoDark from "../../assets/images/logo/logo-dark.png"

import { loginUser } from "../../store/auth/actions"
import { getRandomBackgroundImage } from "helpers/Utils"

const Login = ({ loginUserAction, history, loading, error }) => {
  const [bg, setBg] = useState()
  const [_loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true)
      const randomImage = await getRandomBackgroundImage()
      setBg(randomImage)
      setLoading(false)
    }, 0)
  }, [])

  const onSubmit = (event, errors, values) => {
    if (errors.length === 0 && !loading) {
      loginUserAction(values, history)
    }
  }

  const now = new Date()

  const locale = moment()
  locale.locale("hr")

  return (
    <React.Fragment>
      <div className="account-pages">
        <MetaTags>
          <title>CityX CMS | Login page</title>
        </MetaTags>

        {_loading ? (
          <div className="loading" />
        ) : (
          <>
            <div
              className="accountbg"
              style={{
                background: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>

            <div className="wrapper-page account-page-full">
              <Card className="shadow-none">
                <div className="card-block">
                  <div className="account-box">
                    <div className="card-box shadow-none p-4 h-100">
                      <div className="account-sub-box">
                        <div>
                          <div className="text-center mt-5">
                            <Link to="/">
                              <img src={logoDark} height="60" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-5 text-center">
                            Dobrodošli na CityX CMS
                          </h4>
                          <p className="text-muted text-center">
                            Pametna rješenja za pametne gradove
                          </p>

                          <AvForm
                            className="mt-5"
                            onSubmit={(event, errors, values) =>
                              onSubmit(event, errors, values)
                            }
                          >
                            <div className="mb-3">
                              <label className="form-label" htmlFor="email">
                                E-mail
                              </label>
                              <AvGroup>
                                <AvInput
                                  type="email"
                                  className={`form-control ${
                                    error && error.length > 0
                                      ? "av-invalid is-invalid"
                                      : ""
                                  }`}
                                  id="email"
                                  name="email"
                                  placeholder="Enter e-mail"
                                />
                              </AvGroup>
                            </div>

                            <div className="mb-3">
                              <label className="form-label" htmlFor="password">
                                Password
                              </label>
                              <AvGroup>
                                <AvInput
                                  type="password"
                                  className={`form-control ${
                                    error && error.length > 0
                                      ? "av-invalid is-invalid"
                                      : ""
                                  }`}
                                  id="password"
                                  name="password"
                                  placeholder="Enter password"
                                />
                              </AvGroup>
                            </div>

                            {error && error.length > 0 && (
                              <h4 className="font-size-18  text-center error">
                                Invalid e-mail or password
                              </h4>
                            )}

                            <Row className="mb-3">
                              <Col sm={6}>
                                {/* <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div> */}
                              </Col>

                              <Col sm="6" className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md waves-effect waves-light"
                                >
                                  Log In
                                </button>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>

                        <div className="text-center">
                          {/* <p>
                        Don't have an account ?{" "}
                        <Link
                          to="pages-register-2"
                          className="fw-medium-medium text-primary"
                        >
                          {" "}
                          Signup now{" "}
                        </Link>{" "}
                      </p> */}
                          <div className="d-flex justify-content-between">
                            <p>{`@ CityX Apps, ${locale
                              .utc(now.toLocaleString())
                              .format("YYYY")}`}</p>
                            <p>
                              <a href="www.cityx.hr">www.cityx.hr</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ Auth }) => {
  const { loading, error } = Auth
  return { loading, error }
}

export default connect(mapStateToProps, { loginUserAction: loginUser })(Login)
