import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Button } from "reactstrap"
import { connect } from "react-redux"

import {
  addBlockToFirestore,
  deleteBlockFromFirestore,
} from "../../../helpers/Utils"
import BlockRow from "./BlockRow"
import {
  blockTypeOptions,
  defaultBlockForHomepage,
} from "../../../constants/defaultValues"
import NotificationAlert from "../../../components/Common/Notifications/NotificationAlert"
import CitySelection from "components/Form/Components/CitySelection"

const MultipleAddModal = ({ cmsLoading, callback }) => {
  const [selectedCities, setSelectedCities] = useState([])
  const [block, setBlock] = useState(defaultBlockForHomepage())

  const onMultipleAdd = () => {
    addBlockToFirestore(selectedCities, block, res => {
      if (!res) {
        NotificationAlert({
          type: "success",
          title: "Success",
          expiration: 3000,
        })
        callback()
      } else {
        NotificationAlert({
          type: "error",
          title: "Error",
          message: res,
          expiration: 3000,
        })
        callback()
      }
    })

    // deleteBlockFromFirestore(selectedCities, "3rODgbxOOjH5woS4nrDI", res => {
    //   if (!res) {
    //     NotificationAlert({
    //       type: "success",
    //       title: "Success",
    //       expiration: 3000,
    //     })
    //     callback()
    //   } else {
    //     NotificationAlert({
    //       type: "error",
    //       title: "Error",
    //       message: res,
    //       expiration: 3000,
    //     })
    //     callback()
    //   }
    // })
  }

  const editBlock = (key, val) => {
    const newBlock = { ...block, [key]: val }
    setBlock(newBlock)
  }

  return (
    <>
      {cmsLoading ? (
        <div className="loading" />
      ) : (
        <>
          <CitySelection
            setSelected={val => setSelectedCities(val)}
            selected={selectedCities}
          />
          <BlockRow
            className="mt-5"
            block={block}
            blockTypeOptions={blockTypeOptions}
            editBlock={(key, val) => editBlock(key, val)}
            noSorting
            opened
          />
          <Button
            color="primary"
            className="float-right"
            onClick={() => onMultipleAdd()}
          >
            Submit
          </Button>
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { clientID, loading } = CMS
  return { clientID, cmsLoading: loading }
}
export default withTranslation()(connect(mapStateToProps, {})(MultipleAddModal))
