import CitiesManagerCreator from "../pages/app/account-manager/cities/creator"
import BusinessManagerCreator from "../pages/app/account-manager/businesses/creator"
import CitiesManagerEditor from "../pages/app/account-manager/cities/editor"
import BusinessManagerEditor from "../pages/app/account-manager/businesses/editor"
import CitiesManager from "../pages/app/account-manager/cities/list"
import BusinessManager from "../pages/app/account-manager/businesses/list"

import { adminRoot } from "../constants/defaultValues"

export default [
  {
    path: `${adminRoot}manager/cities/new`,
    component: CitiesManagerCreator,
  },
  {
    path: `${adminRoot}manager/businesses/new`,
    component: BusinessManagerCreator,
  },
  {
    path: `${adminRoot}manager/cities/edit`,
    component: CitiesManagerEditor,
  },
  {
    path: `${adminRoot}manager/businesses/edit`,
    component: BusinessManagerEditor,
  },

  {
    path: `${adminRoot}manager/cities`,
    component: CitiesManager,
  },
  {
    path: `${adminRoot}manager/businesses`,
    component: BusinessManager,
  },
]
