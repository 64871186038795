/* AUTH */
export const GET_USER_INFO = "GET_USER_INFO"
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS"
export const GET_USER_INFO_NO_USER = "GET_USER_INFO_NO_USER"
export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR"
export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR"
export const LOGOUT_USER = "LOGOUT_USER"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"
export const CHANGE_AVATAR_URL = "CHANGE_AVATAR_URL"
