import React from "react"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"

import AdminEditor from "../../../../components/User/AdminSettings/AdminEditor"

const Administrators = ({ appID, clientID, loading }) => {
  return (
    <>
      {loading || !appID || !clientID ? (
        <div className="loading" />
      ) : (
        <div className="page-content">
          <div className="container-fluid p-1">
            <Row>
              <Col>
                <AdminEditor clientID={clientID} appID={appID} />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ CMS }) => {
  const { appID, clientID, loading } = CMS
  return { appID, clientID, loading }
}
export default connect(mapStateToProps, {})(Administrators)
