import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { withTranslation } from "react-i18next"

import Gallery from "../../../../components/Gallery/Gallery"
import { getAllFiles } from "../../../../helpers/API"

const GalleryPage = ({ t }) => {
  const [files, setFiles] = useState()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(async () => {
      const defaultFiles = await getAllFiles()
      setFiles(defaultFiles)
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <div className="page-content">
      <div className="container-fluid p-1">
        <Row>
          <Col>
            {loading ? (
              <div className="loading" />
            ) : (
              <>
                <Gallery
                  defaultValue={files.filter(x => x.Status)}
                  title={t("menu.gallery")}
                />
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default withTranslation()(GalleryPage)
