import {
  CMS_GET,
  CMS_GET_APPLICATION_EDITING_VERSION,
  CMS_GET_ACCOUNT_EDITOR_VERSION,
  CMS_GET_SUCCESS,
  CMS_GET_ERROR,
  SET_CLIENT_ID,
} from "./actionTypes"

const INIT_STATE = {
  cms: null,
  metaData: undefined,
  menuItems: [{ id: "menu.loading", label: " ", to: "" }],
  newVersion: undefined,
  clientID: undefined,
  appID: undefined,
  systemSettings: null,
  loading: false,
  style: undefined,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CMS_GET:
      return { ...state, loading: true }

    case CMS_GET_APPLICATION_EDITING_VERSION:
      return { ...state, loading: true }

    case CMS_GET_ACCOUNT_EDITOR_VERSION:
      return { ...state, loading: true }

    case CMS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        cms: action.payload.cms,
        metaData: action.payload.metaData,
        systemSettings: action.payload.systemSettings,
        menuItems: action.payload.menuItems,
        newVersion: action.payload.newVersion,
        clientID: action.payload.clientID,
        appID: action.payload.appID,
        style: action.payload.style,
      }

    case CMS_GET_ERROR:
      return { ...state, loading: false, error: action.payload }

    case SET_CLIENT_ID:
      return { ...state, clientID: action.payload }

    default:
      return { ...state }
  }
}
