import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { withRouter } from "react-router-dom"

import Form from "../../../../components/Form/Components/Form"

import appEditorForm from "../../../../constants/administratorCMS/edit.application.form.json"
import { adminRoot } from "../../../../constants/defaultValues"

const ApplicationEdior = ({ location, history }) => {
  const [loading, setLoading] = useState(true)
  const [appID, setAppID] = useState()

  useEffect(() => {
    setLoading(true)
    const params = new URLSearchParams(location.search)

    const ID = params.get("appID")
    setAppID(ID)
    setLoading(false)
  }, [location.search])

  const onAdd = ID => {
    history.push(`${location.pathname}?appID=${ID}`)
    history.go()
  }

  const onDelete = () => {
    history.push(adminRoot)
    history.go()
  }

  return (
    <div className="page-content">
      {loading ? (
        <div className="loading" />
      ) : (
        <div className="container-fluid p-1">
          <Row>
            <Col>
              <Form
                form={appEditorForm.Form}
                ID={appID}
                onAdd={onAdd}
                onDelete={onDelete}
                rules={{ create: "True", update: "True", delete: "True" }}
                excludeKeys={["LatestBuildNumber"]}
                privateAPI
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

export default withRouter(ApplicationEdior)
