import {
  MENU_ITEM_FORM_GET,
  MENU_ITEM_FORM_SUBMIT,
  MENU_ITEM_FORM_GET_SUCCESS,
  MENU_ITEM_FORM_GET_ERROR,
  RELOAD_ENTITIES,
  RELOAD_ENTITIES_SUCCESS,
  INITIAL_MENU_ITEM_FORM_GET_SUCCESS,
} from "./actionTypes"

// call to get the menu item form
export const getMenuItemForm = (formID, appID) => ({
  type: MENU_ITEM_FORM_GET,
  payload: { formID, appID },
})

// edit Form
export const editMenuItemForm = (form, keyToEdit, newValue) => {
  const response = form
  response[keyToEdit] = newValue
  return {
    type: MENU_ITEM_FORM_GET_SUCCESS,
    payload: response,
  }
}

// edit Field Groups
export const editMenuItemFieldGroup = (
  form,
  fieldGroupIndex,
  keyToEdit,
  newValue
) => {
  const response = form
  response.FieldGroups[fieldGroupIndex][keyToEdit] = newValue
  return {
    type: MENU_ITEM_FORM_GET_SUCCESS,
    payload: response,
  }
}

// edit Fields
export const editMenuItemFieldGroupField = (
  form,
  fieldGroupIndex,
  fieldIndex,
  keyToEdit,
  newValue
) => {
  const response = form
  response.FieldGroups[fieldGroupIndex].Fields[fieldIndex][keyToEdit] = newValue

  return {
    type: MENU_ITEM_FORM_GET_SUCCESS,
    payload: response,
  }
}

export const editMenuItemFieldGroupButton = (
  form,
  fieldGroupIndex,
  buttonIndex,
  keyToEdit,
  newValue
) => {
  const response = form
  response.FieldGroups[fieldGroupIndex].Buttons[buttonIndex][keyToEdit] =
    newValue

  return {
    type: MENU_ITEM_FORM_GET_SUCCESS,
    payload: response,
  }
}

export const menuItemAddNewFieldGroup = form => {
  const response = form
  response.FieldGroups.push({
    ID: `field_group_id_${Math.floor(Math.random() * 100000)}_${Math.floor(
      Math.random() * 100000
    )}`,
    FieldGroupType_ID: null,
    Entity_ID: null,
    Type: "",
    Order: response.FieldGroups.length,
    PerPage: 5,
    DisplayName: "",
    Description: "",
    Visible: true,
    AdminOnly: false,
    NoExistingEntries: false,
    Fields: [],
    Buttons: [],
  })

  return {
    type: MENU_ITEM_FORM_GET_SUCCESS,
    payload: response,
  }
}

export const menuItemAddNewField = (form, fieldGroupIndex) => {
  const response = form
  response.FieldGroups[fieldGroupIndex].Fields.push({
    ID: `field_id_${Math.floor(Math.random() * 100000)}_${Math.floor(
      Math.random() * 100000
    )}`,
    FieldType_ID: null,
    Order: response.FieldGroups[fieldGroupIndex].Fields.length,
    Name: "",
    Label: "",
    Prompt: "",
    IncludeInGetAll: false,
    Required: false,
    ReadOnly: false,
    Visible: true,
    AdminOnly: false,
    Unique: false,
    Description: "",
    SelectionOptionLabel: "",
    SelectionOptions: [],
  })

  return {
    type: MENU_ITEM_FORM_GET_SUCCESS,
    payload: response,
  }
}

export const menuItemAddNewButton = (form, fieldGroupIndex) => {
  const response = form
  response.FieldGroups[fieldGroupIndex].Buttons.push({
    ID: `button_id_${Math.floor(Math.random() * 100000)}_${Math.floor(
      Math.random() * 100000
    )}`,
    RequestMethod: "",
    RequestURL: "",
    Order: 0,
    Name: "",
    Text: "",
    Confirmation: "",
    Description: "",
  })

  return {
    type: MENU_ITEM_FORM_GET_SUCCESS,
    payload: response,
  }
}

export const deleteFieldGroup = (form, fieldGroupIndex) => {
  const response = form
  response.FieldGroups.splice(fieldGroupIndex, 1)

  return {
    type: MENU_ITEM_FORM_GET_SUCCESS,
    payload: response,
  }
}

export const deleteField = (form, fieldGroupIndex, buttonIndex) => {
  const response = form
  response.FieldGroups[fieldGroupIndex].Fields.splice(buttonIndex, 1)

  return {
    type: MENU_ITEM_FORM_GET_SUCCESS,
    payload: response,
  }
}

export const deleteButton = (form, fieldGroupIndex, fieldIndex) => {
  const response = form
  response.FieldGroups[fieldGroupIndex].Buttons.splice(fieldIndex, 1)

  return {
    type: MENU_ITEM_FORM_GET_SUCCESS,
    payload: response,
  }
}

// call to submit the form in API
export const submitMenuItemForm = form => ({
  type: MENU_ITEM_FORM_SUBMIT,
  payload: form,
})

export const initialGetMenuItemFormSuccess = payload => ({
  type: INITIAL_MENU_ITEM_FORM_GET_SUCCESS,
  payload: payload,
})

export const getMenuItemFormSuccess = form => ({
  type: MENU_ITEM_FORM_GET_SUCCESS,
  payload: form,
})

export const getMenuItemFormError = error => ({
  type: MENU_ITEM_FORM_GET_ERROR,
  payload: error,
})

export const reloadEntites = () => ({
  type: RELOAD_ENTITIES,
})

export const reloadEntitesSuccess = payload => ({
  type: RELOAD_ENTITIES_SUCCESS,
  payload,
})
