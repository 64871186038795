import React, { useState, useEffect } from "react"
import { Row, Table, Button, Col } from "reactstrap"
import { NavLink, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { getApplicationQuestionCollectionsByAppID } from "../../../../helpers/API"
import { adminRoot } from "../../../../constants/defaultValues"

const QuestionCollections = ({ location, history, t }) => {
  const [defaultValues, setDefaultValues] = useState([])
  const [loading, setLoading] = useState(true)
  const [appID, setAppID] = useState()

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true)

      const params = new URLSearchParams(location.search)

      const ID = params.get("appID")

      const questionCollections =
        await getApplicationQuestionCollectionsByAppID(ID)

      setDefaultValues(questionCollections.Relations)
      setAppID(ID)
      setLoading(false)
    }, 1000)
  }, [location.pathname])

  return (
    <>
      <div className="page-content">
        <div className="container-fluid p-1">
          <Row className="app-row">
            <Col>
              {loading ? (
                <div className="loading" />
              ) : (
                <>
                  <h1>{t("menu.application.question-collections")}</h1>

                  <Button
                    color="primary"
                    className="float-right mb-2"
                    type="button"
                    onClick={() => {
                      history.push(
                        `${adminRoot}questionCollection?appID=${appID}`
                      )
                    }}
                  >
                    {t("form.create-new")}
                  </Button>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      {defaultValues.map(questionCollection => (
                        <tr
                          className="col-12 align-items-center p-2"
                          key={`question_collection_row_${questionCollection.QuestionCollection_ID}`}
                        >
                          <td>
                            <NavLink
                              to={`/questionCollection?appID=${appID}&ID=${questionCollection.QuestionCollection_ID}`}
                            >
                              {questionCollection.QuestionCollectionDisplayName}
                            </NavLink>
                          </td>
                          <td>
                            {questionCollection.QuestionCollectionActive.toString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(withRouter(QuestionCollections))
